/******************************************************************************/
/* TABELS                                                                     */
/******************************************************************************/

table.standalone-rows {
  border-collapse: separate;
  border-spacing: 0 1rem;
  margin-top: -1rem;

  &.table thead th {
    vertical-align: middle;
    border: none;
  }

  &.table tbody td {
    vertical-align: middle;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    background-color: white;
  }

  &.table tbody tr.visibility-restricted td {
    background-color: theme-color-level("danger", $alert-bg-level);
  }

  &.table tbody tr.selected td {
    background-color: $hbm-color-secondary;
  }

  @each $color, $value in $theme-colors {
    &.table tbody tr.row-#{$color} td {
      background-color: theme-color($color);
    }

    &.table tbody tr.row-alert-#{$color} td {
      background-color: theme-color-level($color, $alert-bg-level);
    }
  }

  th:first-child,
  td:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-left: 2px solid transparent;
  }

  th:last-child,
  td:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-right: 2px solid transparent;
  }

  th, td {
    &.width-minimal {
      width: 1%;
      white-space: nowrap;
    }

    &.width-company {
      width: 33%;
    }
  }

  tbody tr {
    &:hover td {
      border-top: 2px solid $burda-color;
      border-bottom: 2px solid $burda-color;
    }

    &:hover th:first-child,
    &:hover td:first-child {
      border-left: 2px solid $burda-color;
    }

    &:hover th:last-child,
    &:hover td:last-child {
      border-right: 2px solid $burda-color;
    }
  }
}

/******************************************************************************/
/* TABLES FOR HBMLP-CONTENT-MAIN                                              */
/******************************************************************************/

.hbmlp-content-main-body {
  table {
    th, td {
      &.width-minimal {
        white-space: normal;
      }
    }
  }
}

/******************************************************************************/
/* TABLE FOR PERMISSIONS                                                      */
/******************************************************************************/

.table-fix-head {
  overflow: auto;
  height: 1000px;

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  table.standalone-rows.table thead th {
    background-color: $hbm-color-bg-light;
    border-radius: 0;
  }

  .table-head-permission {
    width: 400px;

    &-card {
      background: $white;
      border-radius: 1rem;
      padding: 1rem;
      color: $burda-color;
    }

    .badge {
      padding: .5rem .8rem;
      border: none;
      border-radius: 5px;
      margin-bottom: 1.5rem;
      margin-left: 0.5rem;
      white-space: break-spaces;

      &-info {
        color: $burda-color;
        background-color: lighten($burda-color, 50%);
      }

      &-success {
        color: $green;
        background-color: lighten($green, 50%);
      }
    }
  }

  td.row-headline {
    color: $burda-color;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.2;
    padding: 3rem 0 1rem;
    border-radius: 0;
    background-color: $hbm-color-bg-light !important;
    position: sticky;
    top: 180px;

    &.sm {
      top: 50px;
    }

    &:first-child,
    &:last-child {
      border-radius: 0;
    }

    &:hover,
    &:hover:first-child,
    &:hover:last-child {
      border-color: transparent;
    }
  }
}

/******************************************************************************/
/* TABLES CONFLUENCE STYLES                                                   */
/******************************************************************************/

table.confluenceTable {
  border: 1px solid $table-border-color;

  th.confluenceTh {
    padding: $table-cell-padding;
    border: 1px solid $table-border-color;
    background-color: $table-head-bg;
  }
  td.confluenceTd {
    padding: $table-cell-padding;
    border: 1px solid $table-border-color;
  }
}
