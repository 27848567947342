/******************************************************************************/
/* CLIPBOARD                                                                  */
/******************************************************************************/

$hbm-color-clipboardable-bg: lighten($hbm-color-secondary, 12);
$hbm-color-clipboardable-bg-hover: lighten($hbm-color-secondary, 10);

.clipboardable {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 1px;
  border: 1px solid $hbm-color-clipboardable-bg;
  border-left: none;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;

  &.hovered {
    border: 1px solid $hbm-color-clipboardable-bg-hover;
    border-left: none;

    .clipboardable-content {
      background: linear-gradient(270deg, $hbm-color-clipboardable-bg-hover 0px, $hbm-color-clipboardable-bg-hover 75%, rgba(255,255,255,1) 100%);
    }
  }

  &.copied {
    .clipboardable-content {
      color: $hbm-color-highlight;
      transition: color .1s ease-in-out;
    }
  }

  .clipboardable-content {
    padding: 1px 1rem 1px 3px;
    background: linear-gradient(270deg, $hbm-color-clipboardable-bg 0px, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  }

  .clipboardable-trigger {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    padding: 1px 6px;
    align-self: stretch;
    background-color: $hbm-color-clipboardable-bg;
    color: $hbm-color-highlight;
    cursor: pointer;

    &:hover {
      background-color: $hbm-color-clipboardable-bg-hover;
    }
  }
}

@keyframes clipboardable-success-animation {
  0% {
    color: theme-color('success');
  }
  100% {
    color: initial;
  }
}

.clipboardable-success {
  animation: clipboardable-success-animation linear 2s 1;
}
