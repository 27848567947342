/******************************************************************************/
/* NAVIGATIONS                                                                */
/******************************************************************************/

/******************************************************************************/
/* HBMLP-CONTENT-MAIN AND -ASIDE                                              */
/******************************************************************************/
.hbmlp-content-main-header,
.hbmlp-content-aside {
  a.nav-link {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 5px 20px 0 10px;
    padding: 0;
    border-bottom: 4px solid transparent;
    border-radius: 5px;
    transition: all 300ms ease;
    position: relative;

    svg {
      width: 20px;
      height: 20px;
    }

    .badge-nav-pills {
      position: absolute;
      top: -5px;
      right: -1px;
    }

    &.active {
      background: inherit;
      color: $burda-color;

      &:before {
        content: '';
        position: absolute;
        bottom: -4px;
        width: 115%;
        border-radius: 10px 10px 0 0;
        height: 12%;
        background-color: $burda-color;
      }
    }

    &:hover {
      &:before {
        content: '';
        position: absolute;
        bottom: -4px;
        width: 115%;
        border-radius: 10px 10px 0 0;
        height: 12%;
        color: lighten($burda-color, 7%);
        background-color: lighten($burda-color, 7%);
      }
    }
  }
}

/******************************************************************************/
/* HBMLP-CONTENT-ASIDE (SPLIT VIEW)                                           */
/******************************************************************************/

.hbmlp-content-aside {
  .nav-pills {
    background-color: $hbm-color-bg;
    border-bottom: 4px solid $white;
  }
}

