/******************************************************************************/
/* HBMLP-CONTENT_MAIN (SPLIT VIEW)                                            */
/******************************************************************************/

.hbmlp-content-main-header {
  background-color: $hbm-color-bg;;

  .leading {
    border-bottom: 4px solid $white;

    .entity-header {
      background-color: $burda-color;
      border-radius: 30px;
      padding: .5rem 2rem;

      h1 {
        font-size: 1.5rem;
        color: $white;
        margin: 0;
      }
    }
  }
}

.hbmlp-content-main-body {
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;

  h2 {
    text-transform: uppercase;
    margin-bottom: 0;
    color: $burda-color;
    font-weight: 600;
    letter-spacing: .03rem;
  }
}

@media only screen and (max-width: 2096px) {
  .hbmlp-content-main-body {
    top: 215px;
    padding: 0 3rem;
  }
}
