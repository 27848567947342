/******************************************************************************/
/* TAGIFY                                                                     */
/******************************************************************************/

.datagrid-menu-search .tagify__tag > div > .tagify__tag-text {
  white-space: nowrap;
}

// Fix bug in chrome where ::after does not get removed on input click

#hbmlp_form_type_admin_reminder_group_default_message_wrapper {
  .tagify__input {
    line-height: 2;
  }
}

.tagify__tag {
  max-width: 100%;
  background-color: var(--tag-bg);
  &:hover {
    background-color: var(--tag-hover);
  }

  .tagify__tag-text__wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;

    .tagify__tag-text__icon {
      margin-right: .5rem;
      flex-shrink: 0;
    }

    .tagify__tag-text {
      margin-right: .5rem;
      flex-shrink: 1;
    }
  }
}

.tagify__input::after {
  content: none !important;
}

.tagify__dropdown {
  min-width: 220px !important;
}


.reminder-body {
  line-height: 1.7;
}

.entity-wrapper {
  padding: 0 0.75rem;
  line-height: 1.4;

  .entity-icon {
    margin-right: 0.35rem;
  }

  &.entity-missing {
    .entity-text {
      text-decoration: line-through;
    }
  }
}
