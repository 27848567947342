@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';

// Should be:
// 'primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'
// 'blue', 'indigo', 'purple', 'pink', 'red', 'orange', 'yellow', 'green', 'teal', 'cyan', 'white', 'gray', 'gray-dark',
// '100', '200', '300', '400', '500', '600', '700', '800', '900',

$theme-colors: map-merge($hbm-colors, map-merge($theme-colors, map-merge($colors, $grays)));

$spacers: map-merge(
  (
    6: ($spacer * 5)
  ),
  $spacers
);

html {
  font-size: $hbm-font-size;
  font-family: $hbm-font-family;
}

@import '../../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../../node_modules/@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4.scss';
@import '../../../../node_modules/@burdadirect/hbm-frontend/scss/hbm-styles';

@import '../../../includes/styles/fonts';
@import '../../../includes/styles/mixins';

@import 'badges';
@import 'buttons';
@import 'card';
@import 'clipboard';
@import 'colors';
@import 'content-aside';
@import 'content-main';
@import 'dashboard';
@import 'dropdown';
@import 'dropzone';
@import 'forms';
@import 'layout';
@import 'list-group';
@import 'mermaid';
@import 'misc';
@import 'modals';
@import 'nav';
@import 'pagination';
@import 'select2';
@import 'sizing';
@import 'steps';
@import 'tables';
@import 'tagify';
@import 'tasks';
@import 'tooltip';
