/******************************************************************************/
/* DROPDOWN                                                                   */
/******************************************************************************/
.upload-dropdown-section .dropdown-menu {
  background-color: darken($burda-color, 10%);
}

#hbmlp-header .dropdown-menu,
.hbmlp-content-aside-body-upload .dropdown-menu,
.hbmlp-content-main-header .dropdown-menu{
  background-color: $hbm-color-bg;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-menu{
  min-width: 100%;
  border-radius: .5rem;
  padding: .5rem;
  background-color: $white;

  & .dropdown-items {
    background: white;
    border-radius: .5rem;
    padding: .5rem;
  }

  & a,
  & .dropdown-item {
    padding: 1.2rem 1.5rem;
    border-radius: .5rem;

    &:hover {
      background-color: $hbm-color-bg;
      color: black;
    }

    &.upload-item {
      padding: 1.2rem 3rem;
    }
  }

  & h6 {
    color: $burda-color;
    font-size: 1.2rem;
  }

  & .dropdown-divider {
    border-top: 2px solid $hbm-color-bg;
    background-color: white;
  }
}

.upload-dropdown-section .dropdown-menu {
  max-height: 70vh;
  overflow-y: auto;
}
