/******************************************************************************/
/* MODALS                                                                     */
/******************************************************************************/

.hbmlp-modal {
  .modal-content {
    padding: 3rem;

    .modal-header {
      padding: 0;
      border-bottom: 0;

      .modal-title {
        font-size: 2rem;
        text-transform: uppercase;
      }

      hr {
        margin: 0.5rem 1.5rem 0 1.5rem;
        border: none;
        height: 5px;
      }
    }

    .modal-body {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    .modal-footer {
      padding: 0;
      border-top: 0;
    }
  }
}
