@charset "UTF-8";
/******************************************************************************/
/* VARIABLES                                                                  */
/******************************************************************************/
/******************************************************************************/
html {
  font-size: 14px;
  font-family: "Arial", "Neue Helvetica", "Helvetica", sans-serif;
}

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #1d71b8;
  --secondary: rgb(174.661971831, 211.338028169, 242.338028169);
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #EB5757;
  --light: #f8f9fa;
  --dark: #343a40;
  --hbmlp-color-steps: #999;
  --secondary-light: rgb(219.7683098592, 235.1936256247, 248.2316901408);
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --100: #f8f9fa;
  --200: #e9ecef;
  --300: #dee2e6;
  --400: #ced4da;
  --500: #adb5bd;
  --600: #6c757d;
  --700: #495057;
  --800: #343a40;
  --900: #212529;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Arial", "Neue Helvetica", "Helvetica", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #260211;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1d71b8;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: rgb(18.5845070423, 72.4154929577, 117.9154929577);
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Arial", "Neue Helvetica", "Helvetica", sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #260211;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #260211;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: rgb(191.72, 215.24, 235.12);
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: rgb(137.48, 181.16, 218.08);
}

.table-hover .table-primary:hover {
  background-color: rgb(172.315959596, 203.0480808081, 229.024040404);
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: rgb(172.315959596, 203.0480808081, 229.024040404);
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: rgb(232.5053521127, 242.7746478873, 251.4546478873);
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: rgb(213.2242253521, 232.2957746479, 248.4157746479);
}

.table-hover .table-secondary:hover {
  background-color: rgb(210.4771830986, 230.8028169014, 247.9828169014);
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: rgb(210.4771830986, 230.8028169014, 247.9828169014);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: rgb(194.8, 230.36, 202.92);
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: rgb(143.2, 209.24, 158.28);
}

.table-hover .table-success:hover {
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: rgb(190.04, 228.96, 235.12);
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: rgb(134.36, 206.64, 218.08);
}

.table-hover .table-info:hover {
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: rgb(255, 237.64, 185.56);
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: rgb(255, 222.76, 126.04);
}

.table-hover .table-warning:hover {
  background-color: rgb(255, 231.265, 160.06);
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: rgb(255, 231.265, 160.06);
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: rgb(249.4, 207.96, 207.96);
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: rgb(244.6, 167.64, 167.64);
}

.table-hover .table-danger:hover {
  background-color: rgb(246.6872340426, 185.1727659574, 185.1727659574);
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: rgb(246.6872340426, 185.1727659574, 185.1727659574);
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: rgb(253.04, 253.32, 253.6);
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: rgb(251.36, 251.88, 252.4);
}

.table-hover .table-light:hover {
  background-color: rgb(238.165, 240.57, 242.975);
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: rgb(238.165, 240.57, 242.975);
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: rgb(198.16, 199.84, 201.52);
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: rgb(149.44, 152.56, 155.68);
}

.table-hover .table-dark:hover {
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}

.table-hbmlp-color-steps,
.table-hbmlp-color-steps > th,
.table-hbmlp-color-steps > td {
  background-color: rgb(226.44, 226.44, 226.44);
}
.table-hbmlp-color-steps th,
.table-hbmlp-color-steps td,
.table-hbmlp-color-steps thead th,
.table-hbmlp-color-steps tbody + tbody {
  border-color: rgb(201.96, 201.96, 201.96);
}

.table-hover .table-hbmlp-color-steps:hover {
  background-color: rgb(213.69, 213.69, 213.69);
}
.table-hover .table-hbmlp-color-steps:hover > td,
.table-hover .table-hbmlp-color-steps:hover > th {
  background-color: rgb(213.69, 213.69, 213.69);
}

.table-secondary-light,
.table-secondary-light > th,
.table-secondary-light > td {
  background-color: rgb(245.1351267606, 249.4542151749, 253.1048732394);
}
.table-secondary-light th,
.table-secondary-light td,
.table-secondary-light thead th,
.table-secondary-light tbody + tbody {
  border-color: rgb(236.6795211268, 244.7006853249, 251.4804788732);
}

.table-hover .table-secondary-light:hover {
  background-color: rgb(223.7444577465, 237.4289164471, 248.9955422535);
}
.table-hover .table-secondary-light:hover > td,
.table-hover .table-secondary-light:hover > th {
  background-color: rgb(223.7444577465, 237.4289164471, 248.9955422535);
}

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: rgb(183.6, 218.04, 255);
}
.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: rgb(122.4, 186.36, 255);
}

.table-hover .table-blue:hover {
  background-color: rgb(158.1, 204.84, 255);
}
.table-hover .table-blue:hover > td,
.table-hover .table-blue:hover > th {
  background-color: rgb(158.1, 204.84, 255);
}

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: rgb(212.16, 188.08, 251.36);
}
.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody + tbody {
  border-color: rgb(175.44, 130.72, 248.24);
}

.table-hover .table-indigo:hover {
  background-color: rgb(196.6778571429, 163.8954761905, 250.0445238095);
}
.table-hover .table-indigo:hover > td,
.table-hover .table-indigo:hover > th {
  background-color: rgb(196.6778571429, 163.8954761905, 250.0445238095);
}

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: rgb(214.68, 202.08, 237.64);
}
.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: rgb(180.12, 156.72, 222.76);
}

.table-hover .table-purple:hover {
  background-color: rgb(200.0505179283, 182.8788047809, 231.3411952191);
}
.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: rgb(200.0505179283, 182.8788047809, 231.3411952191);
}

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: rgb(248.56, 200.96, 222.8);
}
.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody + tbody {
  border-color: rgb(243.04, 154.64, 195.2);
}

.table-hover .table-pink:hover {
  background-color: rgb(245.8447222222, 178.1752777778, 209.2236111111);
}
.table-hover .table-pink:hover > td,
.table-hover .table-pink:hover > th {
  background-color: rgb(245.8447222222, 178.1752777778, 209.2236111111);
}

.table-red,
.table-red > th,
.table-red > td {
  background-color: rgb(245.2, 198.44, 202.92);
}
.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody + tbody {
  border-color: rgb(236.8, 149.96, 158.28);
}

.table-hover .table-red:hover {
  background-color: rgb(241.4341772152, 176.7058227848, 182.9073417722);
}
.table-hover .table-red:hover > td,
.table-hover .table-red:hover > th {
  background-color: rgb(241.4341772152, 176.7058227848, 182.9073417722);
}

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: rgb(254.44, 218.88, 189.2);
}
.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody + tbody {
  border-color: rgb(253.96, 187.92, 132.8);
}

.table-hover .table-orange:hover {
  background-color: rgb(254.2248101266, 205.0002531646, 163.9151898734);
}
.table-hover .table-orange:hover > td,
.table-hover .table-orange:hover > th {
  background-color: rgb(254.2248101266, 205.0002531646, 163.9151898734);
}

.table-yellow,
.table-yellow > th,
.table-yellow > td {
  background-color: rgb(255, 237.64, 185.56);
}
.table-yellow th,
.table-yellow td,
.table-yellow thead th,
.table-yellow tbody + tbody {
  border-color: rgb(255, 222.76, 126.04);
}

.table-hover .table-yellow:hover {
  background-color: rgb(255, 231.265, 160.06);
}
.table-hover .table-yellow:hover > td,
.table-hover .table-yellow:hover > th {
  background-color: rgb(255, 231.265, 160.06);
}

.table-green,
.table-green > th,
.table-green > td {
  background-color: rgb(194.8, 230.36, 202.92);
}
.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: rgb(143.2, 209.24, 158.28);
}

.table-hover .table-green:hover {
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
.table-hover .table-green:hover > td,
.table-hover .table-green:hover > th {
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}

.table-teal,
.table-teal > th,
.table-teal > td {
  background-color: rgb(192.56, 239.88, 225.88);
}
.table-teal th,
.table-teal td,
.table-teal thead th,
.table-teal tbody + tbody {
  border-color: rgb(139.04, 226.92, 200.92);
}

.table-hover .table-teal:hover {
  background-color: rgb(172.0311191336, 234.9088808664, 216.3059927798);
}
.table-hover .table-teal:hover > td,
.table-hover .table-teal:hover > th {
  background-color: rgb(172.0311191336, 234.9088808664, 216.3059927798);
}

.table-cyan,
.table-cyan > th,
.table-cyan > td {
  background-color: rgb(190.04, 228.96, 235.12);
}
.table-cyan th,
.table-cyan td,
.table-cyan thead th,
.table-cyan tbody + tbody {
  border-color: rgb(134.36, 206.64, 218.08);
}

.table-hover .table-cyan:hover {
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.table-hover .table-cyan:hover > td,
.table-hover .table-cyan:hover > th {
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}
.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: rgb(242.25, 242.25, 242.25);
}
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: rgb(242.25, 242.25, 242.25);
}

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: rgb(213.84, 216.36, 218.6);
}
.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: rgb(178.56, 183.24, 187.4);
}

.table-hover .table-gray:hover {
  background-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}
.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
  background-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}

.table-gray-dark,
.table-gray-dark > th,
.table-gray-dark > td {
  background-color: rgb(198.16, 199.84, 201.52);
}
.table-gray-dark th,
.table-gray-dark td,
.table-gray-dark thead th,
.table-gray-dark tbody + tbody {
  border-color: rgb(149.44, 152.56, 155.68);
}

.table-hover .table-gray-dark:hover {
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
.table-hover .table-gray-dark:hover > td,
.table-hover .table-gray-dark:hover > th {
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}

.table-100,
.table-100 > th,
.table-100 > td {
  background-color: rgb(253.04, 253.32, 253.6);
}
.table-100 th,
.table-100 td,
.table-100 thead th,
.table-100 tbody + tbody {
  border-color: rgb(251.36, 251.88, 252.4);
}

.table-hover .table-100:hover {
  background-color: rgb(238.165, 240.57, 242.975);
}
.table-hover .table-100:hover > td,
.table-hover .table-100:hover > th {
  background-color: rgb(238.165, 240.57, 242.975);
}

.table-200,
.table-200 > th,
.table-200 > td {
  background-color: rgb(248.84, 249.68, 250.52);
}
.table-200 th,
.table-200 td,
.table-200 thead th,
.table-200 tbody + tbody {
  border-color: rgb(243.56, 245.12, 246.68);
}

.table-hover .table-200:hover {
  background-color: rgb(234.0768421053, 236.93, 239.7831578947);
}
.table-hover .table-200:hover > td,
.table-hover .table-200:hover > th {
  background-color: rgb(234.0768421053, 236.93, 239.7831578947);
}

.table-300,
.table-300 > th,
.table-300 > td {
  background-color: rgb(245.76, 246.88, 248);
}
.table-300 th,
.table-300 td,
.table-300 thead th,
.table-300 tbody + tbody {
  border-color: rgb(237.84, 239.92, 242);
}

.table-hover .table-300:hover {
  background-color: rgb(231.2513793103, 234.13, 237.0086206897);
}
.table-hover .table-300:hover > td,
.table-hover .table-300:hover > th {
  background-color: rgb(231.2513793103, 234.13, 237.0086206897);
}

.table-400,
.table-400 > th,
.table-400 > td {
  background-color: rgb(241.28, 242.96, 244.64);
}
.table-400 th,
.table-400 td,
.table-400 thead th,
.table-400 tbody + tbody {
  border-color: rgb(229.52, 232.64, 235.76);
}

.table-hover .table-400:hover {
  background-color: rgb(226.7509302326, 230.21, 233.6690697674);
}
.table-hover .table-400:hover > td,
.table-hover .table-400:hover > th {
  background-color: rgb(226.7509302326, 230.21, 233.6690697674);
}

.table-500,
.table-500 > th,
.table-500 > td {
  background-color: rgb(232.04, 234.28, 236.52);
}
.table-500 th,
.table-500 td,
.table-500 thead th,
.table-500 tbody + tbody {
  border-color: rgb(212.36, 216.52, 220.68);
}

.table-hover .table-500:hover {
  background-color: rgb(217.9116216216, 221.53, 225.1483783784);
}
.table-hover .table-500:hover > td,
.table-hover .table-500:hover > th {
  background-color: rgb(217.9116216216, 221.53, 225.1483783784);
}

.table-600,
.table-600 > th,
.table-600 > td {
  background-color: rgb(213.84, 216.36, 218.6);
}
.table-600 th,
.table-600 td,
.table-600 thead th,
.table-600 tbody + tbody {
  border-color: rgb(178.56, 183.24, 187.4);
}

.table-hover .table-600:hover {
  background-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}
.table-hover .table-600:hover > td,
.table-hover .table-600:hover > th {
  background-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}

.table-700,
.table-700 > th,
.table-700 > td {
  background-color: rgb(204.04, 206, 207.96);
}
.table-700 th,
.table-700 td,
.table-700 thead th,
.table-700 tbody + tbody {
  border-color: rgb(160.36, 164, 167.64);
}

.table-hover .table-700:hover {
  background-color: rgb(190.78, 193.25, 195.72);
}
.table-hover .table-700:hover > td,
.table-hover .table-700:hover > th {
  background-color: rgb(190.78, 193.25, 195.72);
}

.table-800,
.table-800 > th,
.table-800 > td {
  background-color: rgb(198.16, 199.84, 201.52);
}
.table-800 th,
.table-800 td,
.table-800 thead th,
.table-800 tbody + tbody {
  border-color: rgb(149.44, 152.56, 155.68);
}

.table-hover .table-800:hover {
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
.table-hover .table-800:hover > td,
.table-hover .table-800:hover > th {
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}

.table-900,
.table-900 > th,
.table-900 > td {
  background-color: rgb(192.84, 193.96, 195.08);
}
.table-900 th,
.table-900 td,
.table-900 thead th,
.table-900 tbody + tbody {
  border-color: rgb(139.56, 141.64, 143.72);
}

.table-hover .table-900:hover {
  background-color: rgb(179.8560550459, 181.21, 182.5639449541);
}
.table-hover .table-900:hover > td,
.table-hover .table-900:hover > th {
  background-color: rgb(179.8560550459, 181.21, 182.5639449541);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: rgb(69.1465517241, 77.125, 85.1034482759);
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: rgb(69.1465517241, 77.125, 85.1034482759);
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: rgb(108.5774647887, 175.4225352113, 231.9225352113);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(29, 113, 184, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #260211;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: rgb(51.6956521739, 206.3043478261, 87);
  background-color: rgb(51.6956521739, 206.3043478261, 87);
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #EB5757;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(235, 87, 87, 0.9);
  border-radius: 0rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #EB5757;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EB5757' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EB5757' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #EB5757;
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #EB5757;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EB5757' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EB5757' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #EB5757;
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #EB5757;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #EB5757;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #EB5757;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: rgb(240.4255319149, 132.5744680851, 132.5744680851);
  background-color: rgb(240.4255319149, 132.5744680851, 132.5744680851);
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #EB5757;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #EB5757;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #EB5757;
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #260211;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #260211;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(29, 113, 184, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #1d71b8;
  border-color: #1d71b8;
}
.btn-primary:hover {
  color: #fff;
  background-color: rgb(23.7922535211, 92.7077464789, 150.9577464789);
  border-color: rgb(22.0563380282, 85.9436619718, 139.9436619718);
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: rgb(23.7922535211, 92.7077464789, 150.9577464789);
  border-color: rgb(22.0563380282, 85.9436619718, 139.9436619718);
  box-shadow: 0 0 0 0.2rem rgba(62.9, 134.3, 194.65, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1d71b8;
  border-color: #1d71b8;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: rgb(22.0563380282, 85.9436619718, 139.9436619718);
  border-color: rgb(20.3204225352, 79.1795774648, 128.9295774648);
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62.9, 134.3, 194.65, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: rgb(174.661971831, 211.338028169, 242.338028169);
  border-color: rgb(174.661971831, 211.338028169, 242.338028169);
}
.btn-secondary:hover {
  color: #212529;
  background-color: rgb(141.6197183099, 193.3802816901, 237.1302816901);
  border-color: rgb(130.6056338028, 187.3943661972, 235.3943661972);
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: rgb(141.6197183099, 193.3802816901, 237.1302816901);
  border-color: rgb(130.6056338028, 187.3943661972, 235.3943661972);
  box-shadow: 0 0 0 0.2rem rgba(153.4126760563, 185.1873239437, 212.1373239437, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: rgb(174.661971831, 211.338028169, 242.338028169);
  border-color: rgb(174.661971831, 211.338028169, 242.338028169);
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: rgb(130.6056338028, 187.3943661972, 235.3943661972);
  border-color: rgb(119.5915492958, 181.4084507042, 233.6584507042);
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153.4126760563, 185.1873239437, 212.1373239437, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: rgb(32.6086956522, 136.1413043478, 56.25);
  border-color: rgb(30.1449275362, 125.8550724638, 52);
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: rgb(32.6086956522, 136.1413043478, 56.25);
  border-color: rgb(30.1449275362, 125.8550724638, 52);
  box-shadow: 0 0 0 0.2rem rgba(72.25, 180.2, 96.9, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: rgb(30.1449275362, 125.8550724638, 52);
  border-color: rgb(27.6811594203, 115.5688405797, 47.75);
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72.25, 180.2, 96.9, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: rgb(18.75, 132.0652173913, 150);
  border-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: rgb(18.75, 132.0652173913, 150);
  border-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
  box-shadow: 0 0 0 0.2rem rgba(57.8, 175.95, 194.65, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
  border-color: rgb(15.9166666667, 112.1086956522, 127.3333333333);
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(57.8, 175.95, 194.65, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: rgb(223.75, 167.8125, 0);
  border-color: rgb(211, 158.25, 0);
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: rgb(223.75, 167.8125, 0);
  border-color: rgb(211, 158.25, 0);
  box-shadow: 0 0 0 0.2rem rgba(221.7, 169.6, 12.1, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: rgb(211, 158.25, 0);
  border-color: rgb(198.25, 148.6875, 0);
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221.7, 169.6, 12.1, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #EB5757;
  border-color: #EB5757;
}
.btn-danger:hover {
  color: #fff;
  background-color: rgb(230.9308510638, 52.8191489362, 52.8191489362);
  border-color: rgb(229.5744680851, 41.4255319149, 41.4255319149);
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: rgb(230.9308510638, 52.8191489362, 52.8191489362);
  border-color: rgb(229.5744680851, 41.4255319149, 41.4255319149);
  box-shadow: 0 0 0 0.2rem rgba(238, 112.2, 112.2, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #EB5757;
  border-color: #EB5757;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: rgb(229.5744680851, 41.4255319149, 41.4255319149);
  border-color: rgb(228.2180851064, 30.0319148936, 30.0319148936);
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 112.2, 112.2, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: rgb(225.6875, 229.875, 234.0625);
  border-color: rgb(218.25, 223.5, 228.75);
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: rgb(225.6875, 229.875, 234.0625);
  border-color: rgb(218.25, 223.5, 228.75);
  box-shadow: 0 0 0 0.2rem rgba(215.75, 217.2, 218.65, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: rgb(218.25, 223.5, 228.75);
  border-color: rgb(210.8125, 217.125, 223.4375);
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215.75, 217.2, 218.65, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: rgb(34.8534482759, 38.875, 42.8965517241);
  border-color: rgb(29.1379310345, 32.5, 35.8620689655);
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: rgb(34.8534482759, 38.875, 42.8965517241);
  border-color: rgb(29.1379310345, 32.5, 35.8620689655);
  box-shadow: 0 0 0 0.2rem rgba(82.45, 87.55, 92.65, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: rgb(29.1379310345, 32.5, 35.8620689655);
  border-color: rgb(23.4224137931, 26.125, 28.8275862069);
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82.45, 87.55, 92.65, 0.5);
}

.btn-hbmlp-color-steps {
  color: #212529;
  background-color: #999;
  border-color: #999;
}
.btn-hbmlp-color-steps:hover {
  color: #fff;
  background-color: rgb(133.875, 133.875, 133.875);
  border-color: rgb(127.5, 127.5, 127.5);
}
.btn-hbmlp-color-steps:focus, .btn-hbmlp-color-steps.focus {
  color: #fff;
  background-color: rgb(133.875, 133.875, 133.875);
  border-color: rgb(127.5, 127.5, 127.5);
  box-shadow: 0 0 0 0.2rem rgba(135, 135.6, 136.2, 0.5);
}
.btn-hbmlp-color-steps.disabled, .btn-hbmlp-color-steps:disabled {
  color: #212529;
  background-color: #999;
  border-color: #999;
}
.btn-hbmlp-color-steps:not(:disabled):not(.disabled):active, .btn-hbmlp-color-steps:not(:disabled):not(.disabled).active, .show > .btn-hbmlp-color-steps.dropdown-toggle {
  color: #fff;
  background-color: rgb(127.5, 127.5, 127.5);
  border-color: rgb(121.125, 121.125, 121.125);
}
.btn-hbmlp-color-steps:not(:disabled):not(.disabled):active:focus, .btn-hbmlp-color-steps:not(:disabled):not(.disabled).active:focus, .show > .btn-hbmlp-color-steps.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 135.6, 136.2, 0.5);
}

.btn-secondary-light {
  color: #212529;
  background-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
  border-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
}
.btn-secondary-light:hover {
  color: #212529;
  background-color: rgb(187.682306338, 217.1556775329, 242.067693662);
  border-color: rgb(176.986971831, 211.143028169, 240.013028169);
}
.btn-secondary-light:focus, .btn-secondary-light.focus {
  color: #212529;
  background-color: rgb(187.682306338, 217.1556775329, 242.067693662);
  border-color: rgb(176.986971831, 211.143028169, 240.013028169);
  box-shadow: 0 0 0 0.2rem rgba(191.7530633803, 205.464581781, 217.1469366197, 0.5);
}
.btn-secondary-light.disabled, .btn-secondary-light:disabled {
  color: #212529;
  background-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
  border-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
}
.btn-secondary-light:not(:disabled):not(.disabled):active, .btn-secondary-light:not(:disabled):not(.disabled).active, .show > .btn-secondary-light.dropdown-toggle {
  color: #212529;
  background-color: rgb(176.986971831, 211.143028169, 240.013028169);
  border-color: rgb(166.2916373239, 205.1303788051, 237.9583626761);
}
.btn-secondary-light:not(:disabled):not(.disabled):active:focus, .btn-secondary-light:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(191.7530633803, 205.464581781, 217.1469366197, 0.5);
}

.btn-blue {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-blue:hover {
  color: #fff;
  background-color: rgb(0, 104.55, 216.75);
  border-color: rgb(0, 98.4, 204);
}
.btn-blue:focus, .btn-blue.focus {
  color: #fff;
  background-color: rgb(0, 104.55, 216.75);
  border-color: rgb(0, 98.4, 204);
  box-shadow: 0 0 0 0.2rem rgba(38.25, 142.8, 255, 0.5);
}
.btn-blue.disabled, .btn-blue:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active, .show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: rgb(0, 98.4, 204);
  border-color: rgb(0, 92.25, 191.25);
}
.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38.25, 142.8, 255, 0.5);
}

.btn-indigo {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-indigo:hover {
  color: #fff;
  background-color: rgb(86.3303571429, 11.3363095238, 208.4136904762);
  border-color: rgb(81.3214285714, 10.6785714286, 196.3214285714);
}
.btn-indigo:focus, .btn-indigo.focus {
  color: #fff;
  background-color: rgb(86.3303571429, 11.3363095238, 208.4136904762);
  border-color: rgb(81.3214285714, 10.6785714286, 196.3214285714);
  box-shadow: 0 0 0 0.2rem rgba(124.95, 51.85, 243.95, 0.5);
}
.btn-indigo.disabled, .btn-indigo:disabled {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active, .show > .btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: rgb(81.3214285714, 10.6785714286, 196.3214285714);
  border-color: rgb(76.3125, 10.0208333333, 184.2291666667);
}
.btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus, .show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(124.95, 51.85, 243.95, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-purple:hover {
  color: #fff;
  background-color: rgb(94.1045816733, 54.5278884462, 166.2221115538);
  border-color: rgb(88.6693227092, 51.3784860558, 156.6215139442);
}
.btn-purple:focus, .btn-purple.focus {
  color: #fff;
  background-color: rgb(94.1045816733, 54.5278884462, 166.2221115538);
  border-color: rgb(88.6693227092, 51.3784860558, 156.6215139442);
  box-shadow: 0 0 0 0.2rem rgba(132.6, 94.35, 202.3, 0.5);
}
.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active, .show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: rgb(88.6693227092, 51.3784860558, 156.6215139442);
  border-color: rgb(83.234063745, 48.2290836653, 147.0209163347);
}
.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(132.6, 94.35, 202.3, 0.5);
}

.btn-pink {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}
.btn-pink:hover {
  color: #fff;
  background-color: rgb(227.9270833333, 27.8229166667, 119.6354166667);
  border-color: rgb(217.125, 25.875, 113.625);
}
.btn-pink:focus, .btn-pink.focus {
  color: #fff;
  background-color: rgb(227.9270833333, 27.8229166667, 119.6354166667);
  border-color: rgb(217.125, 25.875, 113.625);
  box-shadow: 0 0 0 0.2rem rgba(235.45, 90.95, 157.25, 0.5);
}
.btn-pink.disabled, .btn-pink:disabled {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}
.btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active, .show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: rgb(217.125, 25.875, 113.625);
  border-color: rgb(205.7326388889, 24.5173611111, 107.6631944444);
}
.btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus, .show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235.45, 90.95, 157.25, 0.5);
}

.btn-red {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-red:hover {
  color: #fff;
  background-color: rgb(200.082278481, 34.667721519, 50.5158227848);
  border-color: rgb(189.2151898734, 32.7848101266, 47.7721518987);
}
.btn-red:focus, .btn-red.focus {
  color: #fff;
  background-color: rgb(200.082278481, 34.667721519, 50.5158227848);
  border-color: rgb(189.2151898734, 32.7848101266, 47.7721518987);
  box-shadow: 0 0 0 0.2rem rgba(225.25, 83.3, 96.9, 0.5);
}
.btn-red.disabled, .btn-red:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active, .show > .btn-red.dropdown-toggle {
  color: #fff;
  background-color: rgb(189.2151898734, 32.7848101266, 47.7721518987);
  border-color: rgb(178.3481012658, 30.9018987342, 45.0284810127);
}
.btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus, .show > .btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225.25, 83.3, 96.9, 0.5);
}

.btn-orange {
  color: #212529;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-orange:hover {
  color: #fff;
  background-color: rgb(232.7689873418, 106.9746835443, 1.9810126582);
  border-color: rgb(220.1265822785, 101.164556962, 1.8734177215);
}
.btn-orange:focus, .btn-orange.focus {
  color: #fff;
  background-color: rgb(232.7689873418, 106.9746835443, 1.9810126582);
  border-color: rgb(220.1265822785, 101.164556962, 1.8734177215);
  box-shadow: 0 0 0 0.2rem rgba(220, 112.65, 23.15, 0.5);
}
.btn-orange.disabled, .btn-orange:disabled {
  color: #212529;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active, .show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: rgb(220.1265822785, 101.164556962, 1.8734177215);
  border-color: rgb(207.4841772152, 95.3544303797, 1.7658227848);
}
.btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus, .show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 112.65, 23.15, 0.5);
}

.btn-yellow {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-yellow:hover {
  color: #212529;
  background-color: rgb(223.75, 167.8125, 0);
  border-color: rgb(211, 158.25, 0);
}
.btn-yellow:focus, .btn-yellow.focus {
  color: #212529;
  background-color: rgb(223.75, 167.8125, 0);
  border-color: rgb(211, 158.25, 0);
  box-shadow: 0 0 0 0.2rem rgba(221.7, 169.6, 12.1, 0.5);
}
.btn-yellow.disabled, .btn-yellow:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active, .show > .btn-yellow.dropdown-toggle {
  color: #212529;
  background-color: rgb(211, 158.25, 0);
  border-color: rgb(198.25, 148.6875, 0);
}
.btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus, .show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221.7, 169.6, 12.1, 0.5);
}

.btn-green {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-green:hover {
  color: #fff;
  background-color: rgb(32.6086956522, 136.1413043478, 56.25);
  border-color: rgb(30.1449275362, 125.8550724638, 52);
}
.btn-green:focus, .btn-green.focus {
  color: #fff;
  background-color: rgb(32.6086956522, 136.1413043478, 56.25);
  border-color: rgb(30.1449275362, 125.8550724638, 52);
  box-shadow: 0 0 0 0.2rem rgba(72.25, 180.2, 96.9, 0.5);
}
.btn-green.disabled, .btn-green:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active, .show > .btn-green.dropdown-toggle {
  color: #fff;
  background-color: rgb(30.1449275362, 125.8550724638, 52);
  border-color: rgb(27.6811594203, 115.5688405797, 47.75);
}
.btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus, .show > .btn-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72.25, 180.2, 96.9, 0.5);
}

.btn-teal {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-teal:hover {
  color: #fff;
  background-color: rgb(26.7467811159, 168.0032188841, 126.2113733906);
  border-color: rgb(24.9957081545, 157.0042918455, 117.9484978541);
}
.btn-teal:focus, .btn-teal.focus {
  color: #fff;
  background-color: rgb(26.7467811159, 168.0032188841, 126.2113733906);
  border-color: rgb(24.9957081545, 157.0042918455, 117.9484978541);
  box-shadow: 0 0 0 0.2rem rgba(65.45, 209.1, 166.6, 0.5);
}
.btn-teal.disabled, .btn-teal:disabled {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active, .show > .btn-teal.dropdown-toggle {
  color: #fff;
  background-color: rgb(24.9957081545, 157.0042918455, 117.9484978541);
  border-color: rgb(23.2446351931, 146.0053648069, 109.6856223176);
}
.btn-teal:not(:disabled):not(.disabled):active:focus, .btn-teal:not(:disabled):not(.disabled).active:focus, .show > .btn-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65.45, 209.1, 166.6, 0.5);
}

.btn-cyan {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-cyan:hover {
  color: #fff;
  background-color: rgb(18.75, 132.0652173913, 150);
  border-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
}
.btn-cyan:focus, .btn-cyan.focus {
  color: #fff;
  background-color: rgb(18.75, 132.0652173913, 150);
  border-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
  box-shadow: 0 0 0 0.2rem rgba(57.8, 175.95, 194.65, 0.5);
}
.btn-cyan.disabled, .btn-cyan:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-cyan:not(:disabled):not(.disabled):active, .btn-cyan:not(:disabled):not(.disabled).active, .show > .btn-cyan.dropdown-toggle {
  color: #fff;
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
  border-color: rgb(15.9166666667, 112.1086956522, 127.3333333333);
}
.btn-cyan:not(:disabled):not(.disabled):active:focus, .btn-cyan:not(:disabled):not(.disabled).active:focus, .show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(57.8, 175.95, 194.65, 0.5);
}

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #212529;
  background-color: rgb(235.875, 235.875, 235.875);
  border-color: rgb(229.5, 229.5, 229.5);
}
.btn-white:focus, .btn-white.focus {
  color: #212529;
  background-color: rgb(235.875, 235.875, 235.875);
  border-color: rgb(229.5, 229.5, 229.5);
  box-shadow: 0 0 0 0.2rem rgba(221.7, 222.3, 222.9, 0.5);
}
.btn-white.disabled, .btn-white:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: rgb(229.5, 229.5, 229.5);
  border-color: rgb(223.125, 223.125, 223.125);
}
.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221.7, 222.3, 222.9, 0.5);
}

.btn-gray {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-gray:hover {
  color: #fff;
  background-color: rgb(90.2703862661, 97.7929184549, 104.4796137339);
  border-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
}
.btn-gray:focus, .btn-gray.focus {
  color: #fff;
  background-color: rgb(90.2703862661, 97.7929184549, 104.4796137339);
  border-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
  box-shadow: 0 0 0 0.2rem rgba(130.05, 137.7, 144.5, 0.5);
}
.btn-gray.disabled, .btn-gray:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active, .show > .btn-gray.dropdown-toggle {
  color: #fff;
  background-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
  border-color: rgb(78.4506437768, 84.9881974249, 90.7993562232);
}
.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130.05, 137.7, 144.5, 0.5);
}

.btn-gray-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-gray-dark:hover {
  color: #fff;
  background-color: rgb(34.8534482759, 38.875, 42.8965517241);
  border-color: rgb(29.1379310345, 32.5, 35.8620689655);
}
.btn-gray-dark:focus, .btn-gray-dark.focus {
  color: #fff;
  background-color: rgb(34.8534482759, 38.875, 42.8965517241);
  border-color: rgb(29.1379310345, 32.5, 35.8620689655);
  box-shadow: 0 0 0 0.2rem rgba(82.45, 87.55, 92.65, 0.5);
}
.btn-gray-dark.disabled, .btn-gray-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-gray-dark:not(:disabled):not(.disabled):active, .btn-gray-dark:not(:disabled):not(.disabled).active, .show > .btn-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: rgb(29.1379310345, 32.5, 35.8620689655);
  border-color: rgb(23.4224137931, 26.125, 28.8275862069);
}
.btn-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-gray-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82.45, 87.55, 92.65, 0.5);
}

.btn-100 {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-100:hover {
  color: #212529;
  background-color: rgb(225.6875, 229.875, 234.0625);
  border-color: rgb(218.25, 223.5, 228.75);
}
.btn-100:focus, .btn-100.focus {
  color: #212529;
  background-color: rgb(225.6875, 229.875, 234.0625);
  border-color: rgb(218.25, 223.5, 228.75);
  box-shadow: 0 0 0 0.2rem rgba(215.75, 217.2, 218.65, 0.5);
}
.btn-100.disabled, .btn-100:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-100:not(:disabled):not(.disabled):active, .btn-100:not(:disabled):not(.disabled).active, .show > .btn-100.dropdown-toggle {
  color: #212529;
  background-color: rgb(218.25, 223.5, 228.75);
  border-color: rgb(210.8125, 217.125, 223.4375);
}
.btn-100:not(:disabled):not(.disabled):active:focus, .btn-100:not(:disabled):not(.disabled).active:focus, .show > .btn-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215.75, 217.2, 218.65, 0.5);
}

.btn-200 {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-200:hover {
  color: #212529;
  background-color: rgb(210.8552631579, 216.875, 222.8947368421);
  border-color: rgb(203.4736842105, 210.5, 217.5263157895);
}
.btn-200:focus, .btn-200.focus {
  color: #212529;
  background-color: rgb(210.8552631579, 216.875, 222.8947368421);
  border-color: rgb(203.4736842105, 210.5, 217.5263157895);
  box-shadow: 0 0 0 0.2rem rgba(203, 206.15, 209.3, 0.5);
}
.btn-200.disabled, .btn-200:disabled {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-200:not(:disabled):not(.disabled):active, .btn-200:not(:disabled):not(.disabled).active, .show > .btn-200.dropdown-toggle {
  color: #212529;
  background-color: rgb(203.4736842105, 210.5, 217.5263157895);
  border-color: rgb(196.0921052632, 204.125, 212.1578947368);
}
.btn-200:not(:disabled):not(.disabled):active:focus, .btn-200:not(:disabled):not(.disabled).active:focus, .show > .btn-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 206.15, 209.3, 0.5);
}

.btn-300 {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.btn-300:hover {
  color: #212529;
  background-color: rgb(200.2370689655, 206.875, 213.5129310345);
  border-color: rgb(192.9827586207, 200.5, 208.0172413793);
}
.btn-300:focus, .btn-300.focus {
  color: #212529;
  background-color: rgb(200.2370689655, 206.875, 213.5129310345);
  border-color: rgb(192.9827586207, 200.5, 208.0172413793);
  box-shadow: 0 0 0 0.2rem rgba(193.65, 197.65, 201.65, 0.5);
}
.btn-300.disabled, .btn-300:disabled {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.btn-300:not(:disabled):not(.disabled):active, .btn-300:not(:disabled):not(.disabled).active, .show > .btn-300.dropdown-toggle {
  color: #212529;
  background-color: rgb(192.9827586207, 200.5, 208.0172413793);
  border-color: rgb(185.7284482759, 194.125, 202.5215517241);
}
.btn-300:not(:disabled):not(.disabled):active:focus, .btn-300:not(:disabled):not(.disabled).active:focus, .show > .btn-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(193.65, 197.65, 201.65, 0.5);
}

.btn-400 {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-400:hover {
  color: #212529;
  background-color: rgb(184.2063953488, 192.875, 201.5436046512);
  border-color: rgb(176.9418604651, 186.5, 196.0581395349);
}
.btn-400:focus, .btn-400.focus {
  color: #212529;
  background-color: rgb(184.2063953488, 192.875, 201.5436046512);
  border-color: rgb(176.9418604651, 186.5, 196.0581395349);
  box-shadow: 0 0 0 0.2rem rgba(180.05, 185.75, 191.45, 0.5);
}
.btn-400.disabled, .btn-400:disabled {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-400:not(:disabled):not(.disabled):active, .btn-400:not(:disabled):not(.disabled).active, .show > .btn-400.dropdown-toggle {
  color: #212529;
  background-color: rgb(176.9418604651, 186.5, 196.0581395349);
  border-color: rgb(169.6773255814, 180.125, 190.5726744186);
}
.btn-400:not(:disabled):not(.disabled):active:focus, .btn-400:not(:disabled):not(.disabled).active:focus, .show > .btn-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180.05, 185.75, 191.45, 0.5);
}

.btn-500 {
  color: #212529;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.btn-500:hover {
  color: #212529;
  background-color: rgb(151.8074324324, 161.875, 171.9425675676);
  border-color: rgb(144.7432432432, 155.5, 166.2567567568);
}
.btn-500:focus, .btn-500.focus {
  color: #212529;
  background-color: rgb(151.8074324324, 161.875, 171.9425675676);
  border-color: rgb(144.7432432432, 155.5, 166.2567567568);
  box-shadow: 0 0 0 0.2rem rgba(152, 159.4, 166.8, 0.5);
}
.btn-500.disabled, .btn-500:disabled {
  color: #212529;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.btn-500:not(:disabled):not(.disabled):active, .btn-500:not(:disabled):not(.disabled).active, .show > .btn-500.dropdown-toggle {
  color: #212529;
  background-color: rgb(144.7432432432, 155.5, 166.2567567568);
  border-color: rgb(137.6790540541, 149.125, 160.5709459459);
}
.btn-500:not(:disabled):not(.disabled):active:focus, .btn-500:not(:disabled):not(.disabled).active:focus, .show > .btn-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 159.4, 166.8, 0.5);
}

.btn-600 {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-600:hover {
  color: #fff;
  background-color: rgb(90.2703862661, 97.7929184549, 104.4796137339);
  border-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
}
.btn-600:focus, .btn-600.focus {
  color: #fff;
  background-color: rgb(90.2703862661, 97.7929184549, 104.4796137339);
  border-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
  box-shadow: 0 0 0 0.2rem rgba(130.05, 137.7, 144.5, 0.5);
}
.btn-600.disabled, .btn-600:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-600:not(:disabled):not(.disabled):active, .btn-600:not(:disabled):not(.disabled).active, .show > .btn-600.dropdown-toggle {
  color: #fff;
  background-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
  border-color: rgb(78.4506437768, 84.9881974249, 90.7993562232);
}
.btn-600:not(:disabled):not(.disabled):active:focus, .btn-600:not(:disabled):not(.disabled).active:focus, .show > .btn-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130.05, 137.7, 144.5, 0.5);
}

.btn-700 {
  color: #fff;
  background-color: #495057;
  border-color: #495057;
}
.btn-700:hover {
  color: #fff;
  background-color: rgb(55.5484375, 60.875, 66.2015625);
  border-color: rgb(49.73125, 54.5, 59.26875);
}
.btn-700:focus, .btn-700.focus {
  color: #fff;
  background-color: rgb(55.5484375, 60.875, 66.2015625);
  border-color: rgb(49.73125, 54.5, 59.26875);
  box-shadow: 0 0 0 0.2rem rgba(100.3, 106.25, 112.2, 0.5);
}
.btn-700.disabled, .btn-700:disabled {
  color: #fff;
  background-color: #495057;
  border-color: #495057;
}
.btn-700:not(:disabled):not(.disabled):active, .btn-700:not(:disabled):not(.disabled).active, .show > .btn-700.dropdown-toggle {
  color: #fff;
  background-color: rgb(49.73125, 54.5, 59.26875);
  border-color: rgb(43.9140625, 48.125, 52.3359375);
}
.btn-700:not(:disabled):not(.disabled):active:focus, .btn-700:not(:disabled):not(.disabled).active:focus, .show > .btn-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(100.3, 106.25, 112.2, 0.5);
}

.btn-800 {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-800:hover {
  color: #fff;
  background-color: rgb(34.8534482759, 38.875, 42.8965517241);
  border-color: rgb(29.1379310345, 32.5, 35.8620689655);
}
.btn-800:focus, .btn-800.focus {
  color: #fff;
  background-color: rgb(34.8534482759, 38.875, 42.8965517241);
  border-color: rgb(29.1379310345, 32.5, 35.8620689655);
  box-shadow: 0 0 0 0.2rem rgba(82.45, 87.55, 92.65, 0.5);
}
.btn-800.disabled, .btn-800:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-800:not(:disabled):not(.disabled):active, .btn-800:not(:disabled):not(.disabled).active, .show > .btn-800.dropdown-toggle {
  color: #fff;
  background-color: rgb(29.1379310345, 32.5, 35.8620689655);
  border-color: rgb(23.4224137931, 26.125, 28.8275862069);
}
.btn-800:not(:disabled):not(.disabled):active:focus, .btn-800:not(:disabled):not(.disabled).active:focus, .show > .btn-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82.45, 87.55, 92.65, 0.5);
}

.btn-900 {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-900:hover {
  color: #fff;
  background-color: rgb(15.9425675676, 17.875, 19.8074324324);
  border-color: rgb(10.2567567568, 11.5, 12.7432432432);
}
.btn-900:focus, .btn-900.focus {
  color: #fff;
  background-color: rgb(15.9425675676, 17.875, 19.8074324324);
  border-color: rgb(10.2567567568, 11.5, 12.7432432432);
  box-shadow: 0 0 0 0.2rem rgba(66.3, 69.7, 73.1, 0.5);
}
.btn-900.disabled, .btn-900:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-900:not(:disabled):not(.disabled):active, .btn-900:not(:disabled):not(.disabled).active, .show > .btn-900.dropdown-toggle {
  color: #fff;
  background-color: rgb(10.2567567568, 11.5, 12.7432432432);
  border-color: rgb(4.5709459459, 5.125, 5.6790540541);
}
.btn-900:not(:disabled):not(.disabled):active:focus, .btn-900:not(:disabled):not(.disabled).active:focus, .show > .btn-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66.3, 69.7, 73.1, 0.5);
}

.btn-outline-primary {
  color: #1d71b8;
  border-color: #1d71b8;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #1d71b8;
  border-color: #1d71b8;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 113, 184, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1d71b8;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1d71b8;
  border-color: #1d71b8;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 113, 184, 0.5);
}

.btn-outline-secondary {
  color: rgb(174.661971831, 211.338028169, 242.338028169);
  border-color: rgb(174.661971831, 211.338028169, 242.338028169);
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: rgb(174.661971831, 211.338028169, 242.338028169);
  border-color: rgb(174.661971831, 211.338028169, 242.338028169);
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(174.661971831, 211.338028169, 242.338028169, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: rgb(174.661971831, 211.338028169, 242.338028169);
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: rgb(174.661971831, 211.338028169, 242.338028169);
  border-color: rgb(174.661971831, 211.338028169, 242.338028169);
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(174.661971831, 211.338028169, 242.338028169, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #EB5757;
  border-color: #EB5757;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #EB5757;
  border-color: #EB5757;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #EB5757;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #EB5757;
  border-color: #EB5757;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-hbmlp-color-steps {
  color: #999;
  border-color: #999;
}
.btn-outline-hbmlp-color-steps:hover {
  color: #212529;
  background-color: #999;
  border-color: #999;
}
.btn-outline-hbmlp-color-steps:focus, .btn-outline-hbmlp-color-steps.focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}
.btn-outline-hbmlp-color-steps.disabled, .btn-outline-hbmlp-color-steps:disabled {
  color: #999;
  background-color: transparent;
}
.btn-outline-hbmlp-color-steps:not(:disabled):not(.disabled):active, .btn-outline-hbmlp-color-steps:not(:disabled):not(.disabled).active, .show > .btn-outline-hbmlp-color-steps.dropdown-toggle {
  color: #212529;
  background-color: #999;
  border-color: #999;
}
.btn-outline-hbmlp-color-steps:not(:disabled):not(.disabled):active:focus, .btn-outline-hbmlp-color-steps:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-hbmlp-color-steps.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-outline-secondary-light {
  color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
  border-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
}
.btn-outline-secondary-light:hover {
  color: #212529;
  background-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
  border-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
}
.btn-outline-secondary-light:focus, .btn-outline-secondary-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(219.7683098592, 235.1936256247, 248.2316901408, 0.5);
}
.btn-outline-secondary-light.disabled, .btn-outline-secondary-light:disabled {
  color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
  background-color: transparent;
}
.btn-outline-secondary-light:not(:disabled):not(.disabled):active, .btn-outline-secondary-light:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary-light.dropdown-toggle {
  color: #212529;
  background-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
  border-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
}
.btn-outline-secondary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219.7683098592, 235.1936256247, 248.2316901408, 0.5);
}

.btn-outline-blue {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-blue:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-blue:focus, .btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active, .show > .btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-indigo {
  color: #6610f2;
  border-color: #6610f2;
}
.btn-outline-indigo:hover {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-outline-indigo:focus, .btn-outline-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
}
.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
  color: #6610f2;
  background-color: transparent;
}
.btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active, .show > .btn-outline-indigo.dropdown-toggle {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
}

.btn-outline-purple {
  color: #6f42c1;
  border-color: #6f42c1;
}
.btn-outline-purple:hover {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-outline-purple:focus, .btn-outline-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
}
.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #6f42c1;
  background-color: transparent;
}
.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active, .show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
}

.btn-outline-pink {
  color: #e83e8c;
  border-color: #e83e8c;
}
.btn-outline-pink:hover {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}
.btn-outline-pink:focus, .btn-outline-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
}
.btn-outline-pink.disabled, .btn-outline-pink:disabled {
  color: #e83e8c;
  background-color: transparent;
}
.btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active, .show > .btn-outline-pink.dropdown-toggle {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}
.btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
}

.btn-outline-red {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-red:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-red:focus, .btn-outline-red.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-red.disabled, .btn-outline-red:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active, .show > .btn-outline-red.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-red:not(:disabled):not(.disabled):active:focus, .btn-outline-red:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-orange {
  color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-orange:hover {
  color: #212529;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-orange:focus, .btn-outline-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}
.btn-outline-orange.disabled, .btn-outline-orange:disabled {
  color: #fd7e14;
  background-color: transparent;
}
.btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active, .show > .btn-outline-orange.dropdown-toggle {
  color: #212529;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}

.btn-outline-yellow {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-yellow:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-yellow:focus, .btn-outline-yellow.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active, .show > .btn-outline-yellow.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-green {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-green:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-green:focus, .btn-outline-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-green.disabled, .btn-outline-green:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active, .show > .btn-outline-green.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-teal {
  color: #20c997;
  border-color: #20c997;
}
.btn-outline-teal:hover {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-outline-teal:focus, .btn-outline-teal.focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
}
.btn-outline-teal.disabled, .btn-outline-teal:disabled {
  color: #20c997;
  background-color: transparent;
}
.btn-outline-teal:not(:disabled):not(.disabled):active, .btn-outline-teal:not(:disabled):not(.disabled).active, .show > .btn-outline-teal.dropdown-toggle {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-outline-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-teal:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
}

.btn-outline-cyan {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-cyan:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-cyan:focus, .btn-outline-cyan.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-cyan:not(:disabled):not(.disabled):active, .btn-outline-cyan:not(:disabled):not(.disabled).active, .show > .btn-outline-cyan.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-cyan:not(:disabled):not(.disabled):active:focus, .btn-outline-cyan:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active, .show > .btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-gray {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-gray:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-gray:focus, .btn-outline-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-gray.disabled, .btn-outline-gray:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active, .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-gray-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-gray-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-gray-dark:focus, .btn-outline-gray-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-gray-dark.disabled, .btn-outline-gray-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-gray-dark:not(:disabled):not(.disabled):active, .btn-outline-gray-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-100 {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-100:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-100:focus, .btn-outline-100.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-100.disabled, .btn-outline-100:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-100:not(:disabled):not(.disabled):active, .btn-outline-100:not(:disabled):not(.disabled).active, .show > .btn-outline-100.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-100:not(:disabled):not(.disabled):active:focus, .btn-outline-100:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-200 {
  color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-200:hover {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-200:focus, .btn-outline-200.focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}
.btn-outline-200.disabled, .btn-outline-200:disabled {
  color: #e9ecef;
  background-color: transparent;
}
.btn-outline-200:not(:disabled):not(.disabled):active, .btn-outline-200:not(:disabled):not(.disabled).active, .show > .btn-outline-200.dropdown-toggle {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-200:not(:disabled):not(.disabled):active:focus, .btn-outline-200:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-outline-300 {
  color: #dee2e6;
  border-color: #dee2e6;
}
.btn-outline-300:hover {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.btn-outline-300:focus, .btn-outline-300.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}
.btn-outline-300.disabled, .btn-outline-300:disabled {
  color: #dee2e6;
  background-color: transparent;
}
.btn-outline-300:not(:disabled):not(.disabled):active, .btn-outline-300:not(:disabled):not(.disabled).active, .show > .btn-outline-300.dropdown-toggle {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.btn-outline-300:not(:disabled):not(.disabled):active:focus, .btn-outline-300:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-400 {
  color: #ced4da;
  border-color: #ced4da;
}
.btn-outline-400:hover {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-outline-400:focus, .btn-outline-400.focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}
.btn-outline-400.disabled, .btn-outline-400:disabled {
  color: #ced4da;
  background-color: transparent;
}
.btn-outline-400:not(:disabled):not(.disabled):active, .btn-outline-400:not(:disabled):not(.disabled).active, .show > .btn-outline-400.dropdown-toggle {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-outline-400:not(:disabled):not(.disabled):active:focus, .btn-outline-400:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-outline-500 {
  color: #adb5bd;
  border-color: #adb5bd;
}
.btn-outline-500:hover {
  color: #212529;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.btn-outline-500:focus, .btn-outline-500.focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}
.btn-outline-500.disabled, .btn-outline-500:disabled {
  color: #adb5bd;
  background-color: transparent;
}
.btn-outline-500:not(:disabled):not(.disabled):active, .btn-outline-500:not(:disabled):not(.disabled).active, .show > .btn-outline-500.dropdown-toggle {
  color: #212529;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.btn-outline-500:not(:disabled):not(.disabled):active:focus, .btn-outline-500:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}

.btn-outline-600 {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-600:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-600:focus, .btn-outline-600.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-600.disabled, .btn-outline-600:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-600:not(:disabled):not(.disabled):active, .btn-outline-600:not(:disabled):not(.disabled).active, .show > .btn-outline-600.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-600:not(:disabled):not(.disabled):active:focus, .btn-outline-600:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-700 {
  color: #495057;
  border-color: #495057;
}
.btn-outline-700:hover {
  color: #fff;
  background-color: #495057;
  border-color: #495057;
}
.btn-outline-700:focus, .btn-outline-700.focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 80, 87, 0.5);
}
.btn-outline-700.disabled, .btn-outline-700:disabled {
  color: #495057;
  background-color: transparent;
}
.btn-outline-700:not(:disabled):not(.disabled):active, .btn-outline-700:not(:disabled):not(.disabled).active, .show > .btn-outline-700.dropdown-toggle {
  color: #fff;
  background-color: #495057;
  border-color: #495057;
}
.btn-outline-700:not(:disabled):not(.disabled):active:focus, .btn-outline-700:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 80, 87, 0.5);
}

.btn-outline-800 {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-800:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-800:focus, .btn-outline-800.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-800.disabled, .btn-outline-800:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-800:not(:disabled):not(.disabled):active, .btn-outline-800:not(:disabled):not(.disabled).active, .show > .btn-outline-800.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-800:not(:disabled):not(.disabled):active:focus, .btn-outline-800:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-900 {
  color: #212529;
  border-color: #212529;
}
.btn-outline-900:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-outline-900:focus, .btn-outline-900.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5);
}
.btn-outline-900.disabled, .btn-outline-900:disabled {
  color: #212529;
  background-color: transparent;
}
.btn-outline-900:not(:disabled):not(.disabled):active, .btn-outline-900:not(:disabled):not(.disabled).active, .show > .btn-outline-900.dropdown-toggle {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-outline-900:not(:disabled):not(.disabled):active:focus, .btn-outline-900:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #1d71b8;
  text-decoration: none;
}
.btn-link:hover {
  color: rgb(18.5845070423, 72.4154929577, 117.9154929577);
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #260211;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: rgb(21.6283783784, 24.25, 26.8716216216);
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1d71b8;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1d71b8;
  background-color: #1d71b8;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(29, 113, 184, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgb(108.5774647887, 175.4225352113, 231.9225352113);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: rgb(152.6338028169, 199.3661971831, 238.8661971831);
  border-color: rgb(152.6338028169, 199.3661971831, 238.8661971831);
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1d71b8;
  background-color: #1d71b8;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(29, 113, 184, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(29, 113, 184, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(29, 113, 184, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(29, 113, 184, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0rem;
  appearance: none;
}
.custom-select:focus {
  border-color: rgb(108.5774647887, 175.4225352113, 231.9225352113);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(29, 113, 184, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: rgb(108.5774647887, 175.4225352113, 231.9225352113);
  box-shadow: 0 0 0 0.2rem rgba(29, 113, 184, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0rem 0rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(29, 113, 184, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(29, 113, 184, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(29, 113, 184, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1d71b8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: rgb(152.6338028169, 199.3661971831, 238.8661971831);
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1d71b8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: rgb(152.6338028169, 199.3661971831, 238.8661971831);
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #1d71b8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: rgb(152.6338028169, 199.3661971831, 238.8661971831);
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1d71b8;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0rem - 1px);
  border-top-right-radius: calc(0rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0rem - 1px);
  border-bottom-left-radius: calc(0rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0rem - 1px) calc(0rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0rem - 1px) calc(0rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0rem - 1px);
  border-top-right-radius: calc(0rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0rem - 1px);
  border-bottom-left-radius: calc(0rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1d71b8;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: rgb(18.5845070423, 72.4154929577, 117.9154929577);
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(29, 113, 184, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1d71b8;
  border-color: #1d71b8;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #1d71b8;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: rgb(22.0563380282, 85.9436619718, 139.9436619718);
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(29, 113, 184, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: rgb(174.661971831, 211.338028169, 242.338028169);
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: rgb(130.6056338028, 187.3943661972, 235.3943661972);
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(174.661971831, 211.338028169, 242.338028169, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: rgb(30.1449275362, 125.8550724638, 52);
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: rgb(211, 158.25, 0);
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #EB5757;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: rgb(229.5744680851, 41.4255319149, 41.4255319149);
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: rgb(218.25, 223.5, 228.75);
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: rgb(29.1379310345, 32.5, 35.8620689655);
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-hbmlp-color-steps {
  color: #212529;
  background-color: #999;
}
a.badge-hbmlp-color-steps:hover, a.badge-hbmlp-color-steps:focus {
  color: #212529;
  background-color: rgb(127.5, 127.5, 127.5);
}
a.badge-hbmlp-color-steps:focus, a.badge-hbmlp-color-steps.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.badge-secondary-light {
  color: #212529;
  background-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
}
a.badge-secondary-light:hover, a.badge-secondary-light:focus {
  color: #212529;
  background-color: rgb(176.986971831, 211.143028169, 240.013028169);
}
a.badge-secondary-light:focus, a.badge-secondary-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(219.7683098592, 235.1936256247, 248.2316901408, 0.5);
}

.badge-blue {
  color: #fff;
  background-color: #007bff;
}
a.badge-blue:hover, a.badge-blue:focus {
  color: #fff;
  background-color: rgb(0, 98.4, 204);
}
a.badge-blue:focus, a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-indigo {
  color: #fff;
  background-color: #6610f2;
}
a.badge-indigo:hover, a.badge-indigo:focus {
  color: #fff;
  background-color: rgb(81.3214285714, 10.6785714286, 196.3214285714);
}
a.badge-indigo:focus, a.badge-indigo.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
}

.badge-purple {
  color: #fff;
  background-color: #6f42c1;
}
a.badge-purple:hover, a.badge-purple:focus {
  color: #fff;
  background-color: rgb(88.6693227092, 51.3784860558, 156.6215139442);
}
a.badge-purple:focus, a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
}

.badge-pink {
  color: #fff;
  background-color: #e83e8c;
}
a.badge-pink:hover, a.badge-pink:focus {
  color: #fff;
  background-color: rgb(217.125, 25.875, 113.625);
}
a.badge-pink:focus, a.badge-pink.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
}

.badge-red {
  color: #fff;
  background-color: #dc3545;
}
a.badge-red:hover, a.badge-red:focus {
  color: #fff;
  background-color: rgb(189.2151898734, 32.7848101266, 47.7721518987);
}
a.badge-red:focus, a.badge-red.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-orange {
  color: #212529;
  background-color: #fd7e14;
}
a.badge-orange:hover, a.badge-orange:focus {
  color: #212529;
  background-color: rgb(220.1265822785, 101.164556962, 1.8734177215);
}
a.badge-orange:focus, a.badge-orange.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}

.badge-yellow {
  color: #212529;
  background-color: #ffc107;
}
a.badge-yellow:hover, a.badge-yellow:focus {
  color: #212529;
  background-color: rgb(211, 158.25, 0);
}
a.badge-yellow:focus, a.badge-yellow.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-green {
  color: #fff;
  background-color: #28a745;
}
a.badge-green:hover, a.badge-green:focus {
  color: #fff;
  background-color: rgb(30.1449275362, 125.8550724638, 52);
}
a.badge-green:focus, a.badge-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-teal {
  color: #fff;
  background-color: #20c997;
}
a.badge-teal:hover, a.badge-teal:focus {
  color: #fff;
  background-color: rgb(24.9957081545, 157.0042918455, 117.9484978541);
}
a.badge-teal:focus, a.badge-teal.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
}

.badge-cyan {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-cyan:hover, a.badge-cyan:focus {
  color: #fff;
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
}
a.badge-cyan:focus, a.badge-cyan.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-white {
  color: #212529;
  background-color: #fff;
}
a.badge-white:hover, a.badge-white:focus {
  color: #212529;
  background-color: rgb(229.5, 229.5, 229.5);
}
a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-gray {
  color: #fff;
  background-color: #6c757d;
}
a.badge-gray:hover, a.badge-gray:focus {
  color: #fff;
  background-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
}
a.badge-gray:focus, a.badge-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-gray-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-gray-dark:hover, a.badge-gray-dark:focus {
  color: #fff;
  background-color: rgb(29.1379310345, 32.5, 35.8620689655);
}
a.badge-gray-dark:focus, a.badge-gray-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-100 {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-100:hover, a.badge-100:focus {
  color: #212529;
  background-color: rgb(218.25, 223.5, 228.75);
}
a.badge-100:focus, a.badge-100.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-200 {
  color: #212529;
  background-color: #e9ecef;
}
a.badge-200:hover, a.badge-200:focus {
  color: #212529;
  background-color: rgb(203.4736842105, 210.5, 217.5263157895);
}
a.badge-200:focus, a.badge-200.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.badge-300 {
  color: #212529;
  background-color: #dee2e6;
}
a.badge-300:hover, a.badge-300:focus {
  color: #212529;
  background-color: rgb(192.9827586207, 200.5, 208.0172413793);
}
a.badge-300:focus, a.badge-300.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.badge-400 {
  color: #212529;
  background-color: #ced4da;
}
a.badge-400:hover, a.badge-400:focus {
  color: #212529;
  background-color: rgb(176.9418604651, 186.5, 196.0581395349);
}
a.badge-400:focus, a.badge-400.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.badge-500 {
  color: #212529;
  background-color: #adb5bd;
}
a.badge-500:hover, a.badge-500:focus {
  color: #212529;
  background-color: rgb(144.7432432432, 155.5, 166.2567567568);
}
a.badge-500:focus, a.badge-500.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}

.badge-600 {
  color: #fff;
  background-color: #6c757d;
}
a.badge-600:hover, a.badge-600:focus {
  color: #fff;
  background-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
}
a.badge-600:focus, a.badge-600.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-700 {
  color: #fff;
  background-color: #495057;
}
a.badge-700:hover, a.badge-700:focus {
  color: #fff;
  background-color: rgb(49.73125, 54.5, 59.26875);
}
a.badge-700:focus, a.badge-700.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(73, 80, 87, 0.5);
}

.badge-800 {
  color: #fff;
  background-color: #343a40;
}
a.badge-800:hover, a.badge-800:focus {
  color: #fff;
  background-color: rgb(29.1379310345, 32.5, 35.8620689655);
}
a.badge-800:focus, a.badge-800.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-900 {
  color: #fff;
  background-color: #212529;
}
a.badge-900:hover, a.badge-900:focus {
  color: #fff;
  background-color: rgb(10.2567567568, 11.5, 12.7432432432);
}
a.badge-900:focus, a.badge-900.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e5e5e5;
  border-radius: 0rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: rgb(15.08, 58.76, 95.68);
  background-color: rgb(209.8, 226.6, 240.8);
  border-color: rgb(191.72, 215.24, 235.12);
}
.alert-primary hr {
  border-top-color: rgb(172.315959596, 203.0480808081, 229.024040404);
}
.alert-primary .alert-link {
  color: rgb(8.1363380282, 31.7036619718, 51.6236619718);
}

.alert-secondary {
  color: rgb(90.8242253521, 109.8957746479, 126.0157746479);
  background-color: rgb(238.9323943662, 246.2676056338, 252.4676056338);
  border-color: rgb(232.5053521127, 242.7746478873, 251.4546478873);
}
.alert-secondary hr {
  border-top-color: rgb(210.4771830986, 230.8028169014, 247.9828169014);
}
.alert-secondary .alert-link {
  color: rgb(69.4626892289, 84.0486776776, 96.3773107711);
}

.alert-success {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(212, 237.4, 217.8);
  border-color: rgb(194.8, 230.36, 202.92);
}
.alert-success hr {
  border-top-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
.alert-success .alert-link {
  color: rgb(10.9449275362, 45.6950724638, 18.88);
}

.alert-info {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(208.6, 236.4, 240.8);
  border-color: rgb(190.04, 228.96, 235.12);
}
.alert-info hr {
  border-top-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.alert-info .alert-link {
  color: rgb(6.2933333333, 44.3269565217, 50.3466666667);
}

.alert-warning {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 242.6, 205.4);
  border-color: rgb(255, 237.64, 185.56);
}
.alert-warning hr {
  border-top-color: rgb(255, 231.265, 160.06);
}
.alert-warning .alert-link {
  color: rgb(82.9625954198, 62.7912977099, 2.2774045802);
}

.alert-danger {
  color: rgb(122.2, 45.24, 45.24);
  background-color: rgb(251, 221.4, 221.4);
  border-color: rgb(249.4, 207.96, 207.96);
}
.alert-danger hr {
  border-top-color: rgb(246.6872340426, 185.1727659574, 185.1727659574);
}
.alert-danger .alert-link {
  color: rgb(84.9795031056, 31.4604968944, 31.4604968944);
}

.alert-light {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(253.6, 253.8, 254);
  border-color: rgb(253.04, 253.32, 253.6);
}
.alert-light hr {
  border-top-color: rgb(238.165, 240.57, 242.975);
}
.alert-light .alert-link {
  color: rgb(103.5492351816, 103.98, 104.4107648184);
}

.alert-dark {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(214.4, 215.6, 216.8);
  border-color: rgb(198.16, 199.84, 201.52);
}
.alert-dark hr {
  border-top-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
.alert-dark .alert-link {
  color: rgb(4.1779310345, 4.66, 5.1420689655);
}

.alert-hbmlp-color-steps {
  color: rgb(79.56, 79.56, 79.56);
  background-color: rgb(234.6, 234.6, 234.6);
  border-color: rgb(226.44, 226.44, 226.44);
}
.alert-hbmlp-color-steps hr {
  border-top-color: rgb(213.69, 213.69, 213.69);
}
.alert-hbmlp-color-steps .alert-link {
  color: rgb(54.06, 54.06, 54.06);
}

.alert-secondary-light {
  color: rgb(114.2795211268, 122.3006853249, 129.0804788732);
  background-color: rgb(247.9536619718, 251.0387251249, 253.6463380282);
  border-color: rgb(245.1351267606, 249.4542151749, 253.1048732394);
}
.alert-secondary-light hr {
  border-top-color: rgb(223.7444577465, 237.4289164471, 248.9955422535);
}
.alert-secondary-light .alert-link {
  color: rgb(90.330410437, 96.6706107375, 102.029589563);
}

.alert-blue {
  color: rgb(0, 63.96, 132.6);
  background-color: rgb(204, 228.6, 255);
  border-color: rgb(183.6, 218.04, 255);
}
.alert-blue hr {
  border-top-color: rgb(158.1, 204.84, 255);
}
.alert-blue .alert-link {
  color: rgb(0, 39.36, 81.6);
}

.alert-indigo {
  color: rgb(53.04, 8.32, 125.84);
  background-color: rgb(224.4, 207.2, 252.4);
  border-color: rgb(212.16, 188.08, 251.36);
}
.alert-indigo hr {
  border-top-color: rgb(196.6778571429, 163.8954761905, 250.0445238095);
}
.alert-indigo .alert-link {
  color: rgb(32.8772093023, 5.1572093023, 78.0027906977);
}

.alert-purple {
  color: rgb(57.72, 34.32, 100.36);
  background-color: rgb(226.2, 217.2, 242.6);
  border-color: rgb(214.68, 202.08, 237.64);
}
.alert-purple hr {
  border-top-color: rgb(200.0505179283, 182.8788047809, 231.3411952191);
}
.alert-purple .alert-link {
  color: rgb(35.8628571429, 21.3238610039, 62.3561389961);
}

.alert-pink {
  color: rgb(120.64, 32.24, 72.8);
  background-color: rgb(250.4, 216.4, 232);
  border-color: rgb(248.56, 200.96, 222.8);
}
.alert-pink hr {
  border-top-color: rgb(245.8447222222, 178.1752777778, 209.2236111111);
}
.alert-pink .alert-link {
  color: rgb(80.3951020408, 21.4848979592, 48.5142857143);
}

.alert-red {
  color: rgb(114.4, 27.56, 35.88);
  background-color: rgb(248, 214.6, 217.8);
  border-color: rgb(245.2, 198.44, 202.92);
}
.alert-red hr {
  border-top-color: rgb(241.4341772152, 176.7058227848, 182.9073417722);
}
.alert-red .alert-link {
  color: rgb(73.3010989011, 17.6589010989, 22.9898901099);
}

.alert-orange {
  color: rgb(131.56, 65.52, 10.4);
  background-color: rgb(254.6, 229.2, 208);
  border-color: rgb(254.44, 218.88, 189.2);
}
.alert-orange hr {
  border-top-color: rgb(254.2248101266, 205.0002531646, 163.9151898734);
}
.alert-orange .alert-link {
  color: rgb(84.2962637363, 41.9815384615, 6.6637362637);
}

.alert-yellow {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 242.6, 205.4);
  border-color: rgb(255, 237.64, 185.56);
}
.alert-yellow hr {
  border-top-color: rgb(255, 231.265, 160.06);
}
.alert-yellow .alert-link {
  color: rgb(82.9625954198, 62.7912977099, 2.2774045802);
}

.alert-green {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(212, 237.4, 217.8);
  border-color: rgb(194.8, 230.36, 202.92);
}
.alert-green hr {
  border-top-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
.alert-green .alert-link {
  color: rgb(10.9449275362, 45.6950724638, 18.88);
}

.alert-teal {
  color: rgb(16.64, 104.52, 78.52);
  background-color: rgb(210.4, 244.2, 234.2);
  border-color: rgb(192.56, 239.88, 225.88);
}
.alert-teal hr {
  border-top-color: rgb(172.0311191336, 234.9088808664, 216.3059927798);
}
.alert-teal .alert-link {
  color: rgb(9.6357081545, 60.5242918455, 45.4684978541);
}

.alert-cyan {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(208.6, 236.4, 240.8);
  border-color: rgb(190.04, 228.96, 235.12);
}
.alert-cyan hr {
  border-top-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.alert-cyan .alert-link {
  color: rgb(6.2933333333, 44.3269565217, 50.3466666667);
}

.alert-white {
  color: rgb(132.6, 132.6, 132.6);
  background-color: white;
  border-color: white;
}
.alert-white hr {
  border-top-color: rgb(242.25, 242.25, 242.25);
}
.alert-white .alert-link {
  color: rgb(107.1, 107.1, 107.1);
}

.alert-gray {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(225.6, 227.4, 229);
  border-color: rgb(213.84, 216.36, 218.6);
}
.alert-gray hr {
  border-top-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}
.alert-gray .alert-link {
  color: rgb(32.5205150215, 35.2305579399, 37.6394849785);
}

.alert-gray-dark {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(214.4, 215.6, 216.8);
  border-color: rgb(198.16, 199.84, 201.52);
}
.alert-gray-dark hr {
  border-top-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
.alert-gray-dark .alert-link {
  color: rgb(4.1779310345, 4.66, 5.1420689655);
}

.alert-100 {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(253.6, 253.8, 254);
  border-color: rgb(253.04, 253.32, 253.6);
}
.alert-100 hr {
  border-top-color: rgb(238.165, 240.57, 242.975);
}
.alert-100 .alert-link {
  color: rgb(103.5492351816, 103.98, 104.4107648184);
}

.alert-200 {
  color: rgb(121.16, 122.72, 124.28);
  background-color: rgb(250.6, 251.2, 251.8);
  border-color: rgb(248.84, 249.68, 250.52);
}
.alert-200 hr {
  border-top-color: rgb(234.0768421053, 236.93, 239.7831578947);
}
.alert-200 .alert-link {
  color: rgb(95.9841525424, 97.22, 98.4558474576);
}

.alert-300 {
  color: rgb(115.44, 117.52, 119.6);
  background-color: rgb(248.4, 249.2, 250);
  border-color: rgb(245.76, 246.88, 248);
}
.alert-300 hr {
  border-top-color: rgb(231.2513793103, 234.13, 237.0086206897);
}
.alert-300 .alert-link {
  color: rgb(90.3913274336, 92.02, 93.6486725664);
}

.alert-400 {
  color: rgb(107.12, 110.24, 113.36);
  background-color: rgb(245.2, 246.4, 247.6);
  border-color: rgb(241.28, 242.96, 244.64);
}
.alert-400 hr {
  border-top-color: rgb(226.7509302326, 230.21, 233.6690697674);
}
.alert-400 .alert-link {
  color: rgb(82.3416981132, 84.74, 87.1383018868);
}

.alert-500 {
  color: rgb(89.96, 94.12, 98.28);
  background-color: rgb(238.6, 240.2, 241.8);
  border-color: rgb(232.04, 234.28, 236.52);
}
.alert-500 hr {
  border-top-color: rgb(217.9116216216, 221.53, 225.1483783784);
}
.alert-500 .alert-link {
  color: rgb(65.5870718232, 68.62, 71.6529281768);
}

.alert-600 {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(225.6, 227.4, 229);
  border-color: rgb(213.84, 216.36, 218.6);
}
.alert-600 hr {
  border-top-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}
.alert-600 .alert-link {
  color: rgb(32.5205150215, 35.2305579399, 37.6394849785);
}

.alert-700 {
  color: rgb(37.96, 41.6, 45.24);
  background-color: rgb(218.6, 220, 221.4);
  border-color: rgb(204.04, 206, 207.96);
}
.alert-700 hr {
  border-top-color: rgb(190.78, 193.25, 195.72);
}
.alert-700 .alert-link {
  color: rgb(14.69125, 16.1, 17.50875);
}

.alert-800 {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(214.4, 215.6, 216.8);
  border-color: rgb(198.16, 199.84, 201.52);
}
.alert-800 hr {
  border-top-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
.alert-800 .alert-link {
  color: rgb(4.1779310345, 4.66, 5.1420689655);
}

.alert-900 {
  color: rgb(17.16, 19.24, 21.32);
  background-color: rgb(210.6, 211.4, 212.2);
  border-color: rgb(192.84, 193.96, 195.08);
}
.alert-900 hr {
  border-top-color: rgb(179.8560550459, 181.21, 182.5639449541);
}
.alert-900 .alert-link {
  color: black;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1d71b8;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #260211;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1d71b8;
  border-color: #1d71b8;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: rgb(15.08, 58.76, 95.68);
  background-color: rgb(191.72, 215.24, 235.12);
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: rgb(15.08, 58.76, 95.68);
  background-color: rgb(172.315959596, 203.0480808081, 229.024040404);
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: rgb(15.08, 58.76, 95.68);
  border-color: rgb(15.08, 58.76, 95.68);
}

.list-group-item-secondary {
  color: rgb(90.8242253521, 109.8957746479, 126.0157746479);
  background-color: rgb(232.5053521127, 242.7746478873, 251.4546478873);
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: rgb(90.8242253521, 109.8957746479, 126.0157746479);
  background-color: rgb(210.4771830986, 230.8028169014, 247.9828169014);
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: rgb(90.8242253521, 109.8957746479, 126.0157746479);
  border-color: rgb(90.8242253521, 109.8957746479, 126.0157746479);
}

.list-group-item-success {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(194.8, 230.36, 202.92);
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: rgb(20.8, 86.84, 35.88);
  border-color: rgb(20.8, 86.84, 35.88);
}

.list-group-item-info {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(190.04, 228.96, 235.12);
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: rgb(11.96, 84.24, 95.68);
  border-color: rgb(11.96, 84.24, 95.68);
}

.list-group-item-warning {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 237.64, 185.56);
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 231.265, 160.06);
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: rgb(132.6, 100.36, 3.64);
  border-color: rgb(132.6, 100.36, 3.64);
}

.list-group-item-danger {
  color: rgb(122.2, 45.24, 45.24);
  background-color: rgb(249.4, 207.96, 207.96);
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: rgb(122.2, 45.24, 45.24);
  background-color: rgb(246.6872340426, 185.1727659574, 185.1727659574);
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: rgb(122.2, 45.24, 45.24);
  border-color: rgb(122.2, 45.24, 45.24);
}

.list-group-item-light {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(253.04, 253.32, 253.6);
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(238.165, 240.57, 242.975);
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: rgb(128.96, 129.48, 130);
  border-color: rgb(128.96, 129.48, 130);
}

.list-group-item-dark {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(198.16, 199.84, 201.52);
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: rgb(27.04, 30.16, 33.28);
  border-color: rgb(27.04, 30.16, 33.28);
}

.list-group-item-hbmlp-color-steps {
  color: rgb(79.56, 79.56, 79.56);
  background-color: rgb(226.44, 226.44, 226.44);
}
.list-group-item-hbmlp-color-steps.list-group-item-action:hover, .list-group-item-hbmlp-color-steps.list-group-item-action:focus {
  color: rgb(79.56, 79.56, 79.56);
  background-color: rgb(213.69, 213.69, 213.69);
}
.list-group-item-hbmlp-color-steps.list-group-item-action.active {
  color: #fff;
  background-color: rgb(79.56, 79.56, 79.56);
  border-color: rgb(79.56, 79.56, 79.56);
}

.list-group-item-secondary-light {
  color: rgb(114.2795211268, 122.3006853249, 129.0804788732);
  background-color: rgb(245.1351267606, 249.4542151749, 253.1048732394);
}
.list-group-item-secondary-light.list-group-item-action:hover, .list-group-item-secondary-light.list-group-item-action:focus {
  color: rgb(114.2795211268, 122.3006853249, 129.0804788732);
  background-color: rgb(223.7444577465, 237.4289164471, 248.9955422535);
}
.list-group-item-secondary-light.list-group-item-action.active {
  color: #fff;
  background-color: rgb(114.2795211268, 122.3006853249, 129.0804788732);
  border-color: rgb(114.2795211268, 122.3006853249, 129.0804788732);
}

.list-group-item-blue {
  color: rgb(0, 63.96, 132.6);
  background-color: rgb(183.6, 218.04, 255);
}
.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: rgb(0, 63.96, 132.6);
  background-color: rgb(158.1, 204.84, 255);
}
.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: rgb(0, 63.96, 132.6);
  border-color: rgb(0, 63.96, 132.6);
}

.list-group-item-indigo {
  color: rgb(53.04, 8.32, 125.84);
  background-color: rgb(212.16, 188.08, 251.36);
}
.list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
  color: rgb(53.04, 8.32, 125.84);
  background-color: rgb(196.6778571429, 163.8954761905, 250.0445238095);
}
.list-group-item-indigo.list-group-item-action.active {
  color: #fff;
  background-color: rgb(53.04, 8.32, 125.84);
  border-color: rgb(53.04, 8.32, 125.84);
}

.list-group-item-purple {
  color: rgb(57.72, 34.32, 100.36);
  background-color: rgb(214.68, 202.08, 237.64);
}
.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: rgb(57.72, 34.32, 100.36);
  background-color: rgb(200.0505179283, 182.8788047809, 231.3411952191);
}
.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: rgb(57.72, 34.32, 100.36);
  border-color: rgb(57.72, 34.32, 100.36);
}

.list-group-item-pink {
  color: rgb(120.64, 32.24, 72.8);
  background-color: rgb(248.56, 200.96, 222.8);
}
.list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
  color: rgb(120.64, 32.24, 72.8);
  background-color: rgb(245.8447222222, 178.1752777778, 209.2236111111);
}
.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: rgb(120.64, 32.24, 72.8);
  border-color: rgb(120.64, 32.24, 72.8);
}

.list-group-item-red {
  color: rgb(114.4, 27.56, 35.88);
  background-color: rgb(245.2, 198.44, 202.92);
}
.list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
  color: rgb(114.4, 27.56, 35.88);
  background-color: rgb(241.4341772152, 176.7058227848, 182.9073417722);
}
.list-group-item-red.list-group-item-action.active {
  color: #fff;
  background-color: rgb(114.4, 27.56, 35.88);
  border-color: rgb(114.4, 27.56, 35.88);
}

.list-group-item-orange {
  color: rgb(131.56, 65.52, 10.4);
  background-color: rgb(254.44, 218.88, 189.2);
}
.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
  color: rgb(131.56, 65.52, 10.4);
  background-color: rgb(254.2248101266, 205.0002531646, 163.9151898734);
}
.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: rgb(131.56, 65.52, 10.4);
  border-color: rgb(131.56, 65.52, 10.4);
}

.list-group-item-yellow {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 237.64, 185.56);
}
.list-group-item-yellow.list-group-item-action:hover, .list-group-item-yellow.list-group-item-action:focus {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 231.265, 160.06);
}
.list-group-item-yellow.list-group-item-action.active {
  color: #fff;
  background-color: rgb(132.6, 100.36, 3.64);
  border-color: rgb(132.6, 100.36, 3.64);
}

.list-group-item-green {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(194.8, 230.36, 202.92);
}
.list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
.list-group-item-green.list-group-item-action.active {
  color: #fff;
  background-color: rgb(20.8, 86.84, 35.88);
  border-color: rgb(20.8, 86.84, 35.88);
}

.list-group-item-teal {
  color: rgb(16.64, 104.52, 78.52);
  background-color: rgb(192.56, 239.88, 225.88);
}
.list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
  color: rgb(16.64, 104.52, 78.52);
  background-color: rgb(172.0311191336, 234.9088808664, 216.3059927798);
}
.list-group-item-teal.list-group-item-action.active {
  color: #fff;
  background-color: rgb(16.64, 104.52, 78.52);
  border-color: rgb(16.64, 104.52, 78.52);
}

.list-group-item-cyan {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(190.04, 228.96, 235.12);
}
.list-group-item-cyan.list-group-item-action:hover, .list-group-item-cyan.list-group-item-action:focus {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.list-group-item-cyan.list-group-item-action.active {
  color: #fff;
  background-color: rgb(11.96, 84.24, 95.68);
  border-color: rgb(11.96, 84.24, 95.68);
}

.list-group-item-white {
  color: rgb(132.6, 132.6, 132.6);
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: rgb(132.6, 132.6, 132.6);
  background-color: rgb(242.25, 242.25, 242.25);
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: rgb(132.6, 132.6, 132.6);
  border-color: rgb(132.6, 132.6, 132.6);
}

.list-group-item-gray {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(213.84, 216.36, 218.6);
}
.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}
.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: rgb(56.16, 60.84, 65);
  border-color: rgb(56.16, 60.84, 65);
}

.list-group-item-gray-dark {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(198.16, 199.84, 201.52);
}
.list-group-item-gray-dark.list-group-item-action:hover, .list-group-item-gray-dark.list-group-item-action:focus {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
.list-group-item-gray-dark.list-group-item-action.active {
  color: #fff;
  background-color: rgb(27.04, 30.16, 33.28);
  border-color: rgb(27.04, 30.16, 33.28);
}

.list-group-item-100 {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(253.04, 253.32, 253.6);
}
.list-group-item-100.list-group-item-action:hover, .list-group-item-100.list-group-item-action:focus {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(238.165, 240.57, 242.975);
}
.list-group-item-100.list-group-item-action.active {
  color: #fff;
  background-color: rgb(128.96, 129.48, 130);
  border-color: rgb(128.96, 129.48, 130);
}

.list-group-item-200 {
  color: rgb(121.16, 122.72, 124.28);
  background-color: rgb(248.84, 249.68, 250.52);
}
.list-group-item-200.list-group-item-action:hover, .list-group-item-200.list-group-item-action:focus {
  color: rgb(121.16, 122.72, 124.28);
  background-color: rgb(234.0768421053, 236.93, 239.7831578947);
}
.list-group-item-200.list-group-item-action.active {
  color: #fff;
  background-color: rgb(121.16, 122.72, 124.28);
  border-color: rgb(121.16, 122.72, 124.28);
}

.list-group-item-300 {
  color: rgb(115.44, 117.52, 119.6);
  background-color: rgb(245.76, 246.88, 248);
}
.list-group-item-300.list-group-item-action:hover, .list-group-item-300.list-group-item-action:focus {
  color: rgb(115.44, 117.52, 119.6);
  background-color: rgb(231.2513793103, 234.13, 237.0086206897);
}
.list-group-item-300.list-group-item-action.active {
  color: #fff;
  background-color: rgb(115.44, 117.52, 119.6);
  border-color: rgb(115.44, 117.52, 119.6);
}

.list-group-item-400 {
  color: rgb(107.12, 110.24, 113.36);
  background-color: rgb(241.28, 242.96, 244.64);
}
.list-group-item-400.list-group-item-action:hover, .list-group-item-400.list-group-item-action:focus {
  color: rgb(107.12, 110.24, 113.36);
  background-color: rgb(226.7509302326, 230.21, 233.6690697674);
}
.list-group-item-400.list-group-item-action.active {
  color: #fff;
  background-color: rgb(107.12, 110.24, 113.36);
  border-color: rgb(107.12, 110.24, 113.36);
}

.list-group-item-500 {
  color: rgb(89.96, 94.12, 98.28);
  background-color: rgb(232.04, 234.28, 236.52);
}
.list-group-item-500.list-group-item-action:hover, .list-group-item-500.list-group-item-action:focus {
  color: rgb(89.96, 94.12, 98.28);
  background-color: rgb(217.9116216216, 221.53, 225.1483783784);
}
.list-group-item-500.list-group-item-action.active {
  color: #fff;
  background-color: rgb(89.96, 94.12, 98.28);
  border-color: rgb(89.96, 94.12, 98.28);
}

.list-group-item-600 {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(213.84, 216.36, 218.6);
}
.list-group-item-600.list-group-item-action:hover, .list-group-item-600.list-group-item-action:focus {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}
.list-group-item-600.list-group-item-action.active {
  color: #fff;
  background-color: rgb(56.16, 60.84, 65);
  border-color: rgb(56.16, 60.84, 65);
}

.list-group-item-700 {
  color: rgb(37.96, 41.6, 45.24);
  background-color: rgb(204.04, 206, 207.96);
}
.list-group-item-700.list-group-item-action:hover, .list-group-item-700.list-group-item-action:focus {
  color: rgb(37.96, 41.6, 45.24);
  background-color: rgb(190.78, 193.25, 195.72);
}
.list-group-item-700.list-group-item-action.active {
  color: #fff;
  background-color: rgb(37.96, 41.6, 45.24);
  border-color: rgb(37.96, 41.6, 45.24);
}

.list-group-item-800 {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(198.16, 199.84, 201.52);
}
.list-group-item-800.list-group-item-action:hover, .list-group-item-800.list-group-item-action:focus {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
.list-group-item-800.list-group-item-action.active {
  color: #fff;
  background-color: rgb(27.04, 30.16, 33.28);
  border-color: rgb(27.04, 30.16, 33.28);
}

.list-group-item-900 {
  color: rgb(17.16, 19.24, 21.32);
  background-color: rgb(192.84, 193.96, 195.08);
}
.list-group-item-900.list-group-item-action:hover, .list-group-item-900.list-group-item-action:focus {
  color: rgb(17.16, 19.24, 21.32);
  background-color: rgb(179.8560550459, 181.21, 182.5639449541);
}
.list-group-item-900.list-group-item-action.active {
  color: #fff;
  background-color: rgb(17.16, 19.24, 21.32);
  border-color: rgb(17.16, 19.24, 21.32);
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0rem - 1px);
  border-top-right-radius: calc(0rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0rem - 1px);
  border-bottom-left-radius: calc(0rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Arial", "Neue Helvetica", "Helvetica", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Arial", "Neue Helvetica", "Helvetica", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid rgb(247.35, 247.35, 247.35);
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: rgb(247.35, 247.35, 247.35);
  border-bottom: 1px solid rgb(234.6, 234.6, 234.6);
  border-top-left-radius: calc(0rem - 1px);
  border-top-right-radius: calc(0rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #260211;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #1d71b8 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: rgb(22.0563380282, 85.9436619718, 139.9436619718) !important;
}

.bg-secondary {
  background-color: rgb(174.661971831, 211.338028169, 242.338028169) !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: rgb(130.6056338028, 187.3943661972, 235.3943661972) !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: rgb(30.1449275362, 125.8550724638, 52) !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667) !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: rgb(211, 158.25, 0) !important;
}

.bg-danger {
  background-color: #EB5757 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: rgb(229.5744680851, 41.4255319149, 41.4255319149) !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: rgb(218.25, 223.5, 228.75) !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: rgb(29.1379310345, 32.5, 35.8620689655) !important;
}

.bg-hbmlp-color-steps {
  background-color: #999 !important;
}

a.bg-hbmlp-color-steps:hover, a.bg-hbmlp-color-steps:focus,
button.bg-hbmlp-color-steps:hover,
button.bg-hbmlp-color-steps:focus {
  background-color: rgb(127.5, 127.5, 127.5) !important;
}

.bg-secondary-light {
  background-color: rgb(219.7683098592, 235.1936256247, 248.2316901408) !important;
}

a.bg-secondary-light:hover, a.bg-secondary-light:focus,
button.bg-secondary-light:hover,
button.bg-secondary-light:focus {
  background-color: rgb(176.986971831, 211.143028169, 240.013028169) !important;
}

.bg-blue {
  background-color: #007bff !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: rgb(0, 98.4, 204) !important;
}

.bg-indigo {
  background-color: #6610f2 !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: rgb(81.3214285714, 10.6785714286, 196.3214285714) !important;
}

.bg-purple {
  background-color: #6f42c1 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: rgb(88.6693227092, 51.3784860558, 156.6215139442) !important;
}

.bg-pink {
  background-color: #e83e8c !important;
}

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: rgb(217.125, 25.875, 113.625) !important;
}

.bg-red {
  background-color: #dc3545 !important;
}

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: rgb(189.2151898734, 32.7848101266, 47.7721518987) !important;
}

.bg-orange {
  background-color: #fd7e14 !important;
}

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: rgb(220.1265822785, 101.164556962, 1.8734177215) !important;
}

.bg-yellow {
  background-color: #ffc107 !important;
}

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: rgb(211, 158.25, 0) !important;
}

.bg-green {
  background-color: #28a745 !important;
}

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: rgb(30.1449275362, 125.8550724638, 52) !important;
}

.bg-teal {
  background-color: #20c997 !important;
}

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: rgb(24.9957081545, 157.0042918455, 117.9484978541) !important;
}

.bg-cyan {
  background-color: #17a2b8 !important;
}

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667) !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: rgb(229.5, 229.5, 229.5) !important;
}

.bg-gray {
  background-color: #6c757d !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: rgb(84.3605150215, 91.3905579399, 97.6394849785) !important;
}

.bg-gray-dark {
  background-color: #343a40 !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: rgb(29.1379310345, 32.5, 35.8620689655) !important;
}

.bg-100 {
  background-color: #f8f9fa !important;
}

a.bg-100:hover, a.bg-100:focus,
button.bg-100:hover,
button.bg-100:focus {
  background-color: rgb(218.25, 223.5, 228.75) !important;
}

.bg-200 {
  background-color: #e9ecef !important;
}

a.bg-200:hover, a.bg-200:focus,
button.bg-200:hover,
button.bg-200:focus {
  background-color: rgb(203.4736842105, 210.5, 217.5263157895) !important;
}

.bg-300 {
  background-color: #dee2e6 !important;
}

a.bg-300:hover, a.bg-300:focus,
button.bg-300:hover,
button.bg-300:focus {
  background-color: rgb(192.9827586207, 200.5, 208.0172413793) !important;
}

.bg-400 {
  background-color: #ced4da !important;
}

a.bg-400:hover, a.bg-400:focus,
button.bg-400:hover,
button.bg-400:focus {
  background-color: rgb(176.9418604651, 186.5, 196.0581395349) !important;
}

.bg-500 {
  background-color: #adb5bd !important;
}

a.bg-500:hover, a.bg-500:focus,
button.bg-500:hover,
button.bg-500:focus {
  background-color: rgb(144.7432432432, 155.5, 166.2567567568) !important;
}

.bg-600 {
  background-color: #6c757d !important;
}

a.bg-600:hover, a.bg-600:focus,
button.bg-600:hover,
button.bg-600:focus {
  background-color: rgb(84.3605150215, 91.3905579399, 97.6394849785) !important;
}

.bg-700 {
  background-color: #495057 !important;
}

a.bg-700:hover, a.bg-700:focus,
button.bg-700:hover,
button.bg-700:focus {
  background-color: rgb(49.73125, 54.5, 59.26875) !important;
}

.bg-800 {
  background-color: #343a40 !important;
}

a.bg-800:hover, a.bg-800:focus,
button.bg-800:hover,
button.bg-800:focus {
  background-color: rgb(29.1379310345, 32.5, 35.8620689655) !important;
}

.bg-900 {
  background-color: #212529 !important;
}

a.bg-900:hover, a.bg-900:focus,
button.bg-900:hover,
button.bg-900:focus {
  background-color: rgb(10.2567567568, 11.5, 12.7432432432) !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1d71b8 !important;
}

.border-secondary {
  border-color: rgb(174.661971831, 211.338028169, 242.338028169) !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #EB5757 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-hbmlp-color-steps {
  border-color: #999 !important;
}

.border-secondary-light {
  border-color: rgb(219.7683098592, 235.1936256247, 248.2316901408) !important;
}

.border-blue {
  border-color: #007bff !important;
}

.border-indigo {
  border-color: #6610f2 !important;
}

.border-purple {
  border-color: #6f42c1 !important;
}

.border-pink {
  border-color: #e83e8c !important;
}

.border-red {
  border-color: #dc3545 !important;
}

.border-orange {
  border-color: #fd7e14 !important;
}

.border-yellow {
  border-color: #ffc107 !important;
}

.border-green {
  border-color: #28a745 !important;
}

.border-teal {
  border-color: #20c997 !important;
}

.border-cyan {
  border-color: #17a2b8 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-gray {
  border-color: #6c757d !important;
}

.border-gray-dark {
  border-color: #343a40 !important;
}

.border-100 {
  border-color: #f8f9fa !important;
}

.border-200 {
  border-color: #e9ecef !important;
}

.border-300 {
  border-color: #dee2e6 !important;
}

.border-400 {
  border-color: #ced4da !important;
}

.border-500 {
  border-color: #adb5bd !important;
}

.border-600 {
  border-color: #6c757d !important;
}

.border-700 {
  border-color: #495057 !important;
}

.border-800 {
  border-color: #343a40 !important;
}

.border-900 {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0rem !important;
}

.rounded {
  border-radius: 0rem !important;
}

.rounded-top {
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.rounded-right {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.rounded-left {
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.rounded-lg {
  border-radius: 0rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 5rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n6 {
    margin: -5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n6 {
    margin: -5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 5rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n6 {
    margin: -5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 5rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n6 {
    margin: -5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #1d71b8 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: rgb(18.5845070423, 72.4154929577, 117.9154929577) !important;
}

.text-secondary {
  color: rgb(174.661971831, 211.338028169, 242.338028169) !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: rgb(108.5774647887, 175.4225352113, 231.9225352113) !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: rgb(25.2173913043, 105.2826086957, 43.5) !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: rgb(14.5, 102.1304347826, 116) !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: rgb(185.5, 139.125, 0) !important;
}

.text-danger {
  color: #EB5757 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: rgb(219.3829787234, 26.1170212766, 26.1170212766) !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: rgb(203.375, 210.75, 218.125) !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: rgb(17.7068965517, 19.75, 21.7931034483) !important;
}

.text-hbmlp-color-steps {
  color: #999 !important;
}

a.text-hbmlp-color-steps:hover, a.text-hbmlp-color-steps:focus {
  color: rgb(114.75, 114.75, 114.75) !important;
}

.text-secondary-light {
  color: rgb(219.7683098592, 235.1936256247, 248.2316901408) !important;
}

a.text-secondary-light:hover, a.text-secondary-light:focus {
  color: rgb(155.5963028169, 199.1177294412, 235.9036971831) !important;
}

.text-blue {
  color: #007bff !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: rgb(0, 86.1, 178.5) !important;
}

.text-indigo {
  color: #6610f2 !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: rgb(71.3035714286, 9.3630952381, 172.1369047619) !important;
}

.text-purple {
  color: #6f42c1 !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: rgb(77.7988047809, 45.0796812749, 137.4203187251) !important;
}

.text-pink {
  color: #e83e8c !important;
}

a.text-pink:hover, a.text-pink:focus {
  color: rgb(194.3402777778, 23.1597222222, 101.7013888889) !important;
}

.text-red {
  color: #dc3545 !important;
}

a.text-red:hover, a.text-red:focus {
  color: rgb(167.4810126582, 29.0189873418, 42.2848101266) !important;
}

.text-orange {
  color: #fd7e14 !important;
}

a.text-orange:hover, a.text-orange:focus {
  color: rgb(194.8417721519, 89.5443037975, 1.6582278481) !important;
}

.text-yellow {
  color: #ffc107 !important;
}

a.text-yellow:hover, a.text-yellow:focus {
  color: rgb(185.5, 139.125, 0) !important;
}

.text-green {
  color: #28a745 !important;
}

a.text-green:hover, a.text-green:focus {
  color: rgb(25.2173913043, 105.2826086957, 43.5) !important;
}

.text-teal {
  color: #20c997 !important;
}

a.text-teal:hover, a.text-teal:focus {
  color: rgb(21.4935622318, 135.0064377682, 101.4227467811) !important;
}

.text-cyan {
  color: #17a2b8 !important;
}

a.text-cyan:hover, a.text-cyan:focus {
  color: rgb(14.5, 102.1304347826, 116) !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: rgb(216.75, 216.75, 216.75) !important;
}

.text-gray {
  color: #6c757d !important;
}

a.text-gray:hover, a.text-gray:focus {
  color: rgb(72.5407725322, 78.5858369099, 83.9592274678) !important;
}

.text-gray-dark {
  color: #343a40 !important;
}

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: rgb(17.7068965517, 19.75, 21.7931034483) !important;
}

.text-100 {
  color: #f8f9fa !important;
}

a.text-100:hover, a.text-100:focus {
  color: rgb(203.375, 210.75, 218.125) !important;
}

.text-200 {
  color: #e9ecef !important;
}

a.text-200:hover, a.text-200:focus {
  color: rgb(188.7105263158, 197.75, 206.7894736842) !important;
}

.text-300 {
  color: #dee2e6 !important;
}

a.text-300:hover, a.text-300:focus {
  color: rgb(178.474137931, 187.75, 197.025862069) !important;
}

.text-400 {
  color: #ced4da !important;
}

a.text-400:hover, a.text-400:focus {
  color: rgb(162.4127906977, 173.75, 185.0872093023) !important;
}

.text-500 {
  color: #adb5bd !important;
}

a.text-500:hover, a.text-500:focus {
  color: rgb(130.6148648649, 142.75, 154.8851351351) !important;
}

.text-600 {
  color: #6c757d !important;
}

a.text-600:hover, a.text-600:focus {
  color: rgb(72.5407725322, 78.5858369099, 83.9592274678) !important;
}

.text-700 {
  color: #495057 !important;
}

a.text-700:hover, a.text-700:focus {
  color: rgb(38.096875, 41.75, 45.403125) !important;
}

.text-800 {
  color: #343a40 !important;
}

a.text-800:hover, a.text-800:focus {
  color: rgb(17.7068965517, 19.75, 21.7931034483) !important;
}

.text-900 {
  color: #212529 !important;
}

a.text-900:hover, a.text-900:focus {
  color: black !important;
}

.text-body {
  color: #260211 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.select2-container {
  display: block;
}
.select2-container *:focus {
  outline: 0;
}

.input-group .select2-container--bootstrap4 {
  flex-grow: 1;
}

.input-group-prepend ~ .select2-container--bootstrap4 .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .select2-container--bootstrap4:not(:last-child) .select2-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--bootstrap4 .select2-selection {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .select2-container--bootstrap4 .select2-selection {
    transition: none;
  }
}
.select2-container--bootstrap4.select2-container--focus .select2-selection {
  border-color: rgb(108.5774647887, 175.4225352113, 231.9225352113);
  box-shadow: 0 0 0 0.2rem rgba(29, 113, 184, 0.25);
}
.select2-container--bootstrap4.select2-container--focus.select2-container--open .select2-selection {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--bootstrap4.select2-container--open.select2-container--above .select2-selection {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--bootstrap4.select2-container--open.select2-container--below .select2-selection {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--bootstrap4.select2-container--disabled .select2-selection, .select2-container--bootstrap4.select2-container--disabled.select2-container--focus .select2-selection {
  cursor: not-allowed;
  background-color: #e9ecef;
  border-color: #ced4da;
  box-shadow: none;
}
.select2-container--bootstrap4.select2-container--disabled .select2-search__field, .select2-container--bootstrap4.select2-container--disabled.select2-container--focus .select2-search__field {
  background-color: transparent;
}
select.is-invalid ~ .select2-container--bootstrap4 .select2-selection, form.was-validated select:invalid ~ .select2-container--bootstrap4 .select2-selection {
  border-color: #dc3545;
}
select.is-valid ~ .select2-container--bootstrap4 .select2-selection, form.was-validated select:valid ~ .select2-container--bootstrap4 .select2-selection {
  border-color: #28a745;
}
.select2-container--bootstrap4 .select2-search {
  width: 100%;
}
.select2-container--bootstrap4 .select2-dropdown {
  border-color: #ced4da;
  border-radius: 0;
}
.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--below {
  border-top: none;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--above {
  border-top: 1px solid #ced4da;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
.select2-container--bootstrap4 .select2-dropdown .select2-results__option[aria-selected=true] {
  color: #212529;
  background-color: rgb(242.25, 242.25, 242.25);
}
.select2-container--bootstrap4 .select2-results__option--highlighted,
.select2-container--bootstrap4 .select2-results__option--highlighted.select2-results__option[aria-selected=true] {
  color: #fff;
  background-color: #007bff;
}
.select2-container--bootstrap4 .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--bootstrap4 .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
  padding-left: 1em;
}
.select2-container--bootstrap4 .select2-results__option {
  padding: 0.375rem 0.75rem;
}
.select2-container--bootstrap4 .select2-results > .select2-results__options {
  max-height: 15em;
  overflow-y: auto;
}
.select2-container--bootstrap4 .select2-results__group {
  display: list-item;
  padding: 6px;
  color: #6c757d;
}
.select2-container--bootstrap4 .select2-selection__clear {
  float: right;
  width: 0.9em;
  height: 0.9em;
  padding-left: 0.15em;
  margin-top: 0.7em;
  margin-right: 0.3em;
  line-height: 0.75em;
  color: #f8f9fa;
  background-color: #c8c8c8;
  border-radius: 100%;
}
.select2-container--bootstrap4 .select2-selection__clear:hover {
  background-color: rgb(174.5, 174.5, 174.5);
}

.select2-container--bootstrap4 .select2-selection--single {
  height: calc(1.5em + 0.75rem + 2px) !important;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__placeholder {
  line-height: calc(1.5em + 0.75rem);
  color: #6c757d;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 50%;
  right: 3px;
  width: 20px;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  top: 60%;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: -2px;
  margin-left: -4px;
  border-color: #343a40 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
  padding-left: 0.75rem;
  line-height: calc(1.5em + 0.75rem);
  color: #495057;
}

.select2-search--dropdown .select2-search__field {
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0rem;
}

.select2-results__message {
  color: #6c757d;
}

.select2-container--bootstrap4 .select2-selection--multiple {
  min-height: calc(1.5em + 0.75rem + 2px) !important;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.375rem;
  margin: 0;
  list-style: none;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
  float: left;
  padding: 0;
  padding-right: 0.75rem;
  margin-top: calc(0.375rem - 2px);
  margin-right: 0.375rem;
  color: #495057;
  cursor: pointer;
  border: 1px solid rgb(188.5, 198, 207.5);
  border-radius: 0rem;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-search__field {
  color: #495057;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice + .select2-search {
  width: 0;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove {
  float: left;
  padding-right: 3px;
  padding-left: 3px;
  margin-right: 1px;
  margin-left: 3px;
  font-weight: 700;
  color: rgb(188.5, 198, 207.5);
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #343a40;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__clear {
  position: absolute !important;
  top: 0;
  right: 0.7em;
  float: none;
  margin-right: 0;
}
.select2-container--bootstrap4.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  padding: 0 5px;
  cursor: not-allowed;
}
.select2-container--bootstrap4.select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  display: none;
}

/******************************************************************************/
/* VARIABLES                                                                  */
/******************************************************************************/
/******************************************************************************/
/******************************************************************************/
/* MIXINS                                                                     */
/******************************************************************************/
/******************************************************************************/
/* AJAX                                                                     */
/******************************************************************************/
.hbm-ajax-transition {
  transition: opacity 0.5s ease-in-out;
}

.hbm-ajax-busy {
  opacity: 0.3;
}

.hbm-ajax-busy-disabled {
  opacity: 0.3;
  pointer-events: none;
}

/******************************************************************************/
/* ALERTS                                                                     */
/******************************************************************************/
.hbm-alerts {
  list-style: none;
  padding: 0;
  font-size: 2rem;
}
.hbm-alerts li.alert {
  margin: 5px 0;
  padding: 5px 15px;
}
.hbm-alerts li.alert .normal {
  font-size: 1rem;
}
.hbm-alerts:last-of-type {
  margin-bottom: 20px;
}

.hbm-form-controls .hbm-alerts {
  font-size: 1rem;
}

/******************************************************************************/
/* BUTTONS                                                                    */
/******************************************************************************/
.btn {
  cursor: pointer;
}

.btn-flex {
  display: flex;
  align-items: center;
  max-width: 100%;
}
ul.nav li.nav-item .btn-flex {
  justify-content: center;
  width: 100%;
}

.btn-text-truncate .btn-text {
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

/******************************************************************************/
/* CARDS                                                                      */
/******************************************************************************/
.card.card-narrow > .card-header,
.card.card-narrow > .card-body,
.card.card-narrow > .card-footer {
  padding: 0.375rem 0.625rem;
}
.card.card-narrow > .list-group > .list-group-item {
  padding: 0.375rem 0.625rem;
}

.card[data-card-collapsible=closed] > .card-body.card-body-collapsible,
.card-collapsible[data-card-collapsible=closed] > .card-body.card-body-collapsible {
  display: none;
}

/******************************************************************************/
/* COLORS                                                                     */
/******************************************************************************/
.hbm-color-fill-primary {
  fill: #1d71b8;
}

.hbm-color-bg-primary {
  background-color: #1d71b8;
}

.hbm-color-fill-secondary {
  fill: rgb(174.661971831, 211.338028169, 242.338028169);
}

.hbm-color-bg-secondary {
  background-color: rgb(174.661971831, 211.338028169, 242.338028169);
}

.hbm-color-fill-logo {
  fill: rgb(9, 142.3161290323, 255);
}

.hbm-color-bg-logo {
  background-color: rgb(9, 142.3161290323, 255);
}

/******************************************************************************/
.hbm-color.hbmlp-color-steps {
  color: #999;
}

.hbm-color-fill.hbmlp-color-steps {
  fill: #999;
}

.hbm-color-bg.hbmlp-color-steps {
  background-color: #999;
}

/******************************************************************************/
/* COMMON                                                                     */
/******************************************************************************/
.highlight {
  color: #1d71b8;
}

.text-normal {
  font-size: 1rem;
}

.mini-90 {
  font-size: 90%;
}

.mini-80 {
  font-size: 80%;
}

.mini-70 {
  font-size: 70%;
}

.mini-60 {
  font-size: 60%;
}

.mini-50 {
  font-size: 50%;
}

.mini-40 {
  font-size: 40%;
}

.mini-30 {
  font-size: 30%;
}

.mini-20 {
  font-size: 20%;
}

mark {
  background-color: yellow;
  display: inline-block;
}

code.pre {
  white-space: pre;
}

.reasonable-paragraph-margins p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.reasonable-paragraph-margins p:first-child {
  margin-top: 0;
}
.reasonable-paragraph-margins p:last-child {
  margin-bottom: 0;
}

.valign-center {
  vertical-align: middle !important;
}

/******************************************************************************/
/* DATAGRID                                                                   */
/******************************************************************************/
.hbm-table-wrapper,
.datagrid-table-wrapper {
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/******************************************************************************/
/* FORM                                                                       */
/******************************************************************************/
.btn-group.input-group-prepend > .btn {
  border-radius: 0;
}

.btn-group.input-group-prepend:last-child > .btn:last-child {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.btn.btn-input-group-addon {
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}
.btn.btn-input-group-addon.focus, .btn.btn-input-group-addon:focus {
  box-shadow: none;
}
.btn.btn-input-group-addon.active {
  color: #1d71b8;
}

.btn-group.input-group-prepend.large-icons .btn.btn-input-group-addon > .fa,
.btn-group.input-group-prepend.large-icons .btn.btn-input-group-addon > .svg-inline--fa {
  font-size: 1.2rem;
}

.input-group-primary {
  color: rgb(15.08, 58.76, 95.68);
  background-color: rgb(191.72, 215.24, 235.12);
}
.input-group-primary .input-group-text {
  background-color: rgb(133.5078787879, 178.6642424242, 216.8321212121);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-primary label {
  background-color: transparent;
}

.input-group-secondary {
  color: rgb(90.8242253521, 109.8957746479, 126.0157746479);
  background-color: rgb(232.5053521127, 242.7746478873, 251.4546478873);
}
.input-group-secondary .input-group-text {
  background-color: rgb(166.4208450704, 206.8591549296, 241.0391549296);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-secondary label {
  background-color: transparent;
}

.input-group-success {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(194.8, 230.36, 202.92);
}
.input-group-success .input-group-text {
  background-color: rgb(140.5178217822, 208.1421782178, 155.9596039604);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-success label {
  background-color: transparent;
}

.input-group-info {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(190.04, 228.96, 235.12);
}
.input-group-info .input-group-text {
  background-color: rgb(131.4657425743, 205.4798019802, 217.1942574257);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-info label {
  background-color: transparent;
}

.input-group-warning {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 237.64, 185.56);
}
.input-group-warning .input-group-text {
  background-color: rgb(255, 218.515, 109.06);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-warning label {
  background-color: transparent;
}

.input-group-danger {
  color: rgb(122.2, 45.24, 45.24);
  background-color: rgb(249.4, 207.96, 207.96);
}
.input-group-danger .input-group-text {
  background-color: rgb(241.2617021277, 139.5982978723, 139.5982978723);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-danger label {
  background-color: transparent;
}

.input-group-light {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(253.04, 253.32, 253.6);
}
.input-group-light .input-group-text {
  background-color: rgb(208.415, 215.07, 221.725);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-light label {
  background-color: transparent;
}

.input-group-dark {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(198.16, 199.84, 201.52);
}
.input-group-dark .input-group-text {
  background-color: rgb(158.7450253807, 161.59, 164.4349746193);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-dark label {
  background-color: transparent;
}

.input-group-hbmlp-color-steps {
  color: rgb(79.56, 79.56, 79.56);
  background-color: rgb(226.44, 226.44, 226.44);
}
.input-group-hbmlp-color-steps .input-group-text {
  background-color: rgb(188.19, 188.19, 188.19);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-hbmlp-color-steps label {
  background-color: transparent;
}

.input-group-secondary-light {
  color: rgb(114.2795211268, 122.3006853249, 129.0804788732);
  background-color: rgb(245.1351267606, 249.4542151749, 253.1048732394);
}
.input-group-secondary-light .input-group-text {
  background-color: rgb(180.9631197183, 213.3783189914, 240.7768802817);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-secondary-light label {
  background-color: transparent;
}

.input-group-blue {
  color: rgb(0, 63.96, 132.6);
  background-color: rgb(183.6, 218.04, 255);
}
.input-group-blue .input-group-text {
  background-color: rgb(107.1, 178.44, 255);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-blue label {
  background-color: transparent;
}

.input-group-indigo {
  color: rgb(53.04, 8.32, 125.84);
  background-color: rgb(212.16, 188.08, 251.36);
}
.input-group-indigo .input-group-text {
  background-color: rgb(165.7135714286, 115.5264285714, 247.4135714286);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-indigo label {
  background-color: transparent;
}

.input-group-purple {
  color: rgb(57.72, 34.32, 100.36);
  background-color: rgb(214.68, 202.08, 237.64);
}
.input-group-purple .input-group-text {
  background-color: rgb(170.7915537849, 144.4764143426, 218.7435856574);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-purple label {
  background-color: transparent;
}

.input-group-pink {
  color: rgb(120.64, 32.24, 72.8);
  background-color: rgb(248.56, 200.96, 222.8);
}
.input-group-pink .input-group-text {
  background-color: rgb(240.4141666667, 132.6058333333, 182.0708333333);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-pink label {
  background-color: transparent;
}

.input-group-red {
  color: rgb(114.4, 27.56, 35.88);
  background-color: rgb(245.2, 198.44, 202.92);
}
.input-group-red .input-group-text {
  background-color: rgb(233.9025316456, 133.2374683544, 142.8820253165);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-red label {
  background-color: transparent;
}

.input-group-orange {
  color: rgb(131.56, 65.52, 10.4);
  background-color: rgb(254.44, 218.88, 189.2);
}
.input-group-orange .input-group-text {
  background-color: rgb(253.7944303797, 177.2407594937, 113.3455696203);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-orange label {
  background-color: transparent;
}

.input-group-yellow {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 237.64, 185.56);
}
.input-group-yellow .input-group-text {
  background-color: rgb(255, 218.515, 109.06);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-yellow label {
  background-color: transparent;
}

.input-group-green {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(194.8, 230.36, 202.92);
}
.input-group-green .input-group-text {
  background-color: rgb(140.5178217822, 208.1421782178, 155.9596039604);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-green label {
  background-color: transparent;
}

.input-group-teal {
  color: rgb(16.64, 104.52, 78.52);
  background-color: rgb(192.56, 239.88, 225.88);
}
.input-group-teal .input-group-text {
  background-color: rgb(130.9733574007, 224.9666425993, 197.1579783394);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-teal label {
  background-color: transparent;
}

.input-group-cyan {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(190.04, 228.96, 235.12);
}
.input-group-cyan .input-group-text {
  background-color: rgb(131.4657425743, 205.4798019802, 217.1942574257);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-cyan label {
  background-color: transparent;
}

.input-group-white {
  color: rgb(132.6, 132.6, 132.6);
  background-color: white;
}
.input-group-white .input-group-text {
  background-color: rgb(216.75, 216.75, 216.75);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-white label {
  background-color: transparent;
}

.input-group-gray {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(213.84, 216.36, 218.6);
}
.input-group-gray .input-group-text {
  background-color: rgb(173.2425270758, 178.2480866426, 182.6974729242);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-gray label {
  background-color: transparent;
}

.input-group-gray-dark {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(198.16, 199.84, 201.52);
}
.input-group-gray-dark .input-group-text {
  background-color: rgb(158.7450253807, 161.59, 164.4349746193);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-gray-dark label {
  background-color: transparent;
}

.input-group-100 {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(253.04, 253.32, 253.6);
}
.input-group-100 .input-group-text {
  background-color: rgb(208.415, 215.07, 221.725);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-100 label {
  background-color: transparent;
}

.input-group-200 {
  color: rgb(121.16, 122.72, 124.28);
  background-color: rgb(248.84, 249.68, 250.52);
}
.input-group-200 .input-group-text {
  background-color: rgb(204.5505263158, 211.43, 218.3094736842);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-200 label {
  background-color: transparent;
}

.input-group-300 {
  color: rgb(115.44, 117.52, 119.6);
  background-color: rgb(245.76, 246.88, 248);
}
.input-group-300 .input-group-text {
  background-color: rgb(202.234137931, 208.63, 215.025862069);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-300 label {
  background-color: transparent;
}

.input-group-400 {
  color: rgb(107.12, 110.24, 113.36);
  background-color: rgb(241.28, 242.96, 244.64);
}
.input-group-400 .input-group-text {
  background-color: rgb(197.6927906977, 204.71, 211.7272093023);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-400 label {
  background-color: transparent;
}

.input-group-500 {
  color: rgb(89.96, 94.12, 98.28);
  background-color: rgb(232.04, 234.28, 236.52);
}
.input-group-500 .input-group-text {
  background-color: rgb(189.6548648649, 196.03, 202.4051351351);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-500 label {
  background-color: transparent;
}

.input-group-600 {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(213.84, 216.36, 218.6);
}
.input-group-600 .input-group-text {
  background-color: rgb(173.2425270758, 178.2480866426, 182.6974729242);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-600 label {
  background-color: transparent;
}

.input-group-700 {
  color: rgb(37.96, 41.6, 45.24);
  background-color: rgb(204.04, 206, 207.96);
}
.input-group-700 .input-group-text {
  background-color: rgb(164.26, 167.75, 171.24);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-700 label {
  background-color: transparent;
}

.input-group-800 {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(198.16, 199.84, 201.52);
}
.input-group-800 .input-group-text {
  background-color: rgb(158.7450253807, 161.59, 164.4349746193);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-800 label {
  background-color: transparent;
}

.input-group-900 {
  color: rgb(17.16, 19.24, 21.32);
  background-color: rgb(192.84, 193.96, 195.08);
}
.input-group-900 .input-group-text {
  background-color: rgb(153.8881651376, 155.71, 157.5318348624);
}

.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-900 label {
  background-color: transparent;
}

/******************************************************************************/
.hbm-form-buttons {
  text-align: center;
  margin-top: 1rem;
}
.hbm-form-buttons .btn-block {
  overflow: hidden;
  text-overflow: ellipsis;
}
.hbm-form-buttons > button + button {
  margin-left: 0.75rem;
}
.hbm-form-buttons > button.btn-block + button.btn-block {
  margin-left: 0;
  margin-top: 0.75rem;
}

.hbm-form-highlight-label label {
  font-weight: bold;
  color: #1d71b8;
}

.hbm-disabled {
  opacity: 0.65;
  cursor: not-allowed;
  pointer-events: none;
}

.hbm-form-highlight-control textarea, .hbm-form-highlight-control input, .hbm-form-highlight-control select {
  background-color: rgb(218.7183098592, 235.2816901408, 249.2816901408);
}

.hbm-form-card.card > .card-header.hbm-form-header {
  font-size: 1.5rem;
}
.hbm-form-card.card > .card-header.hbm-form-header label {
  margin: 0;
}
.hbm-form-card.card > .card-body.hbm-form-body.hbm-form-header-text {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.hbm-form-card.card > .card-body.hbm-form-body .card-subtitle {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 1rem;
}
.hbm-form-card.card > .card-body.hbm-form-body .card-subtitle p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.hbm-form-card.card > .card-body.hbm-form-body .card-subtitle p:first-child {
  margin-top: 0;
}
.hbm-form-card.card > .card-body.hbm-form-body .card-subtitle p:last-child {
  margin-bottom: 0;
}
.hbm-form-card.card > .card-body.hbm-form-body .card-separator {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.hbm-form-card.card > .card-body.hbm-form-body .card-separator-header {
  padding: 0.75rem 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.hbm-form-card.card > .card-body.hbm-form-body .card-divider {
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2rem;
}

.hbm-form-group {
  margin-bottom: 0;
}
.hbm-form-group + .hbm-form-group {
  margin-top: 1rem;
}
.hbm-form-group label.required:not(.form-control):not(.form-control-handle):after {
  content: "*";
}
.hbm-form-group input[type=radio].form-control,
.hbm-form-group input[type=checkbox].form-control {
  width: auto;
}
.hbm-form-group .hbm-form-controls .form-control {
  height: auto;
}
.hbm-form-group .hbm-form-controls .hbm-form-label-inline {
  margin: 0;
  padding-left: 0.5rem;
}
.hbm-form-group .hbm-form-controls .hbm-form-help > ul {
  padding-left: 2rem;
  margin-bottom: 0;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups {
  padding: 0;
  margin: 0;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups.header-center .input-group .input-group-header .input-group-text {
  text-align: center;
  justify-content: center;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group {
  list-style: none;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group .input-group-header {
  width: 100%;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group .input-group-header .input-group-text {
  font-weight: bold;
  white-space: normal;
  text-align: left;
  justify-content: flex-start;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group .input-group-prepend .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group .input-group-append .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group .input-group-header .input-group-text {
  border-top: 0 solid #CCCCCC;
  border-radius: 0;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child .input-group-prepend .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child .input-group-append .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child .input-group-header .input-group-text {
  border-top: 1px solid #CCCCCC;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child .input-group-prepend:first-child:not(:last-child) .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child .input-group-append:first-child:not(:last-child) .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child .input-group-header:first-child:not(:last-child) .input-group-text {
  border-top-right-radius: 0;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child .input-group-prepend:last-child:not(:first-child) .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child .input-group-append:last-child:not(:first-child) .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child .input-group-header:last-child:not(:first-child) .input-group-text {
  border-top-left-radius: 0;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child .input-group-prepend .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child .input-group-append .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child .input-group-header .input-group-text {
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child .input-group-prepend:first-child:not(:last-child) .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child .input-group-append:first-child:not(:last-child) .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child .input-group-header:first-child:not(:last-child) .input-group-text {
  border-bottom-right-radius: 0;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child .input-group-prepend:last-child:not(:first-child) .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child .input-group-append:last-child:not(:first-child) .input-group-text, .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child .input-group-header:last-child:not(:first-child) .input-group-text {
  border-bottom-left-radius: 0;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group label {
  display: block;
  padding: 0.375rem 0.75rem;
  margin: 0;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 0 solid #CCCCCC;
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  border-radius: 0;
  flex-grow: 1;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group label.form-control-handle {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child label {
  border-top: 1px solid #CCCCCC;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child label:first-child:not(:last-child) {
  border-top-right-radius: 0;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:first-child label:last-child:not(:first-child) {
  border-top-left-radius: 0;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child label {
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child label:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
}
.hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group:last-child label:last-child:not(:first-child) {
  border-bottom-left-radius: 0;
}
.hbm-form-group .hbm-form-controls > .hbm-input-checkbox {
  width: auto;
  display: table;
}
.hbm-form-group .hbm-form-controls > textarea {
  height: auto;
}

.form-inline .input-group {
  align-items: center;
  display: flex;
}

/******************************************************************************/
/* JQUERY UI TIMEPICKER ADDON                                                 */
/******************************************************************************/
.ui-timepicker-div .ui-widget-header {
  margin-bottom: 8px;
}

.ui-timepicker-div dl {
  text-align: left;
}

.ui-timepicker-div dl dt {
  height: 25px;
  margin-bottom: -25px;
}

.ui-timepicker-div dl dd {
  margin: 0 10px 10px 65px;
}

.ui-timepicker-div td {
  font-size: 90%;
}

.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.ui-timepicker-rtl {
  direction: rtl;
}

.ui-timepicker-rtl dl {
  text-align: right;
}

.ui-timepicker-rtl dl dd {
  margin: 0 65px 10px 10px;
}

/******************************************************************************/
/* LISTS                                                                      */
/******************************************************************************/
.list-narrow {
  padding-left: 2em;
}

.list-group-transparent .list-group-item {
  background-color: transparent;
}

.list-group-narrow .list-group-item {
  padding: 0.3rem 0.6rem;
}

.list-group-narrow-extra .list-group-item {
  padding: 0.1rem 0.3rem;
}

.padded-like-list-group {
  padding: 0.75rem 1.25rem;
}

.padded-like-list-group-narrow {
  padding: 0.3rem 0.6rem;
}

.padded-like-list-group-narrow-extra {
  padding: 0.1rem 0.3rem;
}

.hbm-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.hbm-logo .hbm-logo-icon {
  width: 4em;
  max-width: 100%;
  display: flex;
  flex-shrink: 0;
}
.hbm-logo .hbm-logo-icon .responsive-svg {
  flex-grow: 1;
}
.hbm-logo .hbm-logo-icon .responsive-svg:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 5%;
  top: 100%;
  left: 0;
  background-image: url(/build/vendor/@burdadirect/hbm-frontend/img/logo-shadow.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}
.hbm-logo .hbm-logo-text {
  font-family: "Arial", "Neue Helvetica", "Helvetica", sans-serif;
  font-weight: bold;
  font-size: 1em;
  color: #260211;
  text-align: left;
  margin-left: 0.65em;
  line-height: 1;
}

/******************************************************************************/
/* Notifications                                                              */
/******************************************************************************/
#notifications {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
}
#notifications .notification {
  margin: 10px 10px 10px 10px;
}
#notifications .notification .notification-content {
  opacity: 0.95;
  text-align: center;
  font-size: 13px;
  background-color: #efefef;
  border: 1px solid #aaaaaa;
  padding: 10px;
}
#notifications .notification .notification-content.notification-level-primary {
  color: rgb(15.08, 58.76, 95.68);
  background-color: rgb(209.8, 226.6, 240.8);
  border-color: rgb(191.72, 215.24, 235.12);
}
#notifications .notification .notification-content.notification-level-primary hr {
  border-top-color: rgb(172.315959596, 203.0480808081, 229.024040404);
}
#notifications .notification .notification-content.notification-level-primary .alert-link {
  color: rgb(8.1363380282, 31.7036619718, 51.6236619718);
}
#notifications .notification .notification-content.notification-level-secondary {
  color: rgb(90.8242253521, 109.8957746479, 126.0157746479);
  background-color: rgb(238.9323943662, 246.2676056338, 252.4676056338);
  border-color: rgb(232.5053521127, 242.7746478873, 251.4546478873);
}
#notifications .notification .notification-content.notification-level-secondary hr {
  border-top-color: rgb(210.4771830986, 230.8028169014, 247.9828169014);
}
#notifications .notification .notification-content.notification-level-secondary .alert-link {
  color: rgb(69.4626892289, 84.0486776776, 96.3773107711);
}
#notifications .notification .notification-content.notification-level-success {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(212, 237.4, 217.8);
  border-color: rgb(194.8, 230.36, 202.92);
}
#notifications .notification .notification-content.notification-level-success hr {
  border-top-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
#notifications .notification .notification-content.notification-level-success .alert-link {
  color: rgb(10.9449275362, 45.6950724638, 18.88);
}
#notifications .notification .notification-content.notification-level-info {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(208.6, 236.4, 240.8);
  border-color: rgb(190.04, 228.96, 235.12);
}
#notifications .notification .notification-content.notification-level-info hr {
  border-top-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
#notifications .notification .notification-content.notification-level-info .alert-link {
  color: rgb(6.2933333333, 44.3269565217, 50.3466666667);
}
#notifications .notification .notification-content.notification-level-warning {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 242.6, 205.4);
  border-color: rgb(255, 237.64, 185.56);
}
#notifications .notification .notification-content.notification-level-warning hr {
  border-top-color: rgb(255, 231.265, 160.06);
}
#notifications .notification .notification-content.notification-level-warning .alert-link {
  color: rgb(82.9625954198, 62.7912977099, 2.2774045802);
}
#notifications .notification .notification-content.notification-level-danger {
  color: rgb(122.2, 45.24, 45.24);
  background-color: rgb(251, 221.4, 221.4);
  border-color: rgb(249.4, 207.96, 207.96);
}
#notifications .notification .notification-content.notification-level-danger hr {
  border-top-color: rgb(246.6872340426, 185.1727659574, 185.1727659574);
}
#notifications .notification .notification-content.notification-level-danger .alert-link {
  color: rgb(84.9795031056, 31.4604968944, 31.4604968944);
}
#notifications .notification .notification-content.notification-level-light {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(253.6, 253.8, 254);
  border-color: rgb(253.04, 253.32, 253.6);
}
#notifications .notification .notification-content.notification-level-light hr {
  border-top-color: rgb(238.165, 240.57, 242.975);
}
#notifications .notification .notification-content.notification-level-light .alert-link {
  color: rgb(103.5492351816, 103.98, 104.4107648184);
}
#notifications .notification .notification-content.notification-level-dark {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(214.4, 215.6, 216.8);
  border-color: rgb(198.16, 199.84, 201.52);
}
#notifications .notification .notification-content.notification-level-dark hr {
  border-top-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
#notifications .notification .notification-content.notification-level-dark .alert-link {
  color: rgb(4.1779310345, 4.66, 5.1420689655);
}
#notifications .notification .notification-content.notification-level-hbmlp-color-steps {
  color: rgb(79.56, 79.56, 79.56);
  background-color: rgb(234.6, 234.6, 234.6);
  border-color: rgb(226.44, 226.44, 226.44);
}
#notifications .notification .notification-content.notification-level-hbmlp-color-steps hr {
  border-top-color: rgb(213.69, 213.69, 213.69);
}
#notifications .notification .notification-content.notification-level-hbmlp-color-steps .alert-link {
  color: rgb(54.06, 54.06, 54.06);
}
#notifications .notification .notification-content.notification-level-secondary-light {
  color: rgb(114.2795211268, 122.3006853249, 129.0804788732);
  background-color: rgb(247.9536619718, 251.0387251249, 253.6463380282);
  border-color: rgb(245.1351267606, 249.4542151749, 253.1048732394);
}
#notifications .notification .notification-content.notification-level-secondary-light hr {
  border-top-color: rgb(223.7444577465, 237.4289164471, 248.9955422535);
}
#notifications .notification .notification-content.notification-level-secondary-light .alert-link {
  color: rgb(90.330410437, 96.6706107375, 102.029589563);
}
#notifications .notification .notification-content.notification-level-blue {
  color: rgb(0, 63.96, 132.6);
  background-color: rgb(204, 228.6, 255);
  border-color: rgb(183.6, 218.04, 255);
}
#notifications .notification .notification-content.notification-level-blue hr {
  border-top-color: rgb(158.1, 204.84, 255);
}
#notifications .notification .notification-content.notification-level-blue .alert-link {
  color: rgb(0, 39.36, 81.6);
}
#notifications .notification .notification-content.notification-level-indigo {
  color: rgb(53.04, 8.32, 125.84);
  background-color: rgb(224.4, 207.2, 252.4);
  border-color: rgb(212.16, 188.08, 251.36);
}
#notifications .notification .notification-content.notification-level-indigo hr {
  border-top-color: rgb(196.6778571429, 163.8954761905, 250.0445238095);
}
#notifications .notification .notification-content.notification-level-indigo .alert-link {
  color: rgb(32.8772093023, 5.1572093023, 78.0027906977);
}
#notifications .notification .notification-content.notification-level-purple {
  color: rgb(57.72, 34.32, 100.36);
  background-color: rgb(226.2, 217.2, 242.6);
  border-color: rgb(214.68, 202.08, 237.64);
}
#notifications .notification .notification-content.notification-level-purple hr {
  border-top-color: rgb(200.0505179283, 182.8788047809, 231.3411952191);
}
#notifications .notification .notification-content.notification-level-purple .alert-link {
  color: rgb(35.8628571429, 21.3238610039, 62.3561389961);
}
#notifications .notification .notification-content.notification-level-pink {
  color: rgb(120.64, 32.24, 72.8);
  background-color: rgb(250.4, 216.4, 232);
  border-color: rgb(248.56, 200.96, 222.8);
}
#notifications .notification .notification-content.notification-level-pink hr {
  border-top-color: rgb(245.8447222222, 178.1752777778, 209.2236111111);
}
#notifications .notification .notification-content.notification-level-pink .alert-link {
  color: rgb(80.3951020408, 21.4848979592, 48.5142857143);
}
#notifications .notification .notification-content.notification-level-red {
  color: rgb(114.4, 27.56, 35.88);
  background-color: rgb(248, 214.6, 217.8);
  border-color: rgb(245.2, 198.44, 202.92);
}
#notifications .notification .notification-content.notification-level-red hr {
  border-top-color: rgb(241.4341772152, 176.7058227848, 182.9073417722);
}
#notifications .notification .notification-content.notification-level-red .alert-link {
  color: rgb(73.3010989011, 17.6589010989, 22.9898901099);
}
#notifications .notification .notification-content.notification-level-orange {
  color: rgb(131.56, 65.52, 10.4);
  background-color: rgb(254.6, 229.2, 208);
  border-color: rgb(254.44, 218.88, 189.2);
}
#notifications .notification .notification-content.notification-level-orange hr {
  border-top-color: rgb(254.2248101266, 205.0002531646, 163.9151898734);
}
#notifications .notification .notification-content.notification-level-orange .alert-link {
  color: rgb(84.2962637363, 41.9815384615, 6.6637362637);
}
#notifications .notification .notification-content.notification-level-yellow {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 242.6, 205.4);
  border-color: rgb(255, 237.64, 185.56);
}
#notifications .notification .notification-content.notification-level-yellow hr {
  border-top-color: rgb(255, 231.265, 160.06);
}
#notifications .notification .notification-content.notification-level-yellow .alert-link {
  color: rgb(82.9625954198, 62.7912977099, 2.2774045802);
}
#notifications .notification .notification-content.notification-level-green {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(212, 237.4, 217.8);
  border-color: rgb(194.8, 230.36, 202.92);
}
#notifications .notification .notification-content.notification-level-green hr {
  border-top-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
#notifications .notification .notification-content.notification-level-green .alert-link {
  color: rgb(10.9449275362, 45.6950724638, 18.88);
}
#notifications .notification .notification-content.notification-level-teal {
  color: rgb(16.64, 104.52, 78.52);
  background-color: rgb(210.4, 244.2, 234.2);
  border-color: rgb(192.56, 239.88, 225.88);
}
#notifications .notification .notification-content.notification-level-teal hr {
  border-top-color: rgb(172.0311191336, 234.9088808664, 216.3059927798);
}
#notifications .notification .notification-content.notification-level-teal .alert-link {
  color: rgb(9.6357081545, 60.5242918455, 45.4684978541);
}
#notifications .notification .notification-content.notification-level-cyan {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(208.6, 236.4, 240.8);
  border-color: rgb(190.04, 228.96, 235.12);
}
#notifications .notification .notification-content.notification-level-cyan hr {
  border-top-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
#notifications .notification .notification-content.notification-level-cyan .alert-link {
  color: rgb(6.2933333333, 44.3269565217, 50.3466666667);
}
#notifications .notification .notification-content.notification-level-white {
  color: rgb(132.6, 132.6, 132.6);
  background-color: white;
  border-color: white;
}
#notifications .notification .notification-content.notification-level-white hr {
  border-top-color: rgb(242.25, 242.25, 242.25);
}
#notifications .notification .notification-content.notification-level-white .alert-link {
  color: rgb(107.1, 107.1, 107.1);
}
#notifications .notification .notification-content.notification-level-gray {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(225.6, 227.4, 229);
  border-color: rgb(213.84, 216.36, 218.6);
}
#notifications .notification .notification-content.notification-level-gray hr {
  border-top-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}
#notifications .notification .notification-content.notification-level-gray .alert-link {
  color: rgb(32.5205150215, 35.2305579399, 37.6394849785);
}
#notifications .notification .notification-content.notification-level-gray-dark {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(214.4, 215.6, 216.8);
  border-color: rgb(198.16, 199.84, 201.52);
}
#notifications .notification .notification-content.notification-level-gray-dark hr {
  border-top-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
#notifications .notification .notification-content.notification-level-gray-dark .alert-link {
  color: rgb(4.1779310345, 4.66, 5.1420689655);
}
#notifications .notification .notification-content.notification-level-100 {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(253.6, 253.8, 254);
  border-color: rgb(253.04, 253.32, 253.6);
}
#notifications .notification .notification-content.notification-level-100 hr {
  border-top-color: rgb(238.165, 240.57, 242.975);
}
#notifications .notification .notification-content.notification-level-100 .alert-link {
  color: rgb(103.5492351816, 103.98, 104.4107648184);
}
#notifications .notification .notification-content.notification-level-200 {
  color: rgb(121.16, 122.72, 124.28);
  background-color: rgb(250.6, 251.2, 251.8);
  border-color: rgb(248.84, 249.68, 250.52);
}
#notifications .notification .notification-content.notification-level-200 hr {
  border-top-color: rgb(234.0768421053, 236.93, 239.7831578947);
}
#notifications .notification .notification-content.notification-level-200 .alert-link {
  color: rgb(95.9841525424, 97.22, 98.4558474576);
}
#notifications .notification .notification-content.notification-level-300 {
  color: rgb(115.44, 117.52, 119.6);
  background-color: rgb(248.4, 249.2, 250);
  border-color: rgb(245.76, 246.88, 248);
}
#notifications .notification .notification-content.notification-level-300 hr {
  border-top-color: rgb(231.2513793103, 234.13, 237.0086206897);
}
#notifications .notification .notification-content.notification-level-300 .alert-link {
  color: rgb(90.3913274336, 92.02, 93.6486725664);
}
#notifications .notification .notification-content.notification-level-400 {
  color: rgb(107.12, 110.24, 113.36);
  background-color: rgb(245.2, 246.4, 247.6);
  border-color: rgb(241.28, 242.96, 244.64);
}
#notifications .notification .notification-content.notification-level-400 hr {
  border-top-color: rgb(226.7509302326, 230.21, 233.6690697674);
}
#notifications .notification .notification-content.notification-level-400 .alert-link {
  color: rgb(82.3416981132, 84.74, 87.1383018868);
}
#notifications .notification .notification-content.notification-level-500 {
  color: rgb(89.96, 94.12, 98.28);
  background-color: rgb(238.6, 240.2, 241.8);
  border-color: rgb(232.04, 234.28, 236.52);
}
#notifications .notification .notification-content.notification-level-500 hr {
  border-top-color: rgb(217.9116216216, 221.53, 225.1483783784);
}
#notifications .notification .notification-content.notification-level-500 .alert-link {
  color: rgb(65.5870718232, 68.62, 71.6529281768);
}
#notifications .notification .notification-content.notification-level-600 {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(225.6, 227.4, 229);
  border-color: rgb(213.84, 216.36, 218.6);
}
#notifications .notification .notification-content.notification-level-600 hr {
  border-top-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}
#notifications .notification .notification-content.notification-level-600 .alert-link {
  color: rgb(32.5205150215, 35.2305579399, 37.6394849785);
}
#notifications .notification .notification-content.notification-level-700 {
  color: rgb(37.96, 41.6, 45.24);
  background-color: rgb(218.6, 220, 221.4);
  border-color: rgb(204.04, 206, 207.96);
}
#notifications .notification .notification-content.notification-level-700 hr {
  border-top-color: rgb(190.78, 193.25, 195.72);
}
#notifications .notification .notification-content.notification-level-700 .alert-link {
  color: rgb(14.69125, 16.1, 17.50875);
}
#notifications .notification .notification-content.notification-level-800 {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(214.4, 215.6, 216.8);
  border-color: rgb(198.16, 199.84, 201.52);
}
#notifications .notification .notification-content.notification-level-800 hr {
  border-top-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
#notifications .notification .notification-content.notification-level-800 .alert-link {
  color: rgb(4.1779310345, 4.66, 5.1420689655);
}
#notifications .notification .notification-content.notification-level-900 {
  color: rgb(17.16, 19.24, 21.32);
  background-color: rgb(210.6, 211.4, 212.2);
  border-color: rgb(192.84, 193.96, 195.08);
}
#notifications .notification .notification-content.notification-level-900 hr {
  border-top-color: rgb(179.8560550459, 181.21, 182.5639449541);
}
#notifications .notification .notification-content.notification-level-900 .alert-link {
  color: black;
}
#notifications .notification .notification-content .notification-text {
  padding: 0;
  margin: 0;
}
#notifications .notification .notification-content .notification-hint {
  padding: 0;
  margin: 15px 0 0 0;
  font-size: 9px;
}
#notifications .notification .notification-content ul {
  padding-left: 15px !important;
}

/******************************************************************************/
/* STEPS                                                                      */
/******************************************************************************/
ul.steps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
ul.steps li.step {
  flex: 1;
  text-align: center;
  position: relative;
}
ul.steps li.step:before {
  content: "";
  position: absolute;
  top: 1.5em;
  left: 0;
  right: 0;
  border-top: 2px solid #999;
  box-sizing: border-box;
}
ul.steps li.step .step-icon {
  display: inline-flex;
  position: relative;
  border: 2px solid #999;
  background-color: #fff;
  border-radius: 50%;
  padding: 0.5em;
}
ul.steps li.step .step-icon-only {
  display: inline-flex;
}
ul.steps li.step .step-text {
  display: block;
  padding: 1em;
}
ul.steps li.step .step-color {
  color: #999;
}

/******************************************************************************/
/* SECTIONS                                                                   */
/******************************************************************************/
ul.sections {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
ul.sections li.step {
  flex: 1;
  text-align: center;
  position: relative;
}
ul.sections li.step:not(:last-child) {
  border-right: 1px solid #999;
}
ul.sections li.step .step-icon {
  display: inline-flex;
  position: relative;
  background-color: #fff;
}
ul.sections li.step .step-icon-only {
  display: inline-flex;
}
ul.sections li.step .step-text {
  display: block;
}
ul.sections li.step .step-color {
  color: #999;
}

/******************************************************************************/
/* TABLES                                                                     */
/******************************************************************************/
td.fixed-10-center, th.fixed-10-center {
  text-align: center;
  width: 10px;
}
td.fixed-10, th.fixed-10 {
  width: 10px;
}
td.fixed-25-center, th.fixed-25-center {
  text-align: center;
  width: 25px;
}
td.fixed-25, th.fixed-25 {
  width: 25px;
}
td.fixed-50-center, th.fixed-50-center {
  text-align: center;
  width: 50px;
}
td.fixed-50, th.fixed-50 {
  width: 50px;
}
td.fixed-75-center, th.fixed-75-center {
  text-align: center;
  width: 75px;
}
td.fixed-75, th.fixed-75 {
  width: 75px;
}
td.fixed-100-center, th.fixed-100-center {
  text-align: center;
  width: 100px;
}
td.fixed-100, th.fixed-100 {
  width: 100px;
}
td.fixed-125-center, th.fixed-125-center {
  text-align: center;
  width: 125px;
}
td.fixed-125, th.fixed-125 {
  width: 125px;
}
td.fixed-150-center, th.fixed-150-center {
  text-align: center;
  width: 150px;
}
td.fixed-150, th.fixed-150 {
  width: 150px;
}
td.fixed-200-center, th.fixed-200-center {
  text-align: center;
  width: 200px;
}
td.fixed-200, th.fixed-200 {
  width: 200px;
}
td.fixed-250-center, th.fixed-250-center {
  text-align: center;
  width: 250px;
}
td.fixed-250, th.fixed-250 {
  width: 250px;
}

table.table-xs th,
table.table-xs td {
  padding: 0.1rem;
}

td.no-wrap, th.no-wrap {
  white-space: nowrap;
}

table.break-words {
  word-wrap: break-word;
}

table.table-nonfluid {
  width: auto !important;
}

td.list-group-cell-narrow, th.list-group-cell-narrow {
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
}

.table-bordered-colored.table-primary th {
  background-color: rgb(155.56, 192.52, 223.76);
  border-color: rgb(83.24, 147.08, 201.04);
}
.table-bordered-colored.table-primary td {
  border-color: rgb(83.24, 147.08, 201.04);
}
.table-bordered-colored.table-primary .list-group-flush .list-group-item {
  border-color: rgb(83.24, 147.08, 201.04);
}

.table-bordered-colored.table-secondary th {
  background-color: rgb(219.6512676056, 235.7887323944, 249.4287323944);
  border-color: rgb(193.9430985915, 221.8169014085, 245.3769014085);
}
.table-bordered-colored.table-secondary td {
  border-color: rgb(193.9430985915, 221.8169014085, 245.3769014085);
}
.table-bordered-colored.table-secondary .list-group-flush .list-group-item {
  border-color: rgb(193.9430985915, 221.8169014085, 245.3769014085);
}

.table-bordered-colored.table-success th {
  background-color: rgb(160.4, 216.28, 173.16);
  border-color: rgb(91.6, 188.12, 113.64);
}
.table-bordered-colored.table-success td {
  border-color: rgb(91.6, 188.12, 113.64);
}
.table-bordered-colored.table-success .list-group-flush .list-group-item {
  border-color: rgb(91.6, 188.12, 113.64);
}

.table-bordered-colored.table-info th {
  background-color: rgb(152.92, 214.08, 223.76);
  border-color: rgb(78.68, 184.32, 201.04);
}
.table-bordered-colored.table-info td {
  border-color: rgb(78.68, 184.32, 201.04);
}
.table-bordered-colored.table-info .list-group-flush .list-group-item {
  border-color: rgb(78.68, 184.32, 201.04);
}

.table-bordered-colored.table-warning th {
  background-color: rgb(255, 227.72, 145.88);
  border-color: rgb(255, 207.88, 66.52);
}
.table-bordered-colored.table-warning td {
  border-color: rgb(255, 207.88, 66.52);
}
.table-bordered-colored.table-warning .list-group-flush .list-group-item {
  border-color: rgb(255, 207.88, 66.52);
}

.table-bordered-colored.table-danger th {
  background-color: rgb(246.2, 181.08, 181.08);
  border-color: rgb(239.8, 127.32, 127.32);
}
.table-bordered-colored.table-danger td {
  border-color: rgb(239.8, 127.32, 127.32);
}
.table-bordered-colored.table-danger .list-group-flush .list-group-item {
  border-color: rgb(239.8, 127.32, 127.32);
}

.table-bordered-colored.table-light th {
  background-color: rgb(251.92, 252.36, 252.8);
  border-color: rgb(249.68, 250.44, 251.2);
}
.table-bordered-colored.table-light td {
  border-color: rgb(249.68, 250.44, 251.2);
}
.table-bordered-colored.table-light .list-group-flush .list-group-item {
  border-color: rgb(249.68, 250.44, 251.2);
}

.table-bordered-colored.table-dark th {
  background-color: rgb(165.68, 168.32, 170.96);
  border-color: rgb(100.72, 105.28, 109.84);
}
.table-bordered-colored.table-dark td {
  border-color: rgb(100.72, 105.28, 109.84);
}
.table-bordered-colored.table-dark .list-group-flush .list-group-item {
  border-color: rgb(100.72, 105.28, 109.84);
}

.table-bordered-colored.table-hbmlp-color-steps th {
  background-color: rgb(210.12, 210.12, 210.12);
  border-color: rgb(177.48, 177.48, 177.48);
}
.table-bordered-colored.table-hbmlp-color-steps td {
  border-color: rgb(177.48, 177.48, 177.48);
}
.table-bordered-colored.table-hbmlp-color-steps .list-group-flush .list-group-item {
  border-color: rgb(177.48, 177.48, 177.48);
}

.table-bordered-colored.table-secondary-light th {
  background-color: rgb(239.498056338, 246.2851952749, 252.021943662);
  border-color: rgb(228.223915493, 239.9471554748, 249.856084507);
}
.table-bordered-colored.table-secondary-light td {
  border-color: rgb(228.223915493, 239.9471554748, 249.856084507);
}
.table-bordered-colored.table-secondary-light .list-group-flush .list-group-item {
  border-color: rgb(228.223915493, 239.9471554748, 249.856084507);
}

.table-bordered-colored.table-blue th {
  background-color: rgb(142.8, 196.92, 255);
  border-color: rgb(61.2, 154.68, 255);
}
.table-bordered-colored.table-blue td {
  border-color: rgb(61.2, 154.68, 255);
}
.table-bordered-colored.table-blue .list-group-flush .list-group-item {
  border-color: rgb(61.2, 154.68, 255);
}

.table-bordered-colored.table-indigo th {
  background-color: rgb(187.68, 149.84, 249.28);
  border-color: rgb(138.72, 73.36, 245.12);
}
.table-bordered-colored.table-indigo td {
  border-color: rgb(138.72, 73.36, 245.12);
}
.table-bordered-colored.table-indigo .list-group-flush .list-group-item {
  border-color: rgb(138.72, 73.36, 245.12);
}

.table-bordered-colored.table-purple th {
  background-color: rgb(191.64, 171.84, 227.72);
  border-color: rgb(145.56, 111.36, 207.88);
}
.table-bordered-colored.table-purple td {
  border-color: rgb(145.56, 111.36, 207.88);
}
.table-bordered-colored.table-purple .list-group-flush .list-group-item {
  border-color: rgb(145.56, 111.36, 207.88);
}

.table-bordered-colored.table-pink th {
  background-color: rgb(244.88, 170.08, 204.4);
  border-color: rgb(237.52, 108.32, 167.6);
}
.table-bordered-colored.table-pink td {
  border-color: rgb(237.52, 108.32, 167.6);
}
.table-bordered-colored.table-pink .list-group-flush .list-group-item {
  border-color: rgb(237.52, 108.32, 167.6);
}

.table-bordered-colored.table-red th {
  background-color: rgb(239.6, 166.12, 173.16);
  border-color: rgb(228.4, 101.48, 113.64);
}
.table-bordered-colored.table-red td {
  border-color: rgb(228.4, 101.48, 113.64);
}
.table-bordered-colored.table-red .list-group-flush .list-group-item {
  border-color: rgb(228.4, 101.48, 113.64);
}

.table-bordered-colored.table-orange th {
  background-color: rgb(254.12, 198.24, 151.6);
  border-color: rgb(253.48, 156.96, 76.4);
}
.table-bordered-colored.table-orange td {
  border-color: rgb(253.48, 156.96, 76.4);
}
.table-bordered-colored.table-orange .list-group-flush .list-group-item {
  border-color: rgb(253.48, 156.96, 76.4);
}

.table-bordered-colored.table-yellow th {
  background-color: rgb(255, 227.72, 145.88);
  border-color: rgb(255, 207.88, 66.52);
}
.table-bordered-colored.table-yellow td {
  border-color: rgb(255, 207.88, 66.52);
}
.table-bordered-colored.table-yellow .list-group-flush .list-group-item {
  border-color: rgb(255, 207.88, 66.52);
}

.table-bordered-colored.table-green th {
  background-color: rgb(160.4, 216.28, 173.16);
  border-color: rgb(91.6, 188.12, 113.64);
}
.table-bordered-colored.table-green td {
  border-color: rgb(91.6, 188.12, 113.64);
}
.table-bordered-colored.table-green .list-group-flush .list-group-item {
  border-color: rgb(91.6, 188.12, 113.64);
}

.table-bordered-colored.table-teal th {
  background-color: rgb(156.88, 231.24, 209.24);
  border-color: rgb(85.52, 213.96, 175.96);
}
.table-bordered-colored.table-teal td {
  border-color: rgb(85.52, 213.96, 175.96);
}
.table-bordered-colored.table-teal .list-group-flush .list-group-item {
  border-color: rgb(85.52, 213.96, 175.96);
}

.table-bordered-colored.table-cyan th {
  background-color: rgb(152.92, 214.08, 223.76);
  border-color: rgb(78.68, 184.32, 201.04);
}
.table-bordered-colored.table-cyan td {
  border-color: rgb(78.68, 184.32, 201.04);
}
.table-bordered-colored.table-cyan .list-group-flush .list-group-item {
  border-color: rgb(78.68, 184.32, 201.04);
}

.table-bordered-colored.table-white th {
  background-color: white;
  border-color: white;
}
.table-bordered-colored.table-white td {
  border-color: white;
}
.table-bordered-colored.table-white .list-group-flush .list-group-item {
  border-color: white;
}

.table-bordered-colored.table-gray th {
  background-color: rgb(190.32, 194.28, 197.8);
  border-color: rgb(143.28, 150.12, 156.2);
}
.table-bordered-colored.table-gray td {
  border-color: rgb(143.28, 150.12, 156.2);
}
.table-bordered-colored.table-gray .list-group-flush .list-group-item {
  border-color: rgb(143.28, 150.12, 156.2);
}

.table-bordered-colored.table-gray-dark th {
  background-color: rgb(165.68, 168.32, 170.96);
  border-color: rgb(100.72, 105.28, 109.84);
}
.table-bordered-colored.table-gray-dark td {
  border-color: rgb(100.72, 105.28, 109.84);
}
.table-bordered-colored.table-gray-dark .list-group-flush .list-group-item {
  border-color: rgb(100.72, 105.28, 109.84);
}

.table-bordered-colored.table-100 th {
  background-color: rgb(251.92, 252.36, 252.8);
  border-color: rgb(249.68, 250.44, 251.2);
}
.table-bordered-colored.table-100 td {
  border-color: rgb(249.68, 250.44, 251.2);
}
.table-bordered-colored.table-100 .list-group-flush .list-group-item {
  border-color: rgb(249.68, 250.44, 251.2);
}

.table-bordered-colored.table-200 th {
  background-color: rgb(245.32, 246.64, 247.96);
  border-color: rgb(238.28, 240.56, 242.84);
}
.table-bordered-colored.table-200 td {
  border-color: rgb(238.28, 240.56, 242.84);
}
.table-bordered-colored.table-200 .list-group-flush .list-group-item {
  border-color: rgb(238.28, 240.56, 242.84);
}

.table-bordered-colored.table-300 th {
  background-color: rgb(240.48, 242.24, 244);
  border-color: rgb(229.92, 232.96, 236);
}
.table-bordered-colored.table-300 td {
  border-color: rgb(229.92, 232.96, 236);
}
.table-bordered-colored.table-300 .list-group-flush .list-group-item {
  border-color: rgb(229.92, 232.96, 236);
}

.table-bordered-colored.table-400 th {
  background-color: rgb(233.44, 236.08, 238.72);
  border-color: rgb(217.76, 222.32, 226.88);
}
.table-bordered-colored.table-400 td {
  border-color: rgb(217.76, 222.32, 226.88);
}
.table-bordered-colored.table-400 .list-group-flush .list-group-item {
  border-color: rgb(217.76, 222.32, 226.88);
}

.table-bordered-colored.table-500 th {
  background-color: rgb(218.92, 222.44, 225.96);
  border-color: rgb(192.68, 198.76, 204.84);
}
.table-bordered-colored.table-500 td {
  border-color: rgb(192.68, 198.76, 204.84);
}
.table-bordered-colored.table-500 .list-group-flush .list-group-item {
  border-color: rgb(192.68, 198.76, 204.84);
}

.table-bordered-colored.table-600 th {
  background-color: rgb(190.32, 194.28, 197.8);
  border-color: rgb(143.28, 150.12, 156.2);
}
.table-bordered-colored.table-600 td {
  border-color: rgb(143.28, 150.12, 156.2);
}
.table-bordered-colored.table-600 .list-group-flush .list-group-item {
  border-color: rgb(143.28, 150.12, 156.2);
}

.table-bordered-colored.table-700 th {
  background-color: rgb(174.92, 178, 181.08);
  border-color: rgb(116.68, 122, 127.32);
}
.table-bordered-colored.table-700 td {
  border-color: rgb(116.68, 122, 127.32);
}
.table-bordered-colored.table-700 .list-group-flush .list-group-item {
  border-color: rgb(116.68, 122, 127.32);
}

.table-bordered-colored.table-800 th {
  background-color: rgb(165.68, 168.32, 170.96);
  border-color: rgb(100.72, 105.28, 109.84);
}
.table-bordered-colored.table-800 td {
  border-color: rgb(100.72, 105.28, 109.84);
}
.table-bordered-colored.table-800 .list-group-flush .list-group-item {
  border-color: rgb(100.72, 105.28, 109.84);
}

.table-bordered-colored.table-900 th {
  background-color: rgb(157.32, 159.08, 160.84);
  border-color: rgb(86.28, 89.32, 92.36);
}
.table-bordered-colored.table-900 td {
  border-color: rgb(86.28, 89.32, 92.36);
}
.table-bordered-colored.table-900 .list-group-flush .list-group-item {
  border-color: rgb(86.28, 89.32, 92.36);
}

table.table-bordered .list-group-flush .list-group-item {
  border-color: #dee2e6;
}

/******************************************************************************/
/* TAGIFY                                                                     */
/******************************************************************************/
.datagrid-menu-search .input-group {
  align-items: stretch;
}
.datagrid-menu-search .input-group > .tagify.form-control {
  height: auto;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  padding: 0;
  display: flex;
  align-items: center;
}
.datagrid-menu-search .tagify--mix .tagify__input,
.datagrid-menu-search .tagify__input {
  margin: 0.175rem 0.75rem;
  padding: 0;
  display: inline-flex;
  min-width: 12px;
  height: auto;
}
.datagrid-menu-search .tagify--mix .tagify__input:empty,
.datagrid-menu-search .tagify__input:empty {
  display: inline-flex;
}
.datagrid-menu-search .tagify--mix .tagify__input::before,
.datagrid-menu-search .tagify__input::before {
  content: "...";
}
.datagrid-menu-search .tagify__tag {
  margin: 0.175rem 0 0.175rem 0.75rem;
}
.datagrid-menu-search .tagify__tag > div {
  padding: 0.1em 0.5em;
}
.datagrid-menu-search .tagify__tag > div > .tagify__tag-text {
  max-width: 160px;
}
.datagrid-menu-search .tagify__tag > div > .tagify__tag-text[contenteditable=true] {
  max-width: none;
  white-space: normal;
}

.tagify__dropdown {
  min-width: 220px;
}

/******************************************************************************/
/* TOGGABLES                                                                  */
/******************************************************************************/
.toggable-ids {
  display: none;
}
body.visible-ids .toggable-ids {
  display: initial;
}

.toggable-icons {
  display: none;
}
body.visible-icons .toggable-icons {
  display: initial;
}

.toggable-edits {
  display: none;
}
body.visible-edits .toggable-edits {
  display: initial;
}

/******************************************************************************/
/* TREE - https://jsfiddle.net/1fynun7a/1/                                    */
/******************************************************************************/
ul.tree {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.tree ul {
  list-style: none;
  margin: 0 0 0 10px;
  padding: 0;
}
ul.tree li {
  position: relative;
  margin: 0;
  padding: 0 0 0 1.1em;
  border-left: 1px solid rgb(100, 100, 100);
}
ul.tree li:last-child {
  border-left: none;
  padding-left: calc(1.1em + 1px);
}
ul.tree li:before {
  position: absolute;
  top: -0.3em;
  left: 0;
  height: 1em;
  width: 1em;
  color: white;
  border-bottom: 1px solid rgb(100, 100, 100);
  content: "";
}
ul.tree li:last-child:before {
  width: calc(1em + 1px);
  border-left: 1px solid rgb(100, 100, 100);
}

/******************************************************************************/
/* BADGE                                                                      */
/******************************************************************************/
.badge-size-normal {
  font-size: 100%;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.badge-custom {
  padding: 0.5rem 1rem;
  font-size: 100%;
  border-radius: 10rem;
}
.badge-custom.badge-sm {
  padding: 0.3rem 0.7rem;
  font-size: 80%;
}

/******************************************************************************/
/* BUTTONS                                                                    */
/******************************************************************************/
.btn.span-outline {
  opacity: 1;
  color: inherit;
  cursor: initial;
}

.btn-group.btn-group-toggle > label > *:not(input) {
  pointer-events: none;
}

.buttons-bar {
  display: flex;
  flex-direction: column;
  margin: 0 -1rem;
}
.buttons-bar.buttons-bar-top {
  margin-bottom: 3rem;
}
.buttons-bar.buttons-bar-bottom {
  margin-top: 3rem;
}
.buttons-bar .buttons-bar-left,
.buttons-bar .buttons-bar-right {
  display: flex;
  flex-direction: column;
}
.buttons-bar .buttons-bar-left > .btn, .buttons-bar .buttons-bar-left > .btn-group,
.buttons-bar .buttons-bar-right > .btn,
.buttons-bar .buttons-bar-right > .btn-group {
  margin: 1rem;
}
.buttons-bar .buttons-bar-left {
  align-items: flex-start;
}
.buttons-bar .buttons-bar-right {
  align-items: flex-end;
}
@media (min-width: 1200px) {
  .buttons-bar {
    flex-direction: row;
    justify-content: space-between;
  }
}

/******************************************************************************/
/* CARD                                                                       */
/******************************************************************************/
.card > .card-body > .card-title a,
.card > .card-body > .card-subtitle a {
  font-size: inherit;
  color: inherit;
}
.card > .card-body > .card-title a:hover,
.card > .card-body > .card-subtitle a:hover {
  text-decoration: none;
}

.card > .card-header > h1:first-of-type, .card > .card-header h2:first-of-type, .card > .card-header h3:first-of-type, .card > .card-header h4:first-of-type, .card > .card-header h5:first-of-type, .card > .card-header h6:first-of-type,
.card > .card-body > h1:first-of-type,
.card > .card-body h2:first-of-type,
.card > .card-body h3:first-of-type,
.card > .card-body h4:first-of-type,
.card > .card-body h5:first-of-type,
.card > .card-body h6:first-of-type {
  margin-top: 0;
}
.card > .card-header > h1:last-of-type, .card > .card-header h2:last-of-type, .card > .card-header h3:last-of-type, .card > .card-header h4:last-of-type, .card > .card-header h5:last-of-type, .card > .card-header h6:last-of-type,
.card > .card-body > h1:last-of-type,
.card > .card-body h2:last-of-type,
.card > .card-body h3:last-of-type,
.card > .card-body h4:last-of-type,
.card > .card-body h5:last-of-type,
.card > .card-body h6:last-of-type {
  margin-bottom: 0;
}

.card > .card-header.border-top,
.card > .card-body.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

/******************************************************************************/
/* CLIPBOARD                                                                  */
/******************************************************************************/
.clipboardable {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 1px;
  border: 1px solid rgb(227.5295774648, 240.0704225352, 250.6704225352);
  border-left: none;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.clipboardable.hovered {
  border: 1px solid rgb(218.7183098592, 235.2816901408, 249.2816901408);
  border-left: none;
}
.clipboardable.hovered .clipboardable-content {
  background: linear-gradient(270deg, rgb(218.7183098592, 235.2816901408, 249.2816901408) 0px, rgb(218.7183098592, 235.2816901408, 249.2816901408) 75%, rgb(255, 255, 255) 100%);
}
.clipboardable.copied .clipboardable-content {
  color: #1d71b8;
  transition: color 0.1s ease-in-out;
}
.clipboardable .clipboardable-content {
  padding: 1px 1rem 1px 3px;
  background: linear-gradient(270deg, rgb(227.5295774648, 240.0704225352, 250.6704225352) 0px, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
}
.clipboardable .clipboardable-trigger {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 1px 6px;
  align-self: stretch;
  background-color: rgb(227.5295774648, 240.0704225352, 250.6704225352);
  color: #1d71b8;
  cursor: pointer;
}
.clipboardable .clipboardable-trigger:hover {
  background-color: rgb(218.7183098592, 235.2816901408, 249.2816901408);
}

@keyframes clipboardable-success-animation {
  0% {
    color: #28a745;
  }
  100% {
    color: initial;
  }
}
.clipboardable-success {
  animation: clipboardable-success-animation linear 2s 1;
}

/******************************************************************************/
/* COLORS                                                                     */
/******************************************************************************/
.hbmlp-bg-cyan {
  background-color: rgb(196.8407079646, 236.3451327434, 251.1592920354) !important;
}
.hbmlp-bg-cyan a {
  color: rgb(9.2195121951, 113.9268292683, 152.7804878049);
  background-color: transparent;
}
.hbmlp-bg-cyan a:hover {
  color: rgb(4.8658536585, 60.1280487805, 80.6341463415);
  text-decoration: underline;
}

a.hbmlp-bg-cyan:hover, a.hbmlp-bg-cyan:focus,
button.hbmlp-bg-cyan:hover,
button.hbmlp-bg-cyan:focus {
  background-color: rgb(101.1592920354, 205.6548672566, 244.8407079646) !important;
}

.card.hbmlp-bg-cyan > .card-header {
  background-color: #95ddf8 !important;
}

.hbmlp-bg-orange {
  background-color: rgb(254.4722222222, 223.8611111111, 198.5277777778) !important;
}
.hbmlp-bg-orange a {
  color: rgb(169.5569620253, 77.9240506329, 1.4430379747);
  background-color: transparent;
}
.hbmlp-bg-orange a:hover {
  color: rgb(93.7025316456, 43.0632911392, 0.7974683544);
  text-decoration: underline;
}

a.hbmlp-bg-orange:hover, a.hbmlp-bg-orange:focus,
button.hbmlp-bg-orange:hover,
button.hbmlp-bg-orange:focus {
  background-color: rgb(253.5277777778, 168.1388888889, 97.4722222222) !important;
}

.card.hbmlp-bg-orange > .card-header {
  background-color: #fec494 !important;
}

.hbmlp-bg-teal {
  background-color: rgb(193.9606299213, 240.0393700787, 226.2755905512) !important;
}
.hbmlp-bg-teal a {
  color: rgb(17.991416309, 113.008583691, 84.8969957082);
  background-color: transparent;
}
.hbmlp-bg-teal a:hover {
  color: rgb(7.4849785408, 47.0150214592, 35.3197424893);
  text-decoration: underline;
}

a.hbmlp-bg-teal:hover, a.hbmlp-bg-teal:focus,
button.hbmlp-bg-teal:hover,
button.hbmlp-bg-teal:focus {
  background-color: rgb(112.0393700787, 219.9606299213, 187.7244094488) !important;
}

.card.hbmlp-bg-teal > .card-header {
  background-color: #99e6cf !important;
}

.hbmlp-bg-yellow {
  background-color: rgb(255, 239.0884955752, 193) !important;
}
.hbmlp-bg-yellow a {
  color: #a07800;
  background-color: transparent;
}
.hbmlp-bg-yellow a:hover {
  color: rgb(83.5, 62.625, 0);
  text-decoration: underline;
}

a.hbmlp-bg-yellow:hover, a.hbmlp-bg-yellow:focus,
button.hbmlp-bg-yellow:hover,
button.hbmlp-bg-yellow:focus {
  background-color: rgb(255, 212.9115044248, 91) !important;
}

.card.hbmlp-bg-yellow > .card-header {
  background-color: #ffe28e !important;
}

.hbmlp-bg-pink {
  background-color: rgb(249.6666666667, 212.3333333333, 229.303030303) !important;
}
.hbmlp-bg-pink a {
  color: rgb(171.5555555556, 20.4444444444, 89.7777777778);
  background-color: transparent;
}
.hbmlp-bg-pink a:hover {
  color: rgb(103.2013888889, 12.2986111111, 54.0069444444);
  text-decoration: underline;
}

a.hbmlp-bg-pink:hover, a.hbmlp-bg-pink:focus,
button.hbmlp-bg-pink:hover,
button.hbmlp-bg-pink:focus {
  background-color: rgb(238.3333333333, 121.6666666667, 174.696969697) !important;
}

.card.hbmlp-bg-pink > .card-header {
  background-color: #f4a7ca !important;
}

.hbmlp-bg-brown {
  background-color: rgb(229.152173913, 199.5217391304, 193.847826087) !important;
}
.hbmlp-bg-brown a {
  color: rgb(84.0865384615, 32.1057692308, 21.9134615385);
  background-color: transparent;
}
.hbmlp-bg-brown a:hover {
  color: rgb(23.4014423077, 8.9350961538, 6.0985576923);
  text-decoration: underline;
}

a.hbmlp-bg-brown:hover, a.hbmlp-bg-brown:focus,
button.hbmlp-bg-brown:hover,
button.hbmlp-bg-brown:focus {
  background-color: rgb(198.847826087, 134.4782608696, 122.152173913) !important;
}

.card.hbmlp-bg-brown > .card-header {
  background-color: #d6a79e !important;
}

.hbmlp-bg-indigo {
  background-color: rgb(216.0434782609, 194.3391304348, 251.6608695652) !important;
}
.hbmlp-bg-indigo a {
  color: rgb(61.2857142857, 8.0476190476, 147.9523809524);
  background-color: transparent;
}
.hbmlp-bg-indigo a:hover {
  color: rgb(31.2321428571, 4.1011904762, 75.3988095238);
  text-decoration: underline;
}

a.hbmlp-bg-indigo:hover, a.hbmlp-bg-indigo:focus,
button.hbmlp-bg-indigo:hover,
button.hbmlp-bg-indigo:focus {
  background-color: rgb(153.9565217391, 97.6608695652, 246.3391304348) !important;
}

.card.hbmlp-bg-indigo > .card-header {
  background-color: #b992f9 !important;
}

.hbmlp-bg-gray {
  background-color: rgb(245.5, 246.5, 247.5) !important;
}
.hbmlp-bg-gray a {
  color: rgb(116.4864864865, 130, 143.5135135135);
  background-color: transparent;
}
.hbmlp-bg-gray a:hover {
  color: rgb(81.8310810811, 91.75, 101.6689189189);
  text-decoration: underline;
}

a.hbmlp-bg-gray:hover, a.hbmlp-bg-gray:focus,
button.hbmlp-bg-gray:hover,
button.hbmlp-bg-gray:focus {
  background-color: rgb(188.5, 195.5, 202.5) !important;
}

.card.hbmlp-bg-gray > .card-header {
  background-color: #d9dde1 !important;
}

.card.hbmlp-header-cyan > .card-header {
  background-color: #95ddf8 !important;
  border-bottom-color: #17b6f1 !important;
}
.card.hbmlp-header-cyan > .card-header a:not(.btn) {
  color: #17b6f1;
  background-color: transparent;
}
.card.hbmlp-header-cyan > .card-header a:not(.btn):hover {
  color: rgb(10.6707317073, 131.8597560976, 176.8292682927);
  text-decoration: underline;
}

.card.hbmlp-header-orange > .card-header {
  background-color: #fec494 !important;
  border-bottom-color: #fd7e14 !important;
}
.card.hbmlp-header-orange > .card-header a:not(.btn) {
  color: #fd7e14;
  background-color: transparent;
}
.card.hbmlp-header-orange > .card-header a:not(.btn):hover {
  color: rgb(194.8417721519, 89.5443037975, 1.6582278481);
  text-decoration: underline;
}

.card.hbmlp-header-teal > .card-header {
  background-color: #99e6cf !important;
  border-bottom-color: #20c997 !important;
}
.card.hbmlp-header-teal > .card-header a:not(.btn) {
  color: #20c997;
  background-color: transparent;
}
.card.hbmlp-header-teal > .card-header a:not(.btn):hover {
  color: rgb(21.4935622318, 135.0064377682, 101.4227467811);
  text-decoration: underline;
}

.card.hbmlp-header-yellow > .card-header {
  background-color: #ffe28e !important;
  border-bottom-color: #ffc107 !important;
}
.card.hbmlp-header-yellow > .card-header a:not(.btn) {
  color: #ffc107;
  background-color: transparent;
}
.card.hbmlp-header-yellow > .card-header a:not(.btn):hover {
  color: rgb(185.5, 139.125, 0);
  text-decoration: underline;
}

.card.hbmlp-header-pink > .card-header {
  background-color: #f4a7ca !important;
  border-bottom-color: #e83e8c !important;
}
.card.hbmlp-header-pink > .card-header a:not(.btn) {
  color: #e83e8c;
  background-color: transparent;
}
.card.hbmlp-header-pink > .card-header a:not(.btn):hover {
  color: rgb(194.3402777778, 23.1597222222, 101.7013888889);
  text-decoration: underline;
}

.card.hbmlp-header-brown > .card-header {
  background-color: #d6a79e !important;
  border-bottom-color: #a53f2b !important;
}
.card.hbmlp-header-brown > .card-header a:not(.btn) {
  color: #a53f2b;
  background-color: transparent;
}
.card.hbmlp-header-brown > .card-header a:not(.btn):hover {
  color: rgb(104.3149038462, 39.8293269231, 27.1850961538);
  text-decoration: underline;
}

.card.hbmlp-header-indigo > .card-header {
  background-color: #b992f9 !important;
  border-bottom-color: #6610f2 !important;
}
.card.hbmlp-header-indigo > .card-header a:not(.btn) {
  color: #6610f2;
  background-color: transparent;
}
.card.hbmlp-header-indigo > .card-header a:not(.btn):hover {
  color: rgb(71.3035714286, 9.3630952381, 172.1369047619);
  text-decoration: underline;
}

.card.hbmlp-header-gray > .card-header {
  background-color: #d9dde1 !important;
  border-bottom-color: #adb5bd !important;
}
.card.hbmlp-header-gray > .card-header a:not(.btn) {
  color: #adb5bd;
  background-color: transparent;
}
.card.hbmlp-header-gray > .card-header a:not(.btn):hover {
  color: rgb(130.6148648649, 142.75, 154.8851351351);
  text-decoration: underline;
}

.hbmlp-border-cyan {
  border-color: #17b6f1 !important;
}

.hbmlp-border-orange {
  border-color: #fd7e14 !important;
}

.hbmlp-border-teal {
  border-color: #20c997 !important;
}

.hbmlp-border-yellow {
  border-color: #ffc107 !important;
}

.hbmlp-border-pink {
  border-color: #e83e8c !important;
}

.hbmlp-border-brown {
  border-color: #a53f2b !important;
}

.hbmlp-border-indigo {
  border-color: #6610f2 !important;
}

.hbmlp-border-gray {
  border-color: #adb5bd !important;
}

.hbmlp-text-info {
  color: #856404;
}

.bg-card-header {
  background-color: rgba(0, 0, 0, 0.03);
}

/******************************************************************************/
/* HBMLP-CONTENT-ASIDE (SPLIT VIEW)                                           */
/******************************************************************************/
.title-wrapper {
  background-color: #e5e5e5;
}
.title-wrapper .title {
  background-color: #fff;
  border-radius: 30px;
  padding: 0.5rem 2rem;
  display: flex;
  margin: auto;
}
.title-wrapper .title h2 {
  font-size: 1.3rem;
  margin: 0;
}

.hbmlp-content-aside-body {
  position: absolute;
  top: 150px;
  padding: 0 16px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.upload-dropdown-section {
  border-top: 4px solid #fff;
  background-color: #e5e5e5;
  padding: 1rem;
}
.upload-dropdown-section .input-group .input-group-prepend .input-group-text {
  border-color: #1d71b8;
  color: #1d71b8;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.upload-dropdown-section .form-control {
  border-color: #1d71b8;
  color: #1d71b8;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.upload-dropdown-section .form-control::placeholder {
  color: #1d71b8;
}

.hbmlp-content-aside-body-upload {
  position: absolute;
  top: 285px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 0 16px;
}

/******************************************************************************/
/* HBMLP-CONTENT-ASIDE (SPLIT VIEW) - NOTICES                                 */
/******************************************************************************/
.notice-wrapper {
  background-color: #e5e5e5;
  padding: 1rem 2rem;
  margin: 1rem 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.notice-wrapper h3 {
  padding: 10px 16px;
  background-color: #fff;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}
.notice-wrapper p {
  padding: 10px 16px;
  background-color: #fff;
  margin: 1rem 0;
}
.notice-wrapper p.notice-text {
  border-radius: 3px;
}
.notice-wrapper p.text-muted {
  font-size: 0.8rem;
  border-radius: 30px;
}

.uploads-wrapper {
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 5px;
}
.uploads-wrapper .uploads-wrapper-card {
  border: 1px solid #e5e5e5;
  position: relative;
  background-color: white;
  padding: 1rem;
  margin: 1.5rem 0;
  border-radius: 5px;
  width: 100%;
}
.uploads-wrapper .uploads-wrapper-card .upload-newest {
  position: absolute;
  top: -10px;
  left: -10px;
  padding: 0.4rem 0.6rem;
  background-color: #ffc107;
  border-radius: 100px;
}
.uploads-wrapper .uploads-wrapper-card .preview {
  color: #1d71b8;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
.uploads-wrapper .uploads-wrapper-card .preview:hover {
  transform: scale(1.1);
}
.uploads-wrapper .uploads-wrapper-card .icon {
  width: 3rem;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}
.uploads-wrapper .uploads-wrapper-card .badge-uploads {
  padding: 0.5rem 0.8rem;
  color: #1d71b8;
  background-color: rgb(218.7183098592, 235.2816901408, 249.2816901408);
  border: none;
  border-radius: 5px;
  margin-bottom: 0;
}
.uploads-wrapper .uploads-wrapper-card .badge-uploads-danger {
  color: #dc3545;
  background-color: rgb(250.1265822785, 226.8734177215, 229.1012658228);
}
.uploads-wrapper .uploads-wrapper-card .badge-uploads-left {
  border-radius: 5px 0 0 5px;
}
.uploads-wrapper .uploads-wrapper-card .badge-uploads-right {
  border-radius: 0 5px 5px 0;
}
.uploads-wrapper .uploads-wrapper-card .cut-text {
  flex: 1;
  min-width: 0; /* or some value */
}
.uploads-wrapper .uploads-wrapper-card .cut-text p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uploads-wrapper .uploads-wrapper-card h3 {
  font-size: 1rem;
  font-weight: 700;
}
.uploads-wrapper .uploads-wrapper-card p.text-muted {
  font-size: 0.8rem;
}

.uploads-list-aside.ui-sortable.shuffle {
  overflow: visible !important;
}

@media only screen and (max-width: 1770px) {
  .hbmlp-content-aside-body {
    top: 180px;
  }
  .hbmlp-content-aside-body-upload {
    top: 325px;
  }
}
/******************************************************************************/
/* HBMLP-CONTENT_MAIN (SPLIT VIEW)                                            */
/******************************************************************************/
.hbmlp-content-main-header {
  background-color: #e5e5e5;
}
.hbmlp-content-main-header .leading {
  border-bottom: 4px solid #fff;
}
.hbmlp-content-main-header .leading .entity-header {
  background-color: #1d71b8;
  border-radius: 30px;
  padding: 0.5rem 2rem;
}
.hbmlp-content-main-header .leading .entity-header h1 {
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
}

.hbmlp-content-main-body {
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.hbmlp-content-main-body h2 {
  text-transform: uppercase;
  margin-bottom: 0;
  color: #1d71b8;
  font-weight: 600;
  letter-spacing: 0.03rem;
}

@media only screen and (max-width: 2096px) {
  .hbmlp-content-main-body {
    top: 215px;
    padding: 0 3rem;
  }
}
/******************************************************************************/
/* DASHBOARD                                                                  */
/******************************************************************************/
.border-left-primary {
  border-left: 0.4rem solid rgb(83.24, 147.08, 201.04) !important;
}

.border-left-secondary {
  border-left: 0.4rem solid rgb(193.9430985915, 221.8169014085, 245.3769014085) !important;
}

.border-left-success {
  border-left: 0.4rem solid rgb(91.6, 188.12, 113.64) !important;
}

.border-left-info {
  border-left: 0.4rem solid rgb(78.68, 184.32, 201.04) !important;
}

.border-left-warning {
  border-left: 0.4rem solid rgb(255, 207.88, 66.52) !important;
}

.border-left-danger {
  border-left: 0.4rem solid rgb(239.8, 127.32, 127.32) !important;
}

.border-left-light {
  border-left: 0.4rem solid rgb(249.68, 250.44, 251.2) !important;
}

.border-left-dark {
  border-left: 0.4rem solid rgb(100.72, 105.28, 109.84) !important;
}

.border-left-hbmlp-color-steps {
  border-left: 0.4rem solid rgb(177.48, 177.48, 177.48) !important;
}

.border-left-secondary-light {
  border-left: 0.4rem solid rgb(228.223915493, 239.9471554748, 249.856084507) !important;
}

.border-left-blue {
  border-left: 0.4rem solid rgb(61.2, 154.68, 255) !important;
}

.border-left-indigo {
  border-left: 0.4rem solid rgb(138.72, 73.36, 245.12) !important;
}

.border-left-purple {
  border-left: 0.4rem solid rgb(145.56, 111.36, 207.88) !important;
}

.border-left-pink {
  border-left: 0.4rem solid rgb(237.52, 108.32, 167.6) !important;
}

.border-left-red {
  border-left: 0.4rem solid rgb(228.4, 101.48, 113.64) !important;
}

.border-left-orange {
  border-left: 0.4rem solid rgb(253.48, 156.96, 76.4) !important;
}

.border-left-yellow {
  border-left: 0.4rem solid rgb(255, 207.88, 66.52) !important;
}

.border-left-green {
  border-left: 0.4rem solid rgb(91.6, 188.12, 113.64) !important;
}

.border-left-teal {
  border-left: 0.4rem solid rgb(85.52, 213.96, 175.96) !important;
}

.border-left-cyan {
  border-left: 0.4rem solid rgb(78.68, 184.32, 201.04) !important;
}

.border-left-white {
  border-left: 0.4rem solid white !important;
}

.border-left-gray {
  border-left: 0.4rem solid rgb(143.28, 150.12, 156.2) !important;
}

.border-left-gray-dark {
  border-left: 0.4rem solid rgb(100.72, 105.28, 109.84) !important;
}

.border-left-100 {
  border-left: 0.4rem solid rgb(249.68, 250.44, 251.2) !important;
}

.border-left-200 {
  border-left: 0.4rem solid rgb(238.28, 240.56, 242.84) !important;
}

.border-left-300 {
  border-left: 0.4rem solid rgb(229.92, 232.96, 236) !important;
}

.border-left-400 {
  border-left: 0.4rem solid rgb(217.76, 222.32, 226.88) !important;
}

.border-left-500 {
  border-left: 0.4rem solid rgb(192.68, 198.76, 204.84) !important;
}

.border-left-600 {
  border-left: 0.4rem solid rgb(143.28, 150.12, 156.2) !important;
}

.border-left-700 {
  border-left: 0.4rem solid rgb(116.68, 122, 127.32) !important;
}

.border-left-800 {
  border-left: 0.4rem solid rgb(100.72, 105.28, 109.84) !important;
}

.border-left-900 {
  border-left: 0.4rem solid rgb(86.28, 89.32, 92.36) !important;
}

/******************************************************************************/
.hbm-logo-responsive {
  font-size: 2.1rem;
}

.hbm-logo-responsive-list {
  font-size: 1.4rem;
}

.responsive-icons {
  font-size: 1.1rem;
}

.responsive-headline {
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  .hbm-logo-responsive {
    font-size: 2.5rem;
  }
  .hbm-logo-responsive-list {
    font-size: 2rem;
  }
  .responsive-headline {
    font-size: 2rem;
  }
  .responsive-icons {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .hbm-logo-responsive {
    font-size: 3rem;
  }
  .responsive-headline {
    font-size: 2.2rem;
  }
  .responsive-icons {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .responsive-headline {
    font-size: 2.7rem;
  }
  .responsive-icons {
    font-size: 2rem;
  }
}
@media (min-width: 1200px) {
  .hbm-logo-responsive {
    font-size: 3.6rem;
  }
}
/******************************************************************************/
/* DROPDOWN                                                                   */
/******************************************************************************/
.upload-dropdown-section .dropdown-menu {
  background-color: rgb(22.0563380282, 85.9436619718, 139.9436619718);
}

#hbmlp-header .dropdown-menu,
.hbmlp-content-aside-body-upload .dropdown-menu,
.hbmlp-content-main-header .dropdown-menu {
  background-color: #e5e5e5;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-menu {
  min-width: 100%;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #fff;
}
.dropdown-menu .dropdown-items {
  background: white;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.dropdown-menu a, .dropdown-menu .dropdown-item {
  padding: 1.2rem 1.5rem;
  border-radius: 0.5rem;
}
.dropdown-menu a:hover, .dropdown-menu .dropdown-item:hover {
  background-color: #e5e5e5;
  color: black;
}
.dropdown-menu a.upload-item, .dropdown-menu .dropdown-item.upload-item {
  padding: 1.2rem 3rem;
}
.dropdown-menu h6 {
  color: #1d71b8;
  font-size: 1.2rem;
}
.dropdown-menu .dropdown-divider {
  border-top: 2px solid #e5e5e5;
  background-color: white;
}

.upload-dropdown-section .dropdown-menu {
  max-height: 70vh;
  overflow-y: auto;
}

.dz-message {
  cursor: pointer;
  line-height: 2.5rem;
}

/******************************************************************************/
/* FORMS                                                                      */
/******************************************************************************/
ul.hbmlp-form-errors {
  font-size: 1rem;
}

.hbm-form-help {
  font-size: 0.9rem;
  font-style: italic;
}

.hbm-form-highlight-control .hbm-form-controls .input-group textarea, .hbm-form-highlight-control .hbm-form-controls .input-group input, .hbm-form-highlight-control .hbm-form-controls .input-group select {
  border-color: #1d71b8;
}
.hbm-form-highlight-control .hbm-form-controls .input-group .input-group-text {
  border-color: #1d71b8;
  color: #1d71b8;
  background-color: rgb(174.661971831, 211.338028169, 242.338028169);
}

.hbm-form-card-narrow .hbm-form-body {
  padding: 0.5rem;
}
.hbm-form-card-narrow .hbm-form-body .hbm-form-group label {
  margin-bottom: 0;
}
.hbm-form-card-narrow .hbm-form-body .hbm-form-group + .hbm-form-group {
  margin-top: 0.5rem;
}

/******************************************************************************/
/* LAYOUT                                                                     */
/******************************************************************************/
#notifications {
  z-index: 1000;
}

/******************************************************************************/
#hbmlp-logo {
  flex: 0 0 250px;
  padding: 15px 0.75rem;
  font-size: 2rem;
}
@media (min-width: 1200px) {
  #hbmlp-logo {
    flex: 0 0 300px;
    padding: 15px 0.75rem;
  }
}
#hbmlp-logo:hover {
  text-decoration: none;
}

/******************************************************************************/
#hbmlp-all {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#hbmlp-all #hbmlp-header {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: sticky;
  top: 0;
}
#hbmlp-all #hbmlp-header .navbar {
  flex: 1;
  padding: 0.5rem 1rem 0.5rem 0;
}
#hbmlp-all #hbmlp-header .navbar .nav {
  font-family: "Arial", "Neue Helvetica", "Helvetica", sans-serif;
  font-size: 1.85rem;
  line-height: 1.25;
}
#hbmlp-all #hbmlp-header .navbar .nav .nav-item {
  margin: 0 1rem;
}
#hbmlp-all #hbmlp-header .navbar .nav .nav-item:first-child {
  margin-left: 0;
}
#hbmlp-all #hbmlp-header .navbar .nav .nav-item .nav-link {
  padding: 0.1rem 0.25rem;
  border: 1px solid transparent;
}
#hbmlp-all #hbmlp-header .navbar .nav .nav-item .nav-link.dropdown-toggle {
  padding: 0.1rem 1.5rem;
  border-radius: 3rem;
  border: 1px solid #1d71b8;
}
#hbmlp-all #hbmlp-header .navbar .nav .nav-item.active .nav-link {
  padding: 0.1rem 1rem;
}
#hbmlp-all #hbmlp-header .navbar .nav .nav-item.active .nav-link, #hbmlp-all #hbmlp-header .navbar .nav .nav-item.active .nav-link:hover, #hbmlp-all #hbmlp-header .navbar .nav .nav-item.active .nav-link:focus {
  color: #fff;
  cursor: default;
  background-color: #1d71b8;
}
#hbmlp-all #hbmlp-header #hbmlp-profile {
  flex: 0 1 auto;
  padding: 0.25rem 0.75rem;
  text-align: center;
}
#hbmlp-all #hbmlp-header #hbmlp-profile #hbmlp-profile-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
#hbmlp-all #hbmlp-header #hbmlp-profile #hbmlp-profile-links > li {
  margin: 0;
  padding: 0;
  line-height: 1;
}
#hbmlp-all #hbmlp-header #hbmlp-profile #hbmlp-profile-links > li > a {
  text-wrap: none;
}
#hbmlp-all #hbmlp-header #hbmlp-profile #hbmlp-profile-links > li:not(:last-child) {
  margin-right: 5px;
  padding-right: 5px;
  border-right: 1px solid #260211;
}
@media (max-width: 991.98px) {
  #hbmlp-all #hbmlp-header {
    flex-wrap: wrap;
  }
  #hbmlp-all #hbmlp-header #hbmlp-logo {
    flex: 1 0 50%;
    order: 1;
  }
  #hbmlp-all #hbmlp-header #hbmlp-logo .responsive-svg {
    max-width: 220px;
  }
  #hbmlp-all #hbmlp-header #hbmlp-profile {
    flex: 1;
    order: 2;
  }
  #hbmlp-all #hbmlp-header .navbar {
    flex: 0 0 100%;
    order: 3;
    padding: 0.5rem 1rem;
  }
  #hbmlp-all #hbmlp-header .navbar .nav {
    justify-content: center;
  }
}
@media (max-width: 575.98px) {
  #hbmlp-all #hbmlp-header #hbmlp-logo {
    flex: 0 0 100%;
    justify-content: center;
  }
  #hbmlp-all #hbmlp-header #hbmlp-profile {
    flex: 0 0 100%;
  }
  #hbmlp-all #hbmlp-header .navbar .nav {
    font-size: 1.5rem;
  }
}
#hbmlp-all #hbmlp-main {
  flex: 1 1 auto;
  display: flex;
  background-color: #f6f6f6;
}
#hbmlp-all #hbmlp-main #hbmlp-aside {
  position: relative;
  flex: 0 0 250px;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);
  background-color: #e5e5e5;
  padding: 0.75rem 0.75rem 0.75rem 0;
}
@media (min-width: 1200px) {
  #hbmlp-all #hbmlp-main #hbmlp-aside {
    flex: 0 0 300px;
  }
}
#hbmlp-all #hbmlp-main #hbmlp-aside #hbmlp-aside-inner {
  position: sticky;
  top: 97px;
  left: 0.75rem;
  right: 0.75rem;
}
#hbmlp-all #hbmlp-main #hbmlp-aside #hbmlp-aside-inner .aside-separator {
  height: 0;
  border-bottom: 1px solid #FFF;
  margin: 2rem -0.75rem;
}
#hbmlp-all #hbmlp-main #hbmlp-aside .card {
  border: none;
}
#hbmlp-all #hbmlp-main #hbmlp-aside .card .card-header {
  background-color: #e5e5e5;
  border: none;
  padding: 0.75rem 1.2rem;
  font-weight: 400;
}
#hbmlp-all #hbmlp-main #hbmlp-aside .card > .list-group {
  background-color: #e5e5e5;
}
#hbmlp-all #hbmlp-main #hbmlp-aside .card > .card-body, #hbmlp-all #hbmlp-main #hbmlp-aside .card > .card-footer,
#hbmlp-all #hbmlp-main #hbmlp-aside .card > .list-group .list-group-item {
  padding: 0.75rem 1.2rem;
  background-color: #e5e5e5;
  border: 2px solid transparent;
  border-radius: 0 30px 30px 0;
  font-weight: 600;
}
#hbmlp-all #hbmlp-main #hbmlp-aside .card > .card-body:hover, #hbmlp-all #hbmlp-main #hbmlp-aside .card > .card-footer:hover,
#hbmlp-all #hbmlp-main #hbmlp-aside .card > .list-group .list-group-item:hover {
  background-color: white;
}
#hbmlp-all #hbmlp-main #hbmlp-aside .card > .card-body.list-group-item-secondary, #hbmlp-all #hbmlp-main #hbmlp-aside .card > .card-footer.list-group-item-secondary,
#hbmlp-all #hbmlp-main #hbmlp-aside .card > .list-group .list-group-item.list-group-item-secondary {
  background-color: #1d71b8;
  color: white;
}
#hbmlp-all #hbmlp-main #hbmlp-aside .card > .card-body.list-group-item-secondary::before, #hbmlp-all #hbmlp-main #hbmlp-aside .card > .card-footer.list-group-item-secondary::before,
#hbmlp-all #hbmlp-main #hbmlp-aside .card > .list-group .list-group-item.list-group-item-secondary::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2%;
  border-radius: 0 5px 5px 0;
  height: 100%;
  background-color: white;
}
#hbmlp-all #hbmlp-main #hbmlp-aside .card > .card-body.list-group-item-secondary .badge-primary, #hbmlp-all #hbmlp-main #hbmlp-aside .card > .card-footer.list-group-item-secondary .badge-primary,
#hbmlp-all #hbmlp-main #hbmlp-aside .card > .list-group .list-group-item.list-group-item-secondary .badge-primary {
  background-color: white;
  color: #1d71b8;
}
#hbmlp-all #hbmlp-main #hbmlp-aside .nav .nav-item {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.25rem;
}
#hbmlp-all #hbmlp-main #hbmlp-aside .nav .nav-item:first-child {
  margin-top: 1rem;
}
#hbmlp-all #hbmlp-main #hbmlp-aside .nav .nav-item:last-child {
  margin-bottom: 0.5rem;
}
#hbmlp-all #hbmlp-main #hbmlp-content {
  flex: 1 1 auto;
  margin-top: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
}
#hbmlp-all #hbmlp-main #hbmlp-content .navbar-light.bg-light {
  background-color: #f6f6f6 !important;
}
@media (min-width: 1200px) {
  #hbmlp-all #hbmlp-main #hbmlp-content {
    padding: 0 150px;
    margin-top: 3rem;
  }
}
@media (max-width: 991.98px) {
  #hbmlp-all #hbmlp-main {
    flex-direction: column;
  }
  #hbmlp-all #hbmlp-main #hbmlp-aside {
    flex: 0 0 auto;
    max-width: 100%;
  }
  #hbmlp-all #hbmlp-main #hbmlp-content {
    flex: 0 0 auto;
    width: 100%;
  }
}
#hbmlp-all #hbmlp-footer {
  flex: 0 1 auto;
  background-color: #e5e5e5;
  z-index: 2;
}
#hbmlp-all #hbmlp-footer .navbar {
  font-size: 0.8em;
  padding: 5px 0.75rem;
}
#hbmlp-all #hbmlp-footer .navbar .navbar-brand {
  width: 50px;
  padding: 0;
}
#hbmlp-all #hbmlp-footer .navbar .navbar-nav .nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

body.inner-scroll #hbmlp-all {
  max-height: 100vh;
  overflow: hidden;
}
body.inner-scroll #hbmlp-all #hbmlp-main {
  overflow: hidden;
}
body.inner-scroll #hbmlp-all #hbmlp-main #hbmlp-aside #hbmlp-aside-inner {
  top: 0;
}
body.inner-scroll #hbmlp-all #hbmlp-main #hbmlp-content {
  overflow: visible;
  padding: 0 30px;
}
body.inner-scroll #hbmlp-all #hbmlp-main #hbmlp-content .scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 185px);
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
body.inner-scroll #hbmlp-all #hbmlp-main #hbmlp-content .scrollable .scrollable::-webkit-scrollbar {
  display: none;
}
body.inner-scroll #hbmlp-all #hbmlp-main #hbmlp-content .scroll-separator {
  position: absolute;
  left: calc(50% - 1px);
  top: 0px;
  bottom: -25px;
  width: 2px;
  background-color: #e5e5e5;
}

/******************************************************************************/
/* LIST-GROUP                                                                 */
/******************************************************************************/
.list-group.list-group-flush.border-top .list-group-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

/******************************************************************************/
/* MERMAID                                                                    */
/******************************************************************************/
#hbmlp-content .node.hbmlp-mermaid-primary .svg-inline--fa,
#hbmlp-content .node.hbmlp-mermaid-primary-restricted .svg-inline--fa {
  margin-right: -12px;
}
#hbmlp-content .node.hbmlp-mermaid-primary .svg-inline--fa path,
#hbmlp-content .node.hbmlp-mermaid-primary-restricted .svg-inline--fa path {
  fill: #260211;
}
#hbmlp-content .node.hbmlp-mermaid-primary a,
#hbmlp-content .node.hbmlp-mermaid-primary-restricted a {
  color: #FFF;
  font-weight: bold;
  font-size: 0.9em;
  text-align: center;
}
#hbmlp-content .node.hbmlp-mermaid-primary rect,
#hbmlp-content .node.hbmlp-mermaid-primary circle,
#hbmlp-content .node.hbmlp-mermaid-primary-restricted rect,
#hbmlp-content .node.hbmlp-mermaid-primary-restricted circle {
  fill: #1d71b8;
  stroke: #260211;
  stroke-width: 2px;
}

#hbmlp-content .node.hbmlp-mermaid-secondary .svg-inline--fa,
#hbmlp-content .node.hbmlp-mermaid-secondary-restricted .svg-inline--fa {
  margin-right: -12px;
}
#hbmlp-content .node.hbmlp-mermaid-secondary .svg-inline--fa path,
#hbmlp-content .node.hbmlp-mermaid-secondary-restricted .svg-inline--fa path {
  fill: #260211;
}
#hbmlp-content .node.hbmlp-mermaid-secondary a,
#hbmlp-content .node.hbmlp-mermaid-secondary-restricted a {
  color: #260211;
  font-size: 0.9em;
  text-align: center;
}
#hbmlp-content .node.hbmlp-mermaid-secondary rect,
#hbmlp-content .node.hbmlp-mermaid-secondary circle,
#hbmlp-content .node.hbmlp-mermaid-secondary-restricted rect,
#hbmlp-content .node.hbmlp-mermaid-secondary-restricted circle {
  fill: rgb(174.661971831, 211.338028169, 242.338028169);
  stroke: #260211;
  stroke-width: 2px;
}

#hbmlp-content .node.hbmlp-mermaid-primary-restricted .svg-inline--fa path,
#hbmlp-content .node.hbmlp-mermaid-secondary-restricted .svg-inline--fa path {
  fill: #EB5757;
}
#hbmlp-content .node.hbmlp-mermaid-primary-restricted a,
#hbmlp-content .node.hbmlp-mermaid-secondary-restricted a {
  font-style: italic;
}
#hbmlp-content .node.hbmlp-mermaid-primary-restricted rect,
#hbmlp-content .node.hbmlp-mermaid-primary-restricted circle,
#hbmlp-content .node.hbmlp-mermaid-secondary-restricted rect,
#hbmlp-content .node.hbmlp-mermaid-secondary-restricted circle {
  fill: rgb(251, 221.4, 221.4);
}

#hbmlp-content .node.hbmlp-mermaid-exit a {
  color: #FFF;
  font-size: 0.9em;
  text-align: center;
}
#hbmlp-content .node.hbmlp-mermaid-exit polygon,
#hbmlp-content .node.hbmlp-mermaid-exit rect,
#hbmlp-content .node.hbmlp-mermaid-exit circle {
  fill: #999;
  stroke: #260211;
  stroke-width: 2px;
}

/******************************************************************************/
/* MISC                                                                       */
/******************************************************************************/
body.ajax-secured [data-ajax-secured] {
  cursor: default;
}
body.ajax-secured [data-ajax-secured=hidden] {
  display: none;
}

/******************************************************************************/
.tag-editor {
  border: 1px solid #CCCCCC;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}

.editor-container {
  z-index: 1;
  border: 1px solid #ced4da;
  border-radius: 0rem;
}

/******************************************************************************/
.dropzone {
  border: 4px dashed #1d71b8;
  border-radius: 10px;
  min-height: 150px;
  color: #1d71b8;
  background: none;
}

/******************************************************************************/
[v-cloak] {
  display: none;
}

/******************************************************************************/
.inline-edit[contenteditable] {
  position: relative;
}

.inline-edit[contenteditable]:focus {
  outline: #1d71b8 solid 2px;
  outline-offset: 2px;
}

.inline-edit[contenteditable]:hover:not(:focus) {
  outline: rgb(174.661971831, 211.338028169, 242.338028169) solid 2px;
  outline-offset: 2px;
}

@keyframes inline-edit-success-animation {
  0% {
    color: #28a745;
  }
  100% {
    color: initial;
  }
}
.inline-edit-success {
  animation: inline-edit-success-animation linear 2s 1;
}

.inline-edit-error {
  color: #EB5757;
  outline: dashed 2px #EB5757;
  outline-offset: 2px;
}

/******************************************************************************/
span.visibility-restricted {
  padding: 0.75rem;
  border-radius: 1rem;
  background-color: rgb(251, 221.4, 221.4);
}

/******************************************************************************/
.environment-dev {
  border-left: 10px solid #dc3545;
}

.environment-stage {
  border-left: 10px solid #FFC107;
}

/******************************************************************************/
.mfr-0 > *:not(:last-child) {
  margin-right: 0 !important;
}

.mfc-0 > *:not(:last-child) {
  margin-bottom: 0 !important;
}

.mfr-1 > *:not(:last-child) {
  margin-right: 0.25rem !important;
}

.mfc-1 > *:not(:last-child) {
  margin-bottom: 0.25rem !important;
}

.mfr-2 > *:not(:last-child) {
  margin-right: 0.5rem !important;
}

.mfc-2 > *:not(:last-child) {
  margin-bottom: 0.5rem !important;
}

.mfr-3 > *:not(:last-child) {
  margin-right: 1rem !important;
}

.mfc-3 > *:not(:last-child) {
  margin-bottom: 1rem !important;
}

.mfr-4 > *:not(:last-child) {
  margin-right: 1.5rem !important;
}

.mfc-4 > *:not(:last-child) {
  margin-bottom: 1.5rem !important;
}

.mfr-5 > *:not(:last-child) {
  margin-right: 3rem !important;
}

.mfc-5 > *:not(:last-child) {
  margin-bottom: 3rem !important;
}

.mfr-6 > *:not(:last-child) {
  margin-right: 5rem !important;
}

.mfc-6 > *:not(:last-child) {
  margin-bottom: 5rem !important;
}

.pfr-0 > *:not(:last-child) {
  padding-right: 0 !important;
}

.pfc-0 > *:not(:last-child) {
  padding-bottom: 0 !important;
}

.pfr-1 > *:not(:last-child) {
  padding-right: 0.25rem !important;
}

.pfc-1 > *:not(:last-child) {
  padding-bottom: 0.25rem !important;
}

.pfr-2 > *:not(:last-child) {
  padding-right: 0.5rem !important;
}

.pfc-2 > *:not(:last-child) {
  padding-bottom: 0.5rem !important;
}

.pfr-3 > *:not(:last-child) {
  padding-right: 1rem !important;
}

.pfc-3 > *:not(:last-child) {
  padding-bottom: 1rem !important;
}

.pfr-4 > *:not(:last-child) {
  padding-right: 1.5rem !important;
}

.pfc-4 > *:not(:last-child) {
  padding-bottom: 1.5rem !important;
}

.pfr-5 > *:not(:last-child) {
  padding-right: 3rem !important;
}

.pfc-5 > *:not(:last-child) {
  padding-bottom: 3rem !important;
}

.pfr-6 > *:not(:last-child) {
  padding-right: 5rem !important;
}

.pfc-6 > *:not(:last-child) {
  padding-bottom: 5rem !important;
}

@media (min-width: 576px) {
  .mfr-sm-0 > *:not(:last-child) {
    margin-right: 0 !important;
  }
  .mfc-sm-0 > *:not(:last-child) {
    margin-bottom: 0 !important;
  }
  .mfr-sm-1 > *:not(:last-child) {
    margin-right: 0.25rem !important;
  }
  .mfc-sm-1 > *:not(:last-child) {
    margin-bottom: 0.25rem !important;
  }
  .mfr-sm-2 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }
  .mfc-sm-2 > *:not(:last-child) {
    margin-bottom: 0.5rem !important;
  }
  .mfr-sm-3 > *:not(:last-child) {
    margin-right: 1rem !important;
  }
  .mfc-sm-3 > *:not(:last-child) {
    margin-bottom: 1rem !important;
  }
  .mfr-sm-4 > *:not(:last-child) {
    margin-right: 1.5rem !important;
  }
  .mfc-sm-4 > *:not(:last-child) {
    margin-bottom: 1.5rem !important;
  }
  .mfr-sm-5 > *:not(:last-child) {
    margin-right: 3rem !important;
  }
  .mfc-sm-5 > *:not(:last-child) {
    margin-bottom: 3rem !important;
  }
  .mfr-sm-6 > *:not(:last-child) {
    margin-right: 5rem !important;
  }
  .mfc-sm-6 > *:not(:last-child) {
    margin-bottom: 5rem !important;
  }
  .pfr-sm-0 > *:not(:last-child) {
    padding-right: 0 !important;
  }
  .pfc-sm-0 > *:not(:last-child) {
    padding-bottom: 0 !important;
  }
  .pfr-sm-1 > *:not(:last-child) {
    padding-right: 0.25rem !important;
  }
  .pfc-sm-1 > *:not(:last-child) {
    padding-bottom: 0.25rem !important;
  }
  .pfr-sm-2 > *:not(:last-child) {
    padding-right: 0.5rem !important;
  }
  .pfc-sm-2 > *:not(:last-child) {
    padding-bottom: 0.5rem !important;
  }
  .pfr-sm-3 > *:not(:last-child) {
    padding-right: 1rem !important;
  }
  .pfc-sm-3 > *:not(:last-child) {
    padding-bottom: 1rem !important;
  }
  .pfr-sm-4 > *:not(:last-child) {
    padding-right: 1.5rem !important;
  }
  .pfc-sm-4 > *:not(:last-child) {
    padding-bottom: 1.5rem !important;
  }
  .pfr-sm-5 > *:not(:last-child) {
    padding-right: 3rem !important;
  }
  .pfc-sm-5 > *:not(:last-child) {
    padding-bottom: 3rem !important;
  }
  .pfr-sm-6 > *:not(:last-child) {
    padding-right: 5rem !important;
  }
  .pfc-sm-6 > *:not(:last-child) {
    padding-bottom: 5rem !important;
  }
}
@media (min-width: 768px) {
  .mfr-md-0 > *:not(:last-child) {
    margin-right: 0 !important;
  }
  .mfc-md-0 > *:not(:last-child) {
    margin-bottom: 0 !important;
  }
  .mfr-md-1 > *:not(:last-child) {
    margin-right: 0.25rem !important;
  }
  .mfc-md-1 > *:not(:last-child) {
    margin-bottom: 0.25rem !important;
  }
  .mfr-md-2 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }
  .mfc-md-2 > *:not(:last-child) {
    margin-bottom: 0.5rem !important;
  }
  .mfr-md-3 > *:not(:last-child) {
    margin-right: 1rem !important;
  }
  .mfc-md-3 > *:not(:last-child) {
    margin-bottom: 1rem !important;
  }
  .mfr-md-4 > *:not(:last-child) {
    margin-right: 1.5rem !important;
  }
  .mfc-md-4 > *:not(:last-child) {
    margin-bottom: 1.5rem !important;
  }
  .mfr-md-5 > *:not(:last-child) {
    margin-right: 3rem !important;
  }
  .mfc-md-5 > *:not(:last-child) {
    margin-bottom: 3rem !important;
  }
  .mfr-md-6 > *:not(:last-child) {
    margin-right: 5rem !important;
  }
  .mfc-md-6 > *:not(:last-child) {
    margin-bottom: 5rem !important;
  }
  .pfr-md-0 > *:not(:last-child) {
    padding-right: 0 !important;
  }
  .pfc-md-0 > *:not(:last-child) {
    padding-bottom: 0 !important;
  }
  .pfr-md-1 > *:not(:last-child) {
    padding-right: 0.25rem !important;
  }
  .pfc-md-1 > *:not(:last-child) {
    padding-bottom: 0.25rem !important;
  }
  .pfr-md-2 > *:not(:last-child) {
    padding-right: 0.5rem !important;
  }
  .pfc-md-2 > *:not(:last-child) {
    padding-bottom: 0.5rem !important;
  }
  .pfr-md-3 > *:not(:last-child) {
    padding-right: 1rem !important;
  }
  .pfc-md-3 > *:not(:last-child) {
    padding-bottom: 1rem !important;
  }
  .pfr-md-4 > *:not(:last-child) {
    padding-right: 1.5rem !important;
  }
  .pfc-md-4 > *:not(:last-child) {
    padding-bottom: 1.5rem !important;
  }
  .pfr-md-5 > *:not(:last-child) {
    padding-right: 3rem !important;
  }
  .pfc-md-5 > *:not(:last-child) {
    padding-bottom: 3rem !important;
  }
  .pfr-md-6 > *:not(:last-child) {
    padding-right: 5rem !important;
  }
  .pfc-md-6 > *:not(:last-child) {
    padding-bottom: 5rem !important;
  }
}
@media (min-width: 992px) {
  .mfr-lg-0 > *:not(:last-child) {
    margin-right: 0 !important;
  }
  .mfc-lg-0 > *:not(:last-child) {
    margin-bottom: 0 !important;
  }
  .mfr-lg-1 > *:not(:last-child) {
    margin-right: 0.25rem !important;
  }
  .mfc-lg-1 > *:not(:last-child) {
    margin-bottom: 0.25rem !important;
  }
  .mfr-lg-2 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }
  .mfc-lg-2 > *:not(:last-child) {
    margin-bottom: 0.5rem !important;
  }
  .mfr-lg-3 > *:not(:last-child) {
    margin-right: 1rem !important;
  }
  .mfc-lg-3 > *:not(:last-child) {
    margin-bottom: 1rem !important;
  }
  .mfr-lg-4 > *:not(:last-child) {
    margin-right: 1.5rem !important;
  }
  .mfc-lg-4 > *:not(:last-child) {
    margin-bottom: 1.5rem !important;
  }
  .mfr-lg-5 > *:not(:last-child) {
    margin-right: 3rem !important;
  }
  .mfc-lg-5 > *:not(:last-child) {
    margin-bottom: 3rem !important;
  }
  .mfr-lg-6 > *:not(:last-child) {
    margin-right: 5rem !important;
  }
  .mfc-lg-6 > *:not(:last-child) {
    margin-bottom: 5rem !important;
  }
  .pfr-lg-0 > *:not(:last-child) {
    padding-right: 0 !important;
  }
  .pfc-lg-0 > *:not(:last-child) {
    padding-bottom: 0 !important;
  }
  .pfr-lg-1 > *:not(:last-child) {
    padding-right: 0.25rem !important;
  }
  .pfc-lg-1 > *:not(:last-child) {
    padding-bottom: 0.25rem !important;
  }
  .pfr-lg-2 > *:not(:last-child) {
    padding-right: 0.5rem !important;
  }
  .pfc-lg-2 > *:not(:last-child) {
    padding-bottom: 0.5rem !important;
  }
  .pfr-lg-3 > *:not(:last-child) {
    padding-right: 1rem !important;
  }
  .pfc-lg-3 > *:not(:last-child) {
    padding-bottom: 1rem !important;
  }
  .pfr-lg-4 > *:not(:last-child) {
    padding-right: 1.5rem !important;
  }
  .pfc-lg-4 > *:not(:last-child) {
    padding-bottom: 1.5rem !important;
  }
  .pfr-lg-5 > *:not(:last-child) {
    padding-right: 3rem !important;
  }
  .pfc-lg-5 > *:not(:last-child) {
    padding-bottom: 3rem !important;
  }
  .pfr-lg-6 > *:not(:last-child) {
    padding-right: 5rem !important;
  }
  .pfc-lg-6 > *:not(:last-child) {
    padding-bottom: 5rem !important;
  }
}
@media (min-width: 1200px) {
  .mfr-xl-0 > *:not(:last-child) {
    margin-right: 0 !important;
  }
  .mfc-xl-0 > *:not(:last-child) {
    margin-bottom: 0 !important;
  }
  .mfr-xl-1 > *:not(:last-child) {
    margin-right: 0.25rem !important;
  }
  .mfc-xl-1 > *:not(:last-child) {
    margin-bottom: 0.25rem !important;
  }
  .mfr-xl-2 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }
  .mfc-xl-2 > *:not(:last-child) {
    margin-bottom: 0.5rem !important;
  }
  .mfr-xl-3 > *:not(:last-child) {
    margin-right: 1rem !important;
  }
  .mfc-xl-3 > *:not(:last-child) {
    margin-bottom: 1rem !important;
  }
  .mfr-xl-4 > *:not(:last-child) {
    margin-right: 1.5rem !important;
  }
  .mfc-xl-4 > *:not(:last-child) {
    margin-bottom: 1.5rem !important;
  }
  .mfr-xl-5 > *:not(:last-child) {
    margin-right: 3rem !important;
  }
  .mfc-xl-5 > *:not(:last-child) {
    margin-bottom: 3rem !important;
  }
  .mfr-xl-6 > *:not(:last-child) {
    margin-right: 5rem !important;
  }
  .mfc-xl-6 > *:not(:last-child) {
    margin-bottom: 5rem !important;
  }
  .pfr-xl-0 > *:not(:last-child) {
    padding-right: 0 !important;
  }
  .pfc-xl-0 > *:not(:last-child) {
    padding-bottom: 0 !important;
  }
  .pfr-xl-1 > *:not(:last-child) {
    padding-right: 0.25rem !important;
  }
  .pfc-xl-1 > *:not(:last-child) {
    padding-bottom: 0.25rem !important;
  }
  .pfr-xl-2 > *:not(:last-child) {
    padding-right: 0.5rem !important;
  }
  .pfc-xl-2 > *:not(:last-child) {
    padding-bottom: 0.5rem !important;
  }
  .pfr-xl-3 > *:not(:last-child) {
    padding-right: 1rem !important;
  }
  .pfc-xl-3 > *:not(:last-child) {
    padding-bottom: 1rem !important;
  }
  .pfr-xl-4 > *:not(:last-child) {
    padding-right: 1.5rem !important;
  }
  .pfc-xl-4 > *:not(:last-child) {
    padding-bottom: 1.5rem !important;
  }
  .pfr-xl-5 > *:not(:last-child) {
    padding-right: 3rem !important;
  }
  .pfc-xl-5 > *:not(:last-child) {
    padding-bottom: 3rem !important;
  }
  .pfr-xl-6 > *:not(:last-child) {
    padding-right: 5rem !important;
  }
  .pfc-xl-6 > *:not(:last-child) {
    padding-bottom: 5rem !important;
  }
}
/******************************************************************************/
.highlight-diff del {
  font-weight: bold;
  color: #EB5757;
}
.highlight-diff ins {
  font-weight: bold;
  color: #28a745;
}

/******************************************************************************/
/* MODALS                                                                     */
/******************************************************************************/
.hbmlp-modal .modal-content {
  padding: 3rem;
}
.hbmlp-modal .modal-content .modal-header {
  padding: 0;
  border-bottom: 0;
}
.hbmlp-modal .modal-content .modal-header .modal-title {
  font-size: 2rem;
  text-transform: uppercase;
}
.hbmlp-modal .modal-content .modal-header hr {
  margin: 0.5rem 1.5rem 0 1.5rem;
  border: none;
  height: 5px;
}
.hbmlp-modal .modal-content .modal-body {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.hbmlp-modal .modal-content .modal-footer {
  padding: 0;
  border-top: 0;
}

/******************************************************************************/
/* NAVIGATIONS                                                                */
/******************************************************************************/
/******************************************************************************/
/* HBMLP-CONTENT-MAIN AND -ASIDE                                              */
/******************************************************************************/
.hbmlp-content-main-header a.nav-link,
.hbmlp-content-aside a.nav-link {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 5px 20px 0 10px;
  padding: 0;
  border-bottom: 4px solid transparent;
  border-radius: 5px;
  transition: all 300ms ease;
  position: relative;
}
.hbmlp-content-main-header a.nav-link svg,
.hbmlp-content-aside a.nav-link svg {
  width: 20px;
  height: 20px;
}
.hbmlp-content-main-header a.nav-link .badge-nav-pills,
.hbmlp-content-aside a.nav-link .badge-nav-pills {
  position: absolute;
  top: -5px;
  right: -1px;
}
.hbmlp-content-main-header a.nav-link.active,
.hbmlp-content-aside a.nav-link.active {
  background: inherit;
  color: #1d71b8;
}
.hbmlp-content-main-header a.nav-link.active:before,
.hbmlp-content-aside a.nav-link.active:before {
  content: "";
  position: absolute;
  bottom: -4px;
  width: 115%;
  border-radius: 10px 10px 0 0;
  height: 12%;
  background-color: #1d71b8;
}
.hbmlp-content-main-header a.nav-link:hover:before,
.hbmlp-content-aside a.nav-link:hover:before {
  content: "";
  position: absolute;
  bottom: -4px;
  width: 115%;
  border-radius: 10px 10px 0 0;
  height: 12%;
  color: rgb(33.8605633803, 131.9394366197, 214.8394366197);
  background-color: rgb(33.8605633803, 131.9394366197, 214.8394366197);
}

/******************************************************************************/
/* HBMLP-CONTENT-ASIDE (SPLIT VIEW)                                           */
/******************************************************************************/
.hbmlp-content-aside .nav-pills {
  background-color: #e5e5e5;
  border-bottom: 4px solid #fff;
}

/******************************************************************************/
/* TABELS                                                                     */
/******************************************************************************/
@media (max-width: 767.98px) {
  .pagination {
    flex-wrap: wrap;
  }
}

/******************************************************************************/
/* SELECT2-BOOTSTRAP4-THEME                                                    */
/******************************************************************************/
.select2-container--bootstrap4 .select2-search--dropdown .select2-search__field {
  border-color: #1d71b8;
  background-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
}
.select2-container--bootstrap4 .select2-selection__clear {
  width: 1.3rem;
  height: 1.3rem;
  line-height: 1.2rem;
  text-align: center;
  padding-left: 0;
  margin-right: 0;
  margin-top: 0.5rem;
  right: 4px;
  color: #fff;
  background-color: #1d71b8;
}
.select2-container--bootstrap4 .select2-selection__clear:hover {
  background-color: rgb(23.7922535211, 92.7077464789, 150.9577464789);
}
.select2-container--bootstrap4 .select2-results > .select2-results__options {
  max-height: calc(100vh - 550px);
}
.select2-container--bootstrap4 .select2-results > .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted) {
  color: #260211;
  background-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
}
.select2-container--bootstrap4 .select2-results > .select2-results__options .select2-results__option.select2-results__option--highlighted {
  color: #fff;
  background-color: #1d71b8;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  line-height: 1.5;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  width: 100%;
  color: #260211;
  background-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  margin: 0 0.75rem 0 0;
  color: #1d71b8;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover {
  color: rgb(18.5845070423, 72.4154929577, 117.9154929577);
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-search.select2-search--inline {
  width: 100%;
  background-color: #f8f9fa;
  border: 1px dashed #ced4da;
  margin-top: 4px;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-search.select2-search--inline .select2-search__field {
  background-color: transparent;
  margin: 0;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  line-height: 1.5;
  border-radius: 0.25rem;
  box-sizing: content-box;
}

/******************************************************************************/
/* SIZING                                                                     */
/******************************************************************************/
.w-5 {
  width: 5%;
}

.w-35 {
  width: 35%;
}

/******************************************************************************/
/* STEPS                                                                      */
/******************************************************************************/
ul.steps {
  flex-wrap: wrap;
}

/******************************************************************************/
/* TABELS                                                                     */
/******************************************************************************/
table.standalone-rows {
  border-collapse: separate;
  border-spacing: 0 1rem;
  margin-top: -1rem;
}
table.standalone-rows.table thead th {
  vertical-align: middle;
  border: none;
}
table.standalone-rows.table tbody td {
  vertical-align: middle;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  background-color: white;
}
table.standalone-rows.table tbody tr.visibility-restricted td {
  background-color: rgb(251, 221.4, 221.4);
}
table.standalone-rows.table tbody tr.selected td {
  background-color: rgb(174.661971831, 211.338028169, 242.338028169);
}
table.standalone-rows.table tbody tr.row-primary td {
  background-color: #1d71b8;
}
table.standalone-rows.table tbody tr.row-alert-primary td {
  background-color: rgb(209.8, 226.6, 240.8);
}
table.standalone-rows.table tbody tr.row-secondary td {
  background-color: rgb(174.661971831, 211.338028169, 242.338028169);
}
table.standalone-rows.table tbody tr.row-alert-secondary td {
  background-color: rgb(238.9323943662, 246.2676056338, 252.4676056338);
}
table.standalone-rows.table tbody tr.row-success td {
  background-color: #28a745;
}
table.standalone-rows.table tbody tr.row-alert-success td {
  background-color: rgb(212, 237.4, 217.8);
}
table.standalone-rows.table tbody tr.row-info td {
  background-color: #17a2b8;
}
table.standalone-rows.table tbody tr.row-alert-info td {
  background-color: rgb(208.6, 236.4, 240.8);
}
table.standalone-rows.table tbody tr.row-warning td {
  background-color: #ffc107;
}
table.standalone-rows.table tbody tr.row-alert-warning td {
  background-color: rgb(255, 242.6, 205.4);
}
table.standalone-rows.table tbody tr.row-danger td {
  background-color: #EB5757;
}
table.standalone-rows.table tbody tr.row-alert-danger td {
  background-color: rgb(251, 221.4, 221.4);
}
table.standalone-rows.table tbody tr.row-light td {
  background-color: #f8f9fa;
}
table.standalone-rows.table tbody tr.row-alert-light td {
  background-color: rgb(253.6, 253.8, 254);
}
table.standalone-rows.table tbody tr.row-dark td {
  background-color: #343a40;
}
table.standalone-rows.table tbody tr.row-alert-dark td {
  background-color: rgb(214.4, 215.6, 216.8);
}
table.standalone-rows.table tbody tr.row-hbmlp-color-steps td {
  background-color: #999;
}
table.standalone-rows.table tbody tr.row-alert-hbmlp-color-steps td {
  background-color: rgb(234.6, 234.6, 234.6);
}
table.standalone-rows.table tbody tr.row-secondary-light td {
  background-color: rgb(219.7683098592, 235.1936256247, 248.2316901408);
}
table.standalone-rows.table tbody tr.row-alert-secondary-light td {
  background-color: rgb(247.9536619718, 251.0387251249, 253.6463380282);
}
table.standalone-rows.table tbody tr.row-blue td {
  background-color: #007bff;
}
table.standalone-rows.table tbody tr.row-alert-blue td {
  background-color: rgb(204, 228.6, 255);
}
table.standalone-rows.table tbody tr.row-indigo td {
  background-color: #6610f2;
}
table.standalone-rows.table tbody tr.row-alert-indigo td {
  background-color: rgb(224.4, 207.2, 252.4);
}
table.standalone-rows.table tbody tr.row-purple td {
  background-color: #6f42c1;
}
table.standalone-rows.table tbody tr.row-alert-purple td {
  background-color: rgb(226.2, 217.2, 242.6);
}
table.standalone-rows.table tbody tr.row-pink td {
  background-color: #e83e8c;
}
table.standalone-rows.table tbody tr.row-alert-pink td {
  background-color: rgb(250.4, 216.4, 232);
}
table.standalone-rows.table tbody tr.row-red td {
  background-color: #dc3545;
}
table.standalone-rows.table tbody tr.row-alert-red td {
  background-color: rgb(248, 214.6, 217.8);
}
table.standalone-rows.table tbody tr.row-orange td {
  background-color: #fd7e14;
}
table.standalone-rows.table tbody tr.row-alert-orange td {
  background-color: rgb(254.6, 229.2, 208);
}
table.standalone-rows.table tbody tr.row-yellow td {
  background-color: #ffc107;
}
table.standalone-rows.table tbody tr.row-alert-yellow td {
  background-color: rgb(255, 242.6, 205.4);
}
table.standalone-rows.table tbody tr.row-green td {
  background-color: #28a745;
}
table.standalone-rows.table tbody tr.row-alert-green td {
  background-color: rgb(212, 237.4, 217.8);
}
table.standalone-rows.table tbody tr.row-teal td {
  background-color: #20c997;
}
table.standalone-rows.table tbody tr.row-alert-teal td {
  background-color: rgb(210.4, 244.2, 234.2);
}
table.standalone-rows.table tbody tr.row-cyan td {
  background-color: #17a2b8;
}
table.standalone-rows.table tbody tr.row-alert-cyan td {
  background-color: rgb(208.6, 236.4, 240.8);
}
table.standalone-rows.table tbody tr.row-white td {
  background-color: #fff;
}
table.standalone-rows.table tbody tr.row-alert-white td {
  background-color: white;
}
table.standalone-rows.table tbody tr.row-gray td {
  background-color: #6c757d;
}
table.standalone-rows.table tbody tr.row-alert-gray td {
  background-color: rgb(225.6, 227.4, 229);
}
table.standalone-rows.table tbody tr.row-gray-dark td {
  background-color: #343a40;
}
table.standalone-rows.table tbody tr.row-alert-gray-dark td {
  background-color: rgb(214.4, 215.6, 216.8);
}
table.standalone-rows.table tbody tr.row-100 td {
  background-color: #f8f9fa;
}
table.standalone-rows.table tbody tr.row-alert-100 td {
  background-color: rgb(253.6, 253.8, 254);
}
table.standalone-rows.table tbody tr.row-200 td {
  background-color: #e9ecef;
}
table.standalone-rows.table tbody tr.row-alert-200 td {
  background-color: rgb(250.6, 251.2, 251.8);
}
table.standalone-rows.table tbody tr.row-300 td {
  background-color: #dee2e6;
}
table.standalone-rows.table tbody tr.row-alert-300 td {
  background-color: rgb(248.4, 249.2, 250);
}
table.standalone-rows.table tbody tr.row-400 td {
  background-color: #ced4da;
}
table.standalone-rows.table tbody tr.row-alert-400 td {
  background-color: rgb(245.2, 246.4, 247.6);
}
table.standalone-rows.table tbody tr.row-500 td {
  background-color: #adb5bd;
}
table.standalone-rows.table tbody tr.row-alert-500 td {
  background-color: rgb(238.6, 240.2, 241.8);
}
table.standalone-rows.table tbody tr.row-600 td {
  background-color: #6c757d;
}
table.standalone-rows.table tbody tr.row-alert-600 td {
  background-color: rgb(225.6, 227.4, 229);
}
table.standalone-rows.table tbody tr.row-700 td {
  background-color: #495057;
}
table.standalone-rows.table tbody tr.row-alert-700 td {
  background-color: rgb(218.6, 220, 221.4);
}
table.standalone-rows.table tbody tr.row-800 td {
  background-color: #343a40;
}
table.standalone-rows.table tbody tr.row-alert-800 td {
  background-color: rgb(214.4, 215.6, 216.8);
}
table.standalone-rows.table tbody tr.row-900 td {
  background-color: #212529;
}
table.standalone-rows.table tbody tr.row-alert-900 td {
  background-color: rgb(210.6, 211.4, 212.2);
}
table.standalone-rows th:first-child,
table.standalone-rows td:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-left: 2px solid transparent;
}
table.standalone-rows th:last-child,
table.standalone-rows td:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-right: 2px solid transparent;
}
table.standalone-rows th.width-minimal, table.standalone-rows td.width-minimal {
  width: 1%;
  white-space: nowrap;
}
table.standalone-rows th.width-company, table.standalone-rows td.width-company {
  width: 33%;
}
table.standalone-rows tbody tr:hover td {
  border-top: 2px solid #1d71b8;
  border-bottom: 2px solid #1d71b8;
}
table.standalone-rows tbody tr:hover th:first-child, table.standalone-rows tbody tr:hover td:first-child {
  border-left: 2px solid #1d71b8;
}
table.standalone-rows tbody tr:hover th:last-child, table.standalone-rows tbody tr:hover td:last-child {
  border-right: 2px solid #1d71b8;
}

/******************************************************************************/
/* TABLES FOR HBMLP-CONTENT-MAIN                                              */
/******************************************************************************/
.hbmlp-content-main-body table th.width-minimal, .hbmlp-content-main-body table td.width-minimal {
  white-space: normal;
}

/******************************************************************************/
/* TABLE FOR PERMISSIONS                                                      */
/******************************************************************************/
.table-fix-head {
  overflow: auto;
  height: 1000px;
}
.table-fix-head thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.table-fix-head table.standalone-rows.table thead th {
  background-color: #f6f6f6;
  border-radius: 0;
}
.table-fix-head .table-head-permission {
  width: 400px;
}
.table-fix-head .table-head-permission-card {
  background: #fff;
  border-radius: 1rem;
  padding: 1rem;
  color: #1d71b8;
}
.table-fix-head .table-head-permission .badge {
  padding: 0.5rem 0.8rem;
  border: none;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  margin-left: 0.5rem;
  white-space: break-spaces;
}
.table-fix-head .table-head-permission .badge-info {
  color: #1d71b8;
  background-color: rgb(218.7183098592, 235.2816901408, 249.2816901408);
}
.table-fix-head .table-head-permission .badge-success {
  color: #28a745;
  background-color: rgb(216.2753623188, 245.7246376812, 223);
}
.table-fix-head td.row-headline {
  color: #1d71b8;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.2;
  padding: 3rem 0 1rem;
  border-radius: 0;
  background-color: #f6f6f6 !important;
  position: sticky;
  top: 180px;
}
.table-fix-head td.row-headline.sm {
  top: 50px;
}
.table-fix-head td.row-headline:first-child, .table-fix-head td.row-headline:last-child {
  border-radius: 0;
}
.table-fix-head td.row-headline:hover, .table-fix-head td.row-headline:hover:first-child, .table-fix-head td.row-headline:hover:last-child {
  border-color: transparent;
}

/******************************************************************************/
/* TABLES CONFLUENCE STYLES                                                   */
/******************************************************************************/
table.confluenceTable {
  border: 1px solid #dee2e6;
}
table.confluenceTable th.confluenceTh {
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  background-color: #e9ecef;
}
table.confluenceTable td.confluenceTd {
  padding: 0.75rem;
  border: 1px solid #dee2e6;
}

/******************************************************************************/
/* TAGIFY                                                                     */
/******************************************************************************/
.datagrid-menu-search .tagify__tag > div > .tagify__tag-text {
  white-space: nowrap;
}

#hbmlp_form_type_admin_reminder_group_default_message_wrapper .tagify__input {
  line-height: 2;
}

.tagify__tag {
  max-width: 100%;
  background-color: var(--tag-bg);
}
.tagify__tag:hover {
  background-color: var(--tag-hover);
}
.tagify__tag .tagify__tag-text__wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.tagify__tag .tagify__tag-text__wrapper .tagify__tag-text__icon {
  margin-right: 0.5rem;
  flex-shrink: 0;
}
.tagify__tag .tagify__tag-text__wrapper .tagify__tag-text {
  margin-right: 0.5rem;
  flex-shrink: 1;
}

.tagify__input::after {
  content: none !important;
}

.tagify__dropdown {
  min-width: 220px !important;
}

.reminder-body {
  line-height: 1.7;
}

.entity-wrapper {
  padding: 0 0.75rem;
  line-height: 1.4;
}
.entity-wrapper .entity-icon {
  margin-right: 0.35rem;
}
.entity-wrapper.entity-missing .entity-text {
  text-decoration: line-through;
}

/******************************************************************************/
/* TASKS                                                                      */
/******************************************************************************/
[data-ajax-target=task-detail] {
  background-color: #e5e5e5;
  padding-top: 15px;
  padding-bottom: 15px;
}

/* Tooltip */
.tooltip:not(.clipboardable-tooltip) > .tooltip-inner {
  background-color: #fff;
  color: #1d71b8;
  border: 1px solid #e5e5e5;
  box-shadow: 2px 2px 10px #e5e5e5, -2px -2px 10px #e5e5e5;
  padding: 15px 20px;
  min-width: 380px;
  text-align: left;
  font-size: 1.1rem;
  border-radius: 5px;
}
.tooltip:not(.clipboardable-tooltip) > .tooltip-inner ul {
  list-style-type: none;
}
.tooltip:not(.clipboardable-tooltip) > .tooltip-inner ul li {
  margin-bottom: 0.5rem;
}
.tooltip:not(.clipboardable-tooltip) > .tooltip-inner p {
  padding-top: 1.3rem;
}
.tooltip:not(.clipboardable-tooltip) .arrow {
  display: none;
}
.tooltip:not(.clipboardable-tooltip).show {
  opacity: 1;
}

.tooltip.clipboardable-tooltip > .tooltip-inner {
  background-color: #1d71b8;
  color: #FFFFFF;
  border-radius: 6px;
}
.tooltip.clipboardable-tooltip.bs-tooltip-top, .tooltip.clipboardable-tooltip.bs-tooltip-auto[x-placement^=top] {
  margin-bottom: 5px;
}
.tooltip.clipboardable-tooltip.bs-tooltip-top .arrow::before, .tooltip.clipboardable-tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #1d71b8;
}
.tooltip.clipboardable-tooltip.bs-tooltip-right, .tooltip.clipboardable-tooltip.bs-tooltip-auto[x-placement^=right] {
  margin-left: 5px;
}
.tooltip.clipboardable-tooltip.bs-tooltip-right .arrow::before, .tooltip.clipboardable-tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #1d71b8;
}
.tooltip.clipboardable-tooltip.bs-tooltip-bottom, .tooltip.clipboardable-tooltip.bs-tooltip-auto[x-placement^=bottom] {
  margin-top: 5px;
}
.tooltip.clipboardable-tooltip.bs-tooltip-bottom .arrow::before, .tooltip.clipboardable-tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #1d71b8;
}
.tooltip.clipboardable-tooltip.bs-tooltip-left, .tooltip.clipboardable-tooltip.bs-tooltip-auto[x-placement^=left] {
  margin-right: 5px;
}
.tooltip.clipboardable-tooltip.bs-tooltip-left .arrow::before, .tooltip.clipboardable-tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #1d71b8;
}