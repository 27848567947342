/******************************************************************************/
/* VARIABLES                                                                  */
/******************************************************************************/

$hbm-font-size: 14px;
$hbm-font-family: 'Arial', 'Neue Helvetica', 'Helvetica', sans-serif;


// Prepare @burdadirect/npm_pkg_hbm_frontend variables
$burda-color: #1d71b8;

$hbm-color-bg: #e5e5e5;
$hbm-color-bg-light: #f6f6f6;
$hbm-color-text: #260211;
$hbm-color-logo: saturate(lighten($burda-color,10%), 70%);
$hbm-color-highlight: $burda-color;
$hbm-color-secondary: lighten($burda-color, 40%);
$hbm-color-warning: #EB5757;
$hbm-color-info: #856404;

$hbm-color-steps: #999;

$hbm-toggable-items: ('ids', 'icons', 'edits');

$hbm-colors: (
  'hbmlp-color-steps': $hbm-color-steps
);

// Prepare bootstrap variables
$theme-colors: (
  primary: $hbm-color-highlight,
  secondary: $hbm-color-secondary,
  secondary-light: desaturate(lighten($hbm-color-secondary, 10%), 5%),
  danger: $hbm-color-warning,
);

$font-family-base: $hbm-font-family;
$headings-font-family: $hbm-font-family;

$body-color: $hbm-color-text;

/******************************************************************************/

$border-radius:               0rem !default;
$border-radius-lg:            0rem !default;
$border-radius-sm:            0rem !default;

$input-border-radius:         0rem !default;
$input-border-radius-lg:      0rem !default;
$input-border-radius-sm:      0rem !default;

$btn-border-radius:           .25rem !default;
$btn-border-radius-lg:        .3rem !default;
$btn-border-radius-sm:        .2rem !default;

$jumbotron-bg: $hbm-color-bg;
