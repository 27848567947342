/******************************************************************************/
/* MERMAID                                                                    */
/******************************************************************************/

#hbmlp-content .node.hbmlp-mermaid-primary,
#hbmlp-content .node.hbmlp-mermaid-primary-restricted {
  .svg-inline--fa {
    margin-right: -12px;
    path {
      fill: $hbm-color-text;
    }
  }
  a {
    color: #FFF;
    font-weight: bold;
    font-size: 0.9em;
    text-align: center;
  }
  rect,
  circle {
    fill: $hbm-color-highlight;
    stroke: $hbm-color-text;
    stroke-width: 2px;
  }
}

#hbmlp-content .node.hbmlp-mermaid-secondary,
#hbmlp-content .node.hbmlp-mermaid-secondary-restricted {
  .svg-inline--fa {
    margin-right: -12px;
    path {
      fill: $hbm-color-text;
    }
  }
  a {
    color: $hbm-color-text;
    font-size: 0.9em;
    text-align: center;
  }
  rect,
  circle {
    fill: $hbm-color-secondary;
    stroke: $hbm-color-text;
    stroke-width: 2px;
  }
}

#hbmlp-content .node.hbmlp-mermaid-primary-restricted,
#hbmlp-content .node.hbmlp-mermaid-secondary-restricted {
  .svg-inline--fa {
    path {
      fill: theme-color("danger");
    }
  }
  a {
    font-style: italic;
  }
  rect,
  circle {
    fill: theme-color-level("danger", $alert-bg-level);
  }
}

#hbmlp-content .node.hbmlp-mermaid-exit {
  a {
    color: #FFF;
    font-size: 0.9em;
    text-align: center;
  }
  polygon,
  rect,
  circle {
    fill: $hbm-color-steps;
    stroke: $hbm-color-text;
    stroke-width: 2px;
  }
}
