/******************************************************************************/
/* FORMS                                                                      */
/******************************************************************************/

ul.hbmlp-form-errors {
  font-size: 1rem;
}

.hbm-form-help {
  font-size: 0.9rem;
  font-style: italic;
}

.hbm-form-highlight-control {
  .hbm-form-controls {
    .input-group {
      textarea, input, select {
        border-color: $hbm-color-highlight;
      }

      .input-group-text {
        border-color: $hbm-color-highlight;
        color: $hbm-color-highlight;
        background-color: $hbm-color-secondary;
      }
    }
  }
}

.hbm-form-card-narrow {
  .hbm-form-body {
    padding: 0.5rem;

    .hbm-form-group {
      label {
        margin-bottom: 0;
      }
    }

    .hbm-form-group + .hbm-form-group {
      margin-top: 0.5rem;
    }
  }
}
