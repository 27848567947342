/******************************************************************************/
/* BADGE                                                                      */
/******************************************************************************/

.badge-size-normal {
  font-size: 100%;
  padding-top: 0.20em;
  padding-bottom: 0.20em;
}

.badge-custom {
  padding: 0.5rem 1rem;
  font-size: 100%;
  &.badge-sm {
    padding: 0.3rem 0.7rem;
    font-size: 80%;
  }
  @include border-radius($badge-pill-border-radius);
}