/******************************************************************************/
/* BUTTONS                                                                    */
/******************************************************************************/

.btn.span-outline {
  opacity: 1.0;
  color: inherit;
  cursor: initial;
}

.btn-group.btn-group-toggle {
  > label > *:not(input) {
    pointer-events: none;
  }
}

.buttons-bar {
  display: flex;
  flex-direction: column;
  margin: 0 -#{map-get($spacers, 3)};

  &.buttons-bar-top {
    margin-bottom: #{map-get($spacers, 5)};
  }
  &.buttons-bar-bottom {
    margin-top: #{map-get($spacers, 5)};
  }

  .buttons-bar-left,
  .buttons-bar-right {
    display: flex;
    flex-direction: column;

    > .btn, > .btn-group {
      margin: #{map-get($spacers, 3)};
    }
  }

  .buttons-bar-left {
    align-items: flex-start;
  }
  .buttons-bar-right {
    align-items: flex-end;
  }

  @include media-breakpoint-up(xl) {
    flex-direction: row;
    justify-content: space-between;
  }
}
