/******************************************************************************/
/* COLORS                                                                     */
/******************************************************************************/

@mixin hbmlp-bg-variant($class, $color) {
  #{$class} {
    background-color: lighten(map-get($color, 'light'), 10%) !important;

    // See bootstraps _reboot.scss links section
    a {
      color: darken(map-get($color, 'orig'), 20%);
      background-color: transparent;

      @include hover {
        color: darken(map-get($color, 'orig'), 35%);
        text-decoration: $link-hover-decoration;
      }
    }
  }
  a#{$class},
  button#{$class} {
    @include hover-focus {
      background-color: darken(map-get($color, 'light'), 10%) !important;
    }
  }

  .card#{$class} > .card-header {
    background-color: lighten(map-get($color, 'light'), 0%) !important;
  }
}

@mixin hbmlp-header-variant($class, $color) {
  .card#{$class} > .card-header {
    background-color: lighten(map-get($color, 'light'), 0%) !important;
    border-bottom-color: map-get($color, 'orig') !important;

    // See bootstraps _reboot.scss links section
    a:not(.btn) {
      color: darken(map-get($color, 'orig'), 0%);
      background-color: transparent;

      @include hover {
        color: darken(map-get($color, 'orig'), 15%);
        text-decoration: $link-hover-decoration;
      }
    }
  }
}

$hbmlp-colors: (
  'cyan': (
    'orig': #17b6f1,
    'light': #95ddf8,
  ),
  'orange': (
    'orig': #fd7e14,
    'light': #fec494,
  ),
  'teal': (
    'orig': #20c997,
    'light': #99e6cf,
  ),
  'yellow': (
    'orig': #ffc107,
    'light': #ffe28e,
  ),
  'pink': (
    'orig': #e83e8c,
    'light': #f4a7ca,
  ),
  'brown': (
    'orig': #a53f2b,
    'light': #d6a79e,
  ),
  'indigo': (
    'orig': #6610f2,
    'light': #b992f9,
  ),
  'gray': (
    'orig': #adb5bd,
    'light': #d9dde1,
  ),
);

@each $key, $value in $hbmlp-colors {
  @include hbmlp-bg-variant(".hbmlp-bg-#{$key}", $value);
}

@each $key, $value in $hbmlp-colors {
  @include hbmlp-header-variant(".hbmlp-header-#{$key}", $value);
}

@each $key, $value in $hbmlp-colors {
  .hbmlp-border-#{$key} {
    border-color: map-get($value, 'orig') !important;
  }
}

.hbmlp-text-info {
  color: $hbm-color-info;
}

.bg-card-header {
  background-color: rgba(0, 0, 0, 0.03);
}
