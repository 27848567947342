/******************************************************************************/
/* HBMLP-CONTENT-ASIDE (SPLIT VIEW)                                           */
/******************************************************************************/

.title-wrapper {
  background-color: $hbm-color-bg;;

  .title {
    background-color: $white;
    border-radius: 30px;
    padding: .5rem 2rem;
    display: flex;
    margin: auto;

    h2 {
      font-size: 1.3rem;
      margin: 0;
    }
  }
}

.hbmlp-content-aside-body {
  position: absolute;
  top: 150px;
  padding: 0 16px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.upload-dropdown-section {
  border-top: 4px solid #fff;
  background-color: $hbm-color-bg;
  padding: 1rem;

  .input-group .input-group-prepend .input-group-text {
    border-color: $burda-color;
    color: $burda-color;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  .form-control {
    border-color: $burda-color;
    color: $burda-color;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    &::placeholder {
      color: $burda-color;
    }
  }
}


.hbmlp-content-aside-body-upload {
  position: absolute;
  top: 285px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 0 16px;
}

/******************************************************************************/
/* HBMLP-CONTENT-ASIDE (SPLIT VIEW) - NOTICES                                 */
/******************************************************************************/

.notice-wrapper {
  background-color: $hbm-color-bg;
  padding: 1rem 2rem;
  margin: 1rem 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  h3 {
    padding: 10px 16px;
    background-color: $white;
    border-radius: 3px;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
  }

  p {
    padding: 10px 16px;
    background-color: $white;
    margin: 1rem 0;

    &.notice-text {
      border-radius: 3px;
    }

    &.text-muted {
      font-size: 0.8rem;
      border-radius: 30px;
    }
  }
}

.uploads-wrapper {
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 5px;

  & .uploads-wrapper-card {
    border: 1px solid $hbm-color-bg;
    position: relative;
    background-color: white;
    padding: 1rem;
    margin: 1.5rem 0;
    border-radius: 5px;
    width: 100%;

    .upload-newest {
      position: absolute;
      top: -10px;
      left: -10px;
      padding: 0.4rem 0.6rem;
      background-color: $warning;
      border-radius: 100px;
    }

    .preview {
      color: $burda-color;
      transition: all .1s ease-in-out;
      cursor: pointer;

     &:hover {
       transform: scale(1.1);
     }
    }

    .icon {
      width: 3rem;
      display: flex;
      justify-content: center;
      margin-right: 1rem;
    }

    .badge-uploads {
      padding: .5rem .8rem;
      color: $burda-color;
      background-color: lighten($burda-color, 50%);
      border: none;
      border-radius: 5px;
      margin-bottom: 0;

      &-danger {
        color: $danger;
        background-color: lighten($danger, 40%);
      }
    }

    .badge-uploads-left {
      border-radius: 5px 0 0 5px;
    }
    .badge-uploads-right {
      border-radius: 0 5px 5px 0;
    }

    .cut-text {
      flex: 1;
      min-width: 0; /* or some value */
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    h3 {
      font-size: 1rem;
      font-weight: 700;
    }

    & p.text-muted {
      font-size: 0.8rem;
    }
  }
}

.uploads-list-aside.ui-sortable.shuffle{
  overflow: visible !important;
}

@media only screen and (max-width: 1770px) {
  .hbmlp-content-aside-body {
    top: 180px;
  }

  .hbmlp-content-aside-body-upload {
    top: 325px;
  }
}
