/******************************************************************************/
/* TASKS                                                                      */
/******************************************************************************/

[data-ajax-target="task-detail"] {
  background-color: $hbm-color-bg;

  padding-top: $grid-gutter-width/2;
  padding-bottom: $grid-gutter-width/2;
}
