/******************************************************************************/
/* MISC                                                                       */
/******************************************************************************/

body.ajax-secured {
  [data-ajax-secured] {
    cursor: default;
  }
  [data-ajax-secured="hidden"] {
    display: none;
  }
}

/******************************************************************************/

.tag-editor {
  border: 1px solid #CCCCCC;
  border-radius: .25rem;
  padding: 0.375rem .75rem;
}

.editor-container {
  z-index: 1;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
}

/******************************************************************************/

.dropzone {
  border: 4px dashed $burda-color;
  border-radius: 10px;
  min-height: 150px;
  color: $burda-color;
  background: none;
}

/******************************************************************************/

[v-cloak] {
  display: none;
}

/******************************************************************************/

.inline-edit[contenteditable] {
  position: relative;
}

.inline-edit[contenteditable]:focus {
  outline: theme-color('primary') solid 2px;
  outline-offset: 2px;
}

.inline-edit[contenteditable]:hover:not(:focus) {
  outline: theme-color('secondary') solid 2px;
  outline-offset: 2px;
}

@keyframes inline-edit-success-animation {
  0% {
    color: theme-color('success');
  }
  100% {
    color: initial;
  }
}

.inline-edit-success {
  animation: inline-edit-success-animation linear 2s 1;
}

.inline-edit-error {
  color: theme-color('danger');
  outline: dashed 2px theme-color('danger');
  outline-offset: 2px;
}

/******************************************************************************/

span.visibility-restricted {
  padding: 0.75rem;
  border-radius: 1rem;
  background-color: theme-color-level("danger", $alert-bg-level);
}

/******************************************************************************/

.environment-dev {
  border-left:10px solid #dc3545;
}

.environment-stage {
  border-left:10px solid #FFC107;
}

.environment-prod {
}

/******************************************************************************/

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}fr#{$infix}-#{$size} {
          > *:not(:last-child) {
            #{$prop}-right: $length !important;
          }
        }
        .#{$abbrev}fc#{$infix}-#{$size} {
          > *:not(:last-child) {
            #{$prop}-bottom: $length !important;
          }
        }
      }
    }
  }
}

/******************************************************************************/

.highlight-diff {
  del {
    font-weight: bold;
    color: theme-color('danger');
  }

  ins {
    font-weight: bold;
    color: theme-color('success');
  }
}
