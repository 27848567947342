/* Tooltip */
.tooltip:not(.clipboardable-tooltip) {
  &> .tooltip-inner {
    background-color: $white;
    color: $burda-color;
    border: 1px solid $hbm-color-bg;
    box-shadow: 2px 2px 10px $hbm-color-bg, -2px -2px 10px $hbm-color-bg;
    padding: 15px 20px;
    min-width: 380px;
    text-align: left;
    font-size: 1.1rem;
    border-radius: 5px;

    ul {
      list-style-type: none;

      li {
        margin-bottom: .5rem;
      }
    }

    p {
      padding-top: 1.3rem;
    }
  }
  .arrow {
    display: none;
  }

  &.show {
    opacity: 1;
  }
}

$hbm-color-clipboardable-tooltip-text: #FFFFFF;
$hbm-color-clipboardable-tooltip-bg: lighten($hbm-color-secondary, 8);
$hbm-color-clipboardable-tooltip-bg: $hbm-color-highlight;
$hbm-color-clipboardable-tooltip-directions: ('top': 'bottom', 'right': 'left', 'bottom': 'top', 'left': 'right');

.tooltip.clipboardable-tooltip {
  &> .tooltip-inner {
    background-color: $hbm-color-clipboardable-tooltip-bg;
    color: $hbm-color-clipboardable-tooltip-text;
    border-radius: 6px;
  }

  @each $hbm-color-clipboardable-tooltip-direction, $hbm-color-clipboardable-tooltip-direction-opposite in $hbm-color-clipboardable-tooltip-directions {
    &.bs-tooltip-#{$hbm-color-clipboardable-tooltip-direction} {
      margin-#{$hbm-color-clipboardable-tooltip-direction-opposite}: 5px;
      .arrow {
        &::before {
          border-#{$hbm-color-clipboardable-tooltip-direction}-color: $hbm-color-clipboardable-tooltip-bg;
        }
      }
    }
  }
}
